import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Button, Form, Input } from "reactstrap";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import {
  getAuthKey,
  getClassAssetInfo,
  getClassAssets,
  getUserData,
} from "../../redux/selector";
import DataTable from "react-data-table-component";
import {
  active,
  bulkEdit,
  cross,
  deleteAsset,
  depreciation,
  eye,
  qrCodeScanner,
  reclassification,
  redCross,
  share,
  warning,
} from "../../config/assets";
import {
  disposeAssets,
  disposeLandAssets,
  fairValueAssets,
  getAssets,
  impairAssets,
  moveAssets,
  transferAssets,
  updateStatusAssets,
} from "../../redux/api";
import envConfig from "../../config/constants";
import {
  amountFormat,
  checkEditAccessRight,
  verifyClassLand,
  verifyClassNameAndId,
  verifyReducingBalance,
} from "../../config/utility";
import Popup from "reactjs-popup";
import { setMessageUser } from "../../redux/features/user";
import DatePicker from "react-date-picker";
import moment from "moment";
import swal from "sweetalert";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import BreadCrumbs from "../../components/breadCrumbs";
import { clearClassColumn } from "../../redux/features/asset";

const Assets = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLand = verifyClassLand(props.userData.classes, params.class_id);
  const isReadOnly = checkEditAccessRight(props.userData.access_right);
  const isReducingBalance = verifyReducingBalance(
    props.userData.organization.depreciation_type
  );

  const [statusBox, setStatusBox] = useState({
    show: false,
    value: "",
    asset_id: "",
  });
  const [impairmentBox, setImpairmentBox] = useState({
    show: false,
    value: "",
    originalValue: "",
    asset_id: "",
  });
  const [transferBox, setTransferBox] = useState({
    show: false,
    asset_id: "",
  });

  const [moveAssetBox, setMoveAssetBox] = useState({
    show: false,
    asset_id: "",
  });
  const [fairValueBox, setFairValueBox] = useState(false);
  const [qrBox, setQRBox] = useState({ show: false, asset_id: "", value: "" });
  const [transferTo, setTransferTo] = useState("");
  const [moveTo, setMoveTo] = useState("");
  const [impairmentNature, setImpairmentNature] = useState("");
  const [impairmentSource, setImpairmentSource] = useState("");
  const [impairmentAmount, setImpairmentAmount] = useState("");
  const [impairmentDate, setImpairmentDate] = useState("");
  const [disposeBox, setDisposeBox] = useState({
    show: false,
    value: "",
    asset_id: "",
    originalValue: "",
    area: "",
    area_price: "",
  });
  const [disposeAmount, setDisposeAmount] = useState("");
  const [disposeArea, setDisposeArea] = useState("");
  const [disposeAreaPrice, setDisposeAreaPrice] = useState("");
  const [disposeDate, setDisposeDate] = useState("");
  const [searchText, setSearchText] = useState("");

  const [fairValueCalculation, setFairValueCalculation] = useState({});

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "600",
        fontSize: "15px",
        textTransform: "capitalize",
      },
    },
    rows: {
      style: {
        minHeight: "3rem", // override the row height
      },
    },
  };

  useEffect(() => {
    if (
      !verifyClassNameAndId(
        props.userData.classes,
        params.class_name,
        params.class_id
      )
    )
      navigate("/app/dashboard");
    else
      dispatch(getAssets({ authKey: props.authKey, classId: params.class_id }));
  }, [
    dispatch,
    navigate,
    params.class_id,
    params.class_name,
    props.authKey,
    props.userData.classes,
  ]);

  const findByDefaultColumn = (row, defaultColumn) => {
    return row.reduce((columnValue, data) => {
      return data.default_column_id === defaultColumn ? data : columnValue;
    }, {});
  };

  let headers = [],
    data = [],
    dataWorking = [],
    dataNonWorking = [],
    dataDepreciated = [];
  props.classAssets.forEach((assetRow, index) => {
    if (index === 0) {
      headers = assetRow.reduce((array, asset) => {
        const selector = array.length;
        array.push({
          ...asset,
          cell:
            selector === 0
              ? (row) => {
                  return row[row.length - 1].is_depreciating ? (
                    <>
                      <div className="tooltip">
                        <div className="tooltiptext cap">
                          Your asset is depreciating <p>or</p> depreciated
                          please take action
                        </div>
                        <img
                          src={warning}
                          className="listing__svg cursor"
                          alt="warning"
                        />
                      </div>
                      <span className="ps-1">{row[selector].value}</span>
                    </>
                  ) : (
                    <span className="ps-1">{row[selector].value}</span>
                  );
                }
              : selector === assetRow.length - 1
              ? (row) => {
                  return (
                    <div
                      className="tooltip"
                      onClick={() => {
                        if (!isReadOnly)
                          setStatusBox({
                            asset_id: row[selector].asset_id,
                            value: row[selector].value,
                            show: true,
                          });
                      }}
                    >
                      <span className="tooltiptext">
                        {envConfig.assetStatusValue[row[selector].value]}
                      </span>

                      {row[selector].value === envConfig.assetStatus.WORKING ? (
                        <img
                          className="cursor mr-sm"
                          src={active}
                          alt="edit description"
                        />
                      ) : row[selector].value ===
                        envConfig.assetStatus.FULL_DEPRECIATED_WORKING ? (
                        <img
                          className="cursor mr-sm"
                          src={depreciation}
                          alt="edit description"
                        />
                      ) : (
                        <img
                          className="cursor mr-sm"
                          src={redCross}
                          alt="edit description"
                        />
                      )}
                    </div>
                  );
                }
              : null,
          selector: (row) => {
            return row[selector].value;
          },
          right: true,
          wrap: true,
        });
        return array;
      }, []);
    } else {
      data.push(
        assetRow.reduce((array, asset) => {
          array.push(asset);
          return array;
        }, [])
      );
      dataWorking = data.reduce((array, assetRow) => {
        if (
          assetRow[assetRow.length - 1].value === envConfig.assetStatus.WORKING
        )
          array.push(assetRow);
        return array;
      }, []);
      dataNonWorking = data.reduce((array, assetRow) => {
        if (
          assetRow[assetRow.length - 1].value ===
          envConfig.assetStatus.NON_WORKING
        )
          array.push(assetRow);
        return array;
      }, []);
      dataDepreciated = data.reduce((array, assetRow) => {
        if (
          assetRow[assetRow.length - 1].value ===
          envConfig.assetStatus.FULL_DEPRECIATED_WORKING
        )
          array.push(assetRow);
        return array;
      }, []);
    }
  });
  headers.length > 0 &&
    headers.push({
      name: "Actions",
      cell: (row) => {
        return (
          <div className="listing__actions">
            <div
              className={`tooltip ${isLand || isReadOnly ? "d-hidden" : ""}`}
              onClick={() => {
                if (!isReadOnly)
                  setImpairmentBox({
                    show: true,
                    value: findByDefaultColumn(
                      row,
                      envConfig.defaultColumn.carrying_amount
                    ).value,
                    originalValue: findByDefaultColumn(
                      row,
                      envConfig.defaultColumn.carrying_amount
                    ).originalValue,
                    asset_id: row[0].asset_id,
                  });
                setImpairmentAmount(
                  Math.floor(
                    findByDefaultColumn(
                      row,
                      envConfig.defaultColumn.carrying_amount
                    ).originalValue
                  )
                );
              }}
            >
              <span className="tooltiptext cap">Record Impairment</span>
              <img
                className="listing__svg cursor"
                src={bulkEdit}
                alt="bulkEdit svg icon"
              />
            </div>
            {props.classAssetInfo.canMoveAsset && (
              <div
                className={`tooltip ${isLand || isReadOnly ? "d-hidden" : ""}`}
                onClick={() => {
                  if (!isReadOnly)
                    setMoveAssetBox({
                      show: true,
                      asset_id: row[0].asset_id,
                    });
                }}
              >
                <span className="tooltiptext cap">Transfers</span>
                <img
                  className="listing__svg cursor"
                  src={share}
                  alt="share svg icon"
                />
              </div>
            )}
            <div
              className={`tooltip ${isLand || isReadOnly ? "d-hidden" : ""}`}
              onClick={() => {
                if (!isReadOnly)
                  setTransferBox({
                    show: true,
                    asset_id: row[0].asset_id,
                  });
              }}
            >
              <span className="tooltiptext cap">Re-classification</span>
              <img
                className="listing__svg cursor"
                src={reclassification}
                alt="find reclassification svg icon"
              />
            </div>
            <Link
              className="tooltip"
              to={`/app/asset-detail/${params.class_name}/${params.class_id}/${row[0].asset_id}`}
              replace
            >
              <span className="tooltiptext cap">View</span>
              <img className="listing__svg" src={eye} alt="eye svg icon" />
            </Link>
            <div
              className={`tooltip ${isReadOnly ? "d-hidden" : ""}`}
              onClick={() => {
                if (!isReadOnly)
                  setDisposeBox({
                    show: true,
                    value: row[row.length - 1].value,
                    originalValue: findByDefaultColumn(
                      row,
                      envConfig.defaultColumn.purchase_date
                    ).originalValue,
                    asset_id: row[0].asset_id,
                    area: findByDefaultColumn(row, envConfig.defaultColumn.area)
                      .originalValue,
                    area_price: findByDefaultColumn(
                      row,
                      envConfig.defaultColumn.area_price
                    ).value,
                  });
              }}
            >
              <span className="tooltiptext cap">Dispose</span>
              <img
                className="listing__svg cursor"
                src={deleteAsset}
                alt=" Dispose svg icon"
              />
            </div>
            <div
              className="tooltip"
              onClick={() => {
                setQRBox({
                  show: true,
                  asset_id: row[0].asset_id,
                  value: findByDefaultColumn(
                    row,
                    envConfig.defaultColumn.asset_id
                  ).value,
                });
              }}
            >
              <span className="tooltiptext cap">QR Scanner</span>
              <img
                className="listing__svg cursor"
                src={qrCodeScanner}
                alt="qr code scanner svg icon"
              />
            </div>
          </div>
        );
      },
    });

  if (searchText !== "") {
    data = data.filter((row) => {
      return (
        findByDefaultColumn(row, envConfig.defaultColumn.asset_id)
          .value.toLowerCase()
          .indexOf(searchText.trim().toLowerCase()) > -1
      );
    });
    dataWorking = dataWorking.filter((row) => {
      return (
        findByDefaultColumn(row, envConfig.defaultColumn.asset_id)
          .value.toLowerCase()
          .indexOf(searchText.trim().toLowerCase()) > -1
      );
    });
    dataNonWorking = dataNonWorking.filter((row) => {
      return (
        findByDefaultColumn(row, envConfig.defaultColumn.asset_id)
          .value.toLowerCase()
          .indexOf(searchText.trim().toLowerCase()) > -1
      );
    });
    dataDepreciated = dataDepreciated.filter((row) => {
      return (
        findByDefaultColumn(row, envConfig.defaultColumn.asset_id)
          .value.toLowerCase()
          .indexOf(searchText.trim().toLowerCase()) > -1
      );
    });
  }

  const processImpairment = async () => {
    const response = await dispatch(
      impairAssets({
        authKey: props.authKey,
        classId: params.class_id,
        assetId: impairmentBox.asset_id,
        body: JSON.stringify({
          amount: impairmentAmount,
          date: moment(impairmentDate).format("YYYY-MM-DD"),
          nature_type: impairmentNature,
          sources: impairmentSource,
        }),
      })
    );
    if (response.payload.status === 200) {
      setImpairmentBox({
        show: false,
        value: "",
        asset_id: "",
        originalValue: "",
      });
      setImpairmentAmount("");
      setImpairmentDate("");
      setImpairmentNature("");
      setImpairmentSource("");
      props.setMessageUser({
        message: response.payload.message,
        type: envConfig.notificationTypes.success,
      });
      dispatch(
        getAssets({
          authKey: props.authKey,
          classId: params.class_id,
        })
      );
    } else {
      props.setMessageUser({
        message: response.payload.message,
        type: envConfig.notificationTypes.danger,
      });
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const processLandDispose = async () => {
    const response = await dispatch(
      disposeLandAssets({
        authKey: props.authKey,
        classId: params.class_id,
        assetId: disposeBox.asset_id,
        body: JSON.stringify({
          date: moment(disposeDate).format("YYYY-MM-DD hh:mm:ss"),
          area: disposeArea,
          area_price: disposeAreaPrice,
        }),
      })
    );
    if (response.payload.status === 200) {
      setDisposeBox({
        show: false,
        value: "",
        asset_id: "",
        originalValue: "",
        area: "",
        area_price: "",
      });
      setDisposeArea("");
      setDisposeAreaPrice("");
      setDisposeDate("");
      props.setMessageUser({
        message: response.payload.message,
        type: envConfig.notificationTypes.success,
      });
      dispatch(
        getAssets({
          authKey: props.authKey,
          classId: params.class_id,
        })
      );
    } else {
      props.setMessageUser({
        message: response.payload.message,
        type: envConfig.notificationTypes.danger,
      });
    }
  };

  return (
    <>
      <TopNav />
      <SideNav classId={params.class_id} className={params.class_name} />

      <div className="listings">
        <BreadCrumbs crumbs={[params.class_name]} />
        {props.classAssetInfo.canUpdateFairValue && (
          <div
            className="assetinfo__errorsms"
            onClick={() => {
              setFairValueBox(true);
            }}
          >
            <img src={warning} className="assetinfo__svg" alt="warning" />
            <p>
              Assessment of fair value must be performed within 3 months of the
              start of financial year
            </p>
            <div className="tooltip">
              <div className="assetinfo__btns">
                <Button className="btn assetinfo__btn ">Do it now?</Button>
              </div>
            </div>
          </div>
        )}

        {props.classAssetInfo.canMoveAsset && !isLand && (
          <div className="assetinfo__errorsms">
            <img src={warning} className="assetinfo__svg" alt="warning" />
            <p>
              Transfer of asset is available. Transfers are allowed only within
              3 months of the start of financial year
            </p>
          </div>
        )}

        <div className="listing pb0">
          <div className="listing__header">
            <div className="listing__header__block">
              <h3 className="heading-3 cap">{params.class_name}</h3>
              <div
                onClick={() => {
                  if (
                    props.classAssetInfo.fairValue === 0 ||
                    props.classAssetInfo.canUpdateFairValue
                  ) {
                    setFairValueBox(true);
                  }
                }}
                className={`listing__item listing__item__fairvalue ${
                  props.classAssetInfo.fairValue === 0
                    ? ""
                    : "listing__item--alreadyfairvalue"
                }`}
              >
                <span>
                  <span className="listing__item--name">
                    {props.classAssetInfo.fairValue === 0
                      ? "Turn to Fair Value"
                      : "Fair Value"}
                  </span>
                </span>
              </div>
            </div>

            <div className="listing__header--box">
              <Input
                className="form__search"
                type="search"
                name="search"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {!isReadOnly && (
                <>
                  <div
                    onClick={() => {
                      props.clearClassColumn();
                      navigate(
                        "/app/edit-columns/" +
                          params.class_name +
                          "/" +
                          params.class_id
                      );
                    }}
                    className="btn btn__border btn__brd"
                  >
                    Edit Class
                  </div>

                  <Link
                    to={
                      "/app/asset-add/" +
                      params.class_name +
                      "/" +
                      params.class_id
                    }
                    className="btn btn__blue btn__brd"
                  >
                    + Add Asset
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className="listing__header__mobile">
            <div className="listing__header__block">
              <h3 className="heading-3 cap">{params.class_name}</h3>
              <div
                onClick={() => {
                  if (props.classAssetInfo.fairValue === 0) {
                    setFairValueBox(true);
                  }
                }}
                className={`listing__item listing__item__fairvalue ${
                  props.classAssetInfo.fairValue === 0
                    ? ""
                    : "listing__item--alreadyfairvalue"
                }`}
              >
                <span>
                  <span className="listing__item--name">
                    {props.classAssetInfo.fairValue === 0
                      ? "Turn to Fair Value"
                      : "Fair Value"}
                  </span>
                </span>
              </div>
            </div>
            <Input
              className="form__search"
              type="search"
              name="search"
              placeholder="Search..."
            />
            <Link to="" className="btn btn__border btn__brd">
              Edit Class
            </Link>
            <Link
              to={"/app/asset-add/" + params.class_name + "/" + params.class_id}
              className="btn btn__blue btn__brd"
            >
              + Add Asset
            </Link>
          </div>
          {/* tabs start */}
          <Tabs className="listing__tabs">
            <TabList className="listing__tab">
              <Tab className={`listing__item listing__item--active`}>
                <span>
                  <span className="listing__item--name">All Items</span>
                  <span className="listing__pills listing__pills--gray">
                    {props.classAssetInfo.totalCount}
                  </span>
                </span>
              </Tab>
              <Tab className={`listing__item listing__item--active`}>
                <span>
                  <span className="listing__item--name">
                    <img src={active} className="mr-s-sm" alt="" />
                    Fully Working Item
                  </span>
                  <span className="listing__pills listing__pills--gray">
                    {props.classAssetInfo.workingCount}
                  </span>
                </span>
              </Tab>
              <Tab className={`listing__item listing__item--active`}>
                <span>
                  <span className="listing__item--name">
                    <img src={redCross} className="mr-s-sm" alt="" />
                    Non-Working Item
                  </span>
                  <span className="listing__pills listing__pills--gray">
                    {props.classAssetInfo.nonWorkingCount}
                  </span>
                </span>
              </Tab>
              <Tab className={`listing__item listing__item--active`}>
                <span>
                  <span className="listing__item--name">
                    <img src={depreciation} className="mr-s-sm" alt="" />
                    Fully Depreciated and Working
                  </span>
                  <span className="listing__pills listing__pills--gray">
                    {props.classAssetInfo.depreciatedCount}
                  </span>
                </span>
              </Tab>
            </TabList>
            {/* All Data */}
            <TabPanel className="listing__bottom asset-listing">
              <DataTable
                columns={headers}
                data={data}
                highlightOnHover={true}
                pagination={true}
                persistTableHead={true}
                customStyles={customStyles}
                fixedHeader
                fixedHeaderScrollHeight="300px"
              />
              {data.length > 0 && (
                <div className="listing__bottom__box">
                  <span className="mr-sm">
                    Total Cost :{" "}
                    <span className="c__blue">
                      {props.classAssetInfo.total?.cost}
                    </span>
                  </span>
                  {isLand ? (
                    <span className="mr-sm">
                      Total Current Cost :{" "}
                      <span className="c__blue">
                        {props.classAssetInfo.total?.current_cost}
                      </span>
                    </span>
                  ) : (
                    <>
                      <span className="mr-sm">
                        Total Accumulated Depriciation :{" "}
                        <span className="c__blue">
                          {props.classAssetInfo.total?.accumulated_depreciation}
                        </span>
                      </span>
                      <span className="mr-sm">
                        Total Carrying Amount :{" "}
                        <span className="c__blue">
                          {props.classAssetInfo.total?.carrying_amount}
                        </span>
                      </span>
                    </>
                  )}
                </div>
              )}
            </TabPanel>
            {/*  fully Working Item */}
            <TabPanel className="listing__bottom asset-listing">
              <DataTable
                columns={headers}
                data={dataWorking}
                highlightOnHover={true}
                pagination={true}
                persistTableHead={true}
                customStyles={customStyles}
                fixedHeader
                fixedHeaderScrollHeight="300px"
              />
              {dataWorking.length > 0 && (
                <div className="listing__bottom__box">
                  <span className="mr-sm">
                    Total Cost :{" "}
                    <span className="c__blue">
                      {props.classAssetInfo.total_working?.cost}
                    </span>
                  </span>
                  {isLand ? (
                    <span className="mr-sm">
                      Total Current Cost :{" "}
                      <span className="c__blue">
                        {props.classAssetInfo.total_working?.current_cost}
                      </span>
                    </span>
                  ) : (
                    <>
                      <span className="mr-sm">
                        Total Accumulated Depriciation :{" "}
                        <span className="c__blue">
                          {
                            props.classAssetInfo.total_working
                              ?.accumulated_depreciation
                          }
                        </span>
                      </span>
                      <span className="mr-sm">
                        Total Carrying Amount :{" "}
                        <span className="c__blue">
                          {props.classAssetInfo.total_working?.carrying_amount}
                        </span>
                      </span>
                    </>
                  )}
                </div>
              )}
            </TabPanel>
            <TabPanel className="listing__bottom asset-listing">
              {/* NON_WORKING_data */}
              <DataTable
                columns={headers}
                data={dataNonWorking}
                highlightOnHover={true}
                pagination={true}
                persistTableHead={true}
                customStyles={customStyles}
                fixedHeader
                fixedHeaderScrollHeight="300px"
              />
              {dataNonWorking.length > 0 && (
                <div className="listing__bottom__box">
                  <span className="mr-sm">
                    Total Cost :{" "}
                    <span className="c__blue">
                      {props.classAssetInfo.total_non_working?.cost}
                    </span>
                  </span>
                  {isLand ? (
                    <span className="mr-sm">
                      Total Current Cost :{" "}
                      <span className="c__blue">
                        {props.classAssetInfo.total_non_working?.current_cost}
                      </span>
                    </span>
                  ) : (
                    <>
                      <span className="mr-sm">
                        Total Accumulated Depriciation :{" "}
                        <span className="c__blue">
                          {
                            props.classAssetInfo.total_non_working
                              ?.accumulated_depreciation
                          }
                        </span>
                      </span>
                      <span className="mr-sm">
                        Total Carrying Amount :{" "}
                        <span className="c__blue">
                          {
                            props.classAssetInfo.total_non_working
                              ?.carrying_amount
                          }
                        </span>
                      </span>
                    </>
                  )}
                </div>
              )}
            </TabPanel>
            <TabPanel className="listing__bottom asset-listing">
              {/* fully Depreciated and Working */}
              <DataTable
                columns={headers}
                data={dataDepreciated}
                highlightOnHover={true}
                pagination={true}
                persistTableHead={true}
                customStyles={customStyles}
                fixedHeader
                fixedHeaderScrollHeight="300px"
              />
              {dataDepreciated.length > 0 && (
                <div className="listing__bottom__box">
                  <span className="mr-sm">
                    Total Cost :{" "}
                    <span className="c__blue">
                      {props.classAssetInfo.total_depreciated_working?.cost}
                    </span>
                  </span>
                  {isLand ? (
                    <span className="mr-sm">
                      Total Current Cost :{" "}
                      <span className="c__blue">
                        {
                          props.classAssetInfo.total_depreciated_working
                            ?.current_cost
                        }
                      </span>
                    </span>
                  ) : (
                    <>
                      <span className="mr-sm">
                        Total Accumulated Depriciation :{" "}
                        <span className="c__blue">
                          {
                            props.classAssetInfo.total_depreciated_working
                              ?.accumulated_depreciation
                          }
                        </span>
                      </span>
                      <span className="mr-sm">
                        Total Carrying Amount :{" "}
                        <span className="c__blue">
                          {
                            props.classAssetInfo.total_depreciated_working
                              ?.carrying_amount
                          }
                        </span>
                      </span>
                    </>
                  )}
                </div>
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>

      {/* common popup */}
      <Popup
        open={statusBox.show}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue upper">Are you sure ?</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setStatusBox({ show: false, value: "", asset_id: "" });
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>

          <div className="content">
            <Form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault();
                const response = await dispatch(
                  updateStatusAssets({
                    authKey: props.authKey,
                    classId: params.class_id,
                    assetId: statusBox.asset_id,
                    body: JSON.stringify({
                      status:
                        statusBox.value === envConfig.assetStatus.WORKING ||
                        statusBox.value ===
                          envConfig.assetStatus.FULL_DEPRECIATED_WORKING
                          ? envConfig.assetStatus.NON_WORKING
                          : envConfig.assetStatus.WORKING,
                    }),
                  })
                );
                if (response.payload.status === 200) {
                  setStatusBox({ show: false, value: "", asset_id: "" });
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.success,
                  });
                  dispatch(
                    getAssets({
                      authKey: props.authKey,
                      classId: params.class_id,
                    })
                  );
                } else {
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.danger,
                  });
                }
              }}
            >
              <div className="mb-md mt-md">
                <p className="">
                  {statusBox.value === envConfig.assetStatus.WORKING ||
                  statusBox.value ===
                    envConfig.assetStatus.FULL_DEPRECIATED_WORKING
                    ? `Make this asset non-working.`
                    : "Make this asset working."}
                </p>
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* impairment popup */}
      <Popup
        className="popup-content__width--80"
        open={impairmentBox.show}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue">Record Impairment</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setImpairmentBox({
                  show: false,
                  value: "",
                  asset_id: "",
                  originalValue: "",
                });
                setImpairmentAmount("");
                setImpairmentDate("");
                setImpairmentNature("");
                setImpairmentSource("");
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>
          <p className="mb-sm op-2 cap">
            current carrying amonut :
            <span className="c__blue"> {impairmentBox.value}</span>
          </p>

          <div className="content">
            <Form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault();
                if (impairmentNature === "") {
                  props.setMessageUser({
                    message: "Please select nature of impairment",
                    type: envConfig.notificationTypes.warning,
                  });
                  return;
                }
                if (
                  parseInt(impairmentAmount) ===
                  parseInt(impairmentBox.originalValue)
                ) {
                  swal({
                    title: "Are you sure?",
                    text: "Since the impairment amount is equal to carrying amount, do you want to de-recognise the asset?",
                    icon: "warning",
                    buttons: true,
                  }).then(async (result) => {
                    if (result) {
                      await processImpairment();
                    }
                  });
                } else {
                  await processImpairment();
                }
              }}
            >
              <div className="mb-md">
                <label className="form__label cap mb-s-sm" aria-hidden="true">
                  Select Date of Impairment
                </label>
                <DatePicker
                  className="date__input"
                  minDate={new Date(props.userData.organization.fy_start)}
                  maxDate={new Date()}
                  value={impairmentDate}
                  onChange={(e) => setImpairmentDate(e)}
                  required
                />
              </div>
              <div className="listing__grid-2">
                <div className="">
                  Nature of impairment
                  <div className="accMgmt__grid--2">
                    {envConfig.impairmentNature.map((nature, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            setImpairmentNature(nature);
                          }}
                        >
                          <label className="form__label mt-s-sm cursor">
                            <p
                              className={`cursor listing__edit accMgmt__edit--${
                                impairmentNature === nature ? "blue" : "gray"
                              }`}
                            >
                              {nature}
                            </p>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="mb-s-sm">
                <label
                  htmlFor="img_source"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  {impairmentNature === envConfig.impairmentNature[0]
                    ? "External"
                    : impairmentNature === envConfig.impairmentNature[1]
                    ? "Internal"
                    : ""}{" "}
                  Source of Information
                </label>
                <select
                  className="form__input"
                  value={impairmentSource}
                  onChange={(e) => setImpairmentSource(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {envConfig.impairmentSource
                    .filter((_source, index) => {
                      return (
                        (impairmentNature === envConfig.impairmentNature[0] &&
                          index < 2) ||
                        (impairmentNature === envConfig.impairmentNature[1] &&
                          index > 1)
                      );
                    })
                    .map((source, index) => {
                      return (
                        <option key={index} value={source}>
                          {source}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="mb-s-sm">
                <label
                  htmlFor="impairmentAmount"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  Impairment Amount (Partial impairment not supported yet)
                </label>
                <Input
                  className="form__input"
                  type="text"
                  placeholder="Input"
                  value={impairmentAmount}
                  readOnly
                  required
                />
                <span
                  className="form__label--warning"
                  role="alert"
                  aria-live="assertive"
                >
                  {impairmentAmount === ""
                    ? ""
                    : amountFormat(impairmentAmount)}
                </span>
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Add Impairment
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* dispose asset */}
      <Popup
        open={disposeBox.show}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue">Dispose Asset</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setDisposeBox({
                  show: false,
                  value: "",
                  asset_id: "",
                  originalValue: "",
                  area: "",
                  area_price: "",
                });
                setDisposeAmount("");
                setDisposeDate("");
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>
          {isLand ? (
            <p className="mb-sm op-2">
              Current Area :{" "}
              <span className="c__blue mr-sm">{disposeBox.area}</span>
              Current Area Price :{" "}
              <span className="c__blue">{disposeBox.area_price}</span>
            </p>
          ) : (
            <p className="mb-sm op-2">
              Current Status :{" "}
              <span className="c__blue">
                {envConfig.assetStatusValue[disposeBox.value]}
              </span>
            </p>
          )}
          <div className="content">
            <Form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault();
                if (isLand) {
                  if (disposeBox.area - disposeArea <= 1) {
                    swal({
                      title: "Are you sure?",
                      text: "Since the area of land is diminishing, you have to dispose the complete asset?",
                      icon: "warning",
                      buttons: true,
                    }).then(async (result) => {
                      if (result) {
                        await processLandDispose();
                      }
                    });
                  } else {
                    await processLandDispose();
                  }
                } else {
                  const response = await dispatch(
                    disposeAssets({
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: disposeBox.asset_id,
                      body: JSON.stringify({
                        date: moment(disposeDate).format("YYYY-MM-DD"),
                        amount: disposeAmount,
                      }),
                    })
                  );
                  if (response.payload.status === 200) {
                    setDisposeBox({
                      show: false,
                      value: "",
                      asset_id: "",
                      originalValue: "",
                      area: "",
                      area_price: "",
                    });
                    setDisposeAmount("");
                    setDisposeDate("");
                    props.setMessageUser({
                      message: response.payload.message,
                      type: envConfig.notificationTypes.success,
                    });
                    dispatch(
                      getAssets({
                        authKey: props.authKey,
                        classId: params.class_id,
                      })
                    );
                  } else {
                    props.setMessageUser({
                      message: response.payload.message,
                      type: envConfig.notificationTypes.danger,
                    });
                  }
                }
              }}
            >
              <div className="mb-md">
                <label
                  htmlFor="date_dispose"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  Date of Disposing
                </label>
                <DatePicker
                  name="date_dispose"
                  id="date_dispose"
                  className="date__input"
                  minDate={new Date(props.userData.organization.fy_start)}
                  maxDate={new Date()}
                  value={disposeDate}
                  onChange={(e) => {
                    setDisposeDate(e);
                  }}
                  required
                />
              </div>
              {!isLand && (
                <div className="mb-md">
                  <label
                    htmlFor="amount"
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    Dispose Amount
                  </label>
                  <Input
                    className="form__input"
                    type="text"
                    name="amount"
                    autoFocus
                    minLength="1"
                    maxLength="50"
                    value={disposeAmount}
                    onChange={(e) => {
                      setDisposeAmount(
                        isNaN(e.target.value) || e.target.value > 1000000000
                          ? disposeAmount
                          : e.target.value
                      );
                    }}
                    required
                  />
                  <span
                    className="form__label--warning"
                    role="alert"
                    aria-live="assertive"
                  >
                    {disposeAmount === "" ? "" : amountFormat(disposeAmount)}
                  </span>
                </div>
              )}
              {isLand && (
                <>
                  <div className="mb-md">
                    <label
                      className="form__label cap mb-s-sm"
                      aria-hidden="true"
                    >
                      Dispose Area Size (in Sq. Mtr.)
                    </label>
                    <Input
                      className="form__input"
                      type="tel"
                      minLength="1"
                      maxLength="50"
                      value={disposeArea}
                      onChange={(e) => {
                        setDisposeArea(
                          isNaN(e.target.value) ||
                            e.target.value > parseFloat(disposeBox.area)
                            ? disposeArea
                            : e.target.value
                        );
                      }}
                      required
                    />
                  </div>
                  <div className="mb-md">
                    <label
                      className="form__label cap mb-s-sm"
                      aria-hidden="true"
                    >
                      Dispose Area Price Per Sq. Mtr.
                    </label>
                    <Input
                      className="form__input"
                      type="tel"
                      minLength="1"
                      maxLength="50"
                      value={disposeAreaPrice}
                      onChange={(e) => {
                        setDisposeAreaPrice(
                          isNaN(e.target.value) || e.target.value > 1000000000
                            ? disposeAreaPrice
                            : e.target.value
                        );
                      }}
                      required
                    />
                    <span
                      className="form__label--warning"
                      role="alert"
                      aria-live="assertive"
                    >
                      {disposeAreaPrice === ""
                        ? ""
                        : amountFormat(disposeAreaPrice)}
                    </span>
                  </div>
                </>
              )}
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Move
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* asset reclassification */}
      <Popup
        open={transferBox.show}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue">Reclassify Asset</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setTransferBox({ show: false, asset_id: "" });
                setTransferTo("");
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>
          <p className="mb-sm op-2 cap">
            Current Class :{" "}
            <span className="c__blue cap">{params.class_name}</span>
          </p>
          <div className="content">
            <Form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault();
                const response = await dispatch(
                  transferAssets({
                    authKey: props.authKey,
                    classId: params.class_id,
                    assetId: transferBox.asset_id,
                    body: JSON.stringify({
                      class_id: transferTo,
                    }),
                  })
                );
                if (response.payload.status === 200) {
                  setTransferBox({ show: false, asset_id: "" });
                  setTransferTo("");
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.success,
                  });
                  dispatch(
                    getAssets({
                      authKey: props.authKey,
                      classId: params.class_id,
                    })
                  );
                } else {
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.danger,
                  });
                }
              }}
            >
              <div className="mb-md">
                <label
                  htmlFor="transfer"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  Selet the class to move this item
                </label>
                <select
                  className="form__input cap"
                  name="transfer"
                  value={transferTo}
                  onChange={(e) => setTransferTo(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {props.userData.classes.map((classData) => {
                    return classData.default_class_id ===
                      envConfig.defaultClass.land ? (
                      <></>
                    ) : (
                      <option
                        key={classData.id}
                        value={classData.id}
                        disabled={classData.id === parseInt(params.class_id)}
                      >
                        {classData.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Move
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* asset transfer */}
      <Popup
        open={moveAssetBox.show}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue">Transfer Asset</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setMoveAssetBox({ show: false, asset_id: "" });
                setMoveTo("");
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>
          <p className="mb-sm op-2 cap">
            Current Class :{" "}
            <span className="c__blue cap">{params.class_name}</span>
          </p>
          <div className="content">
            <Form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault();
                const response = await dispatch(
                  moveAssets({
                    authKey: props.authKey,
                    classId: params.class_id,
                    assetId: moveAssetBox.asset_id,
                    body: JSON.stringify({
                      class_id: moveTo,
                    }),
                  })
                );
                if (response.payload.status === 200) {
                  setMoveAssetBox({ show: false, asset_id: "" });
                  setMoveTo("");
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.success,
                  });
                  dispatch(
                    getAssets({
                      authKey: props.authKey,
                      classId: params.class_id,
                    })
                  );
                } else {
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.danger,
                  });
                }
              }}
            >
              <div className="mb-md">
                <label
                  htmlFor="transfer"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  Selet the class to transfer this item
                </label>
                <select
                  className="form__input cap"
                  name="transfer"
                  value={moveTo}
                  onChange={(e) => setMoveTo(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {props.userData.classes.map((classData) => {
                    return classData.default_class_id ===
                      envConfig.defaultClass.land ? (
                      <></>
                    ) : (
                      <option
                        key={classData.id}
                        value={classData.id}
                        disabled={classData.id === parseInt(params.class_id)}
                      >
                        {classData.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Transfer
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* qr view */}
      <Popup
        open={qrBox.show}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal" ref={componentRef}>
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue">QR for {qrBox.value}</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setQRBox({ show: false, asset_id: "" });
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>
          <div className="content qr-box">
            <QRCode
              value={
                envConfig.hostURL +
                "app/asset-detail/" +
                params.class_name +
                "/" +
                params.class_id +
                "/" +
                qrBox.asset_id
              }
              size={512}
            />
          </div>
          <div className="popup__btns mt-sm">
            <Button className="btn popup__btn btn__blue" onClick={handlePrint}>
              Print
            </Button>
          </div>
        </div>
      </Popup>

      {/* fairvalue popup */}
      {fairValueBox && props.classAssetInfo?.fairValueData && (
        <Popup
          className="popup-content__width--80"
          open={fairValueBox}
          modal
          nested
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          <div className="modal popup__modal">
            <div className="header popup__header mb-sm">
              <h2 className="headin-2 popup__blue">Turn to Fair Value</h2>
              <button
                className="popup__close close"
                onClick={() => {
                  setFairValueBox(false);
                }}
              >
                <img src={cross} alt="cross" />
              </button>
            </div>

            <div className="content scroll-content scroll-content-50-min-height">
              <Form
                className="form"
                onSubmit={async (e) => {
                  e.preventDefault();
                  swal({
                    title:
                      "Please make sure you have downloaded all the reports before changing to fair value. Are you sure, you want to change to fair value?",
                    text: "",
                    icon: "warning",
                    buttons: true,
                  }).then(async (result) => {
                    if (result) {
                      const payload = { asset_list: [] };
                      if (isLand) {
                        payload.asset_list = Object.keys(
                          fairValueCalculation
                        ).map((assetId) => ({
                          asset_id: assetId,
                          area_price: fairValueCalculation[assetId].area_price,
                          assessment_date: moment(
                            fairValueCalculation[assetId].assessment_date
                          ).format("YYYY-MM-DD"),
                        }));
                      } else if (isReducingBalance) {
                        payload.asset_list = Object.keys(
                          fairValueCalculation
                        ).map((assetId) => ({
                          asset_id: assetId,
                          new_cost: fairValueCalculation[assetId].cost,
                          useful_life: fairValueCalculation[assetId].life,
                          depreciation_percentage:
                            fairValueCalculation[assetId]
                              .depreciation_percentage,
                          residual_value:
                            fairValueCalculation[assetId].residual_value,
                          assessment_date: moment(
                            fairValueCalculation[assetId].assessment_date
                          ).format("YYYY-MM-DD"),
                        }));
                      } else {
                        payload.asset_list = Object.keys(
                          fairValueCalculation
                        ).map((assetId) => ({
                          asset_id: assetId,
                          new_cost: fairValueCalculation[assetId].cost,
                          useful_life: fairValueCalculation[assetId].life,
                          depreciation_percentage:
                            fairValueCalculation[assetId]
                              .depreciation_percentage,
                          assessment_date: moment(
                            fairValueCalculation[assetId].assessment_date
                          ).format("YYYY-MM-DD"),
                        }));
                      }
                      const response = await dispatch(
                        fairValueAssets({
                          authKey: props.authKey,
                          classId: params.class_id,
                          body: JSON.stringify(payload),
                        })
                      );
                      if (response.payload.status === 200) {
                        setFairValueBox(false);
                        setFairValueCalculation({});
                        props.setMessageUser({
                          message: response.payload.message,
                          type: envConfig.notificationTypes.success,
                        });
                        dispatch(
                          getAssets({
                            authKey: props.authKey,
                            classId: params.class_id,
                          })
                        );
                      } else {
                        props.setMessageUser({
                          message: response.payload.message,
                          type: envConfig.notificationTypes.danger,
                        });
                      }
                    }
                  });
                }}
              >
                {props.classAssetInfo?.fairValueData?.map((item, index) => {
                  const currentAssetId = item.filter(
                    ({ default_column_id }) => {
                      return (
                        default_column_id === envConfig.defaultColumn.asset_id
                      );
                    }
                  )[0]?.value;
                  const storeAssetId = item.filter(({ default_column_id }) => {
                    return (
                      default_column_id === envConfig.defaultColumn.asset_id
                    );
                  })[0]?.asset_id;
                  return (
                    <div
                      key={index}
                      className={`listing__grid-${
                        isLand ? 3 : isReducingBalance ? 6 : 5
                      }`}
                    >
                      <div className="mb-md">
                        <label
                          className="form__label mb-s-sm c__blue"
                          aria-hidden="true"
                        >
                          Asset Id
                        </label>
                        <Input
                          className="form__input "
                          type="text"
                          value={currentAssetId}
                          readOnly
                        />
                      </div>
                      <div className="mb-md">
                        <label
                          htmlFor="assessment_date"
                          className="form__label cap mb-s-sm"
                          aria-hidden="true"
                        >
                          Date of Assessment
                        </label>
                        <DatePicker
                          name="assessment_date"
                          id="assessment_date"
                          className="date__input"
                          minDate={
                            new Date(props.userData.organization.fy_start)
                          }
                          maxDate={new Date()}
                          value={
                            fairValueCalculation[storeAssetId]
                              ? fairValueCalculation[storeAssetId]
                                  .assessment_date
                              : ""
                          }
                          onChange={(e) => {
                            const currentFairValueCalculation = Object.assign(
                              {},
                              fairValueCalculation
                            );
                            currentFairValueCalculation[storeAssetId] =
                              currentFairValueCalculation[storeAssetId]
                                ? {
                                    ...currentFairValueCalculation[
                                      storeAssetId
                                    ],
                                    assessment_date: e,
                                  }
                                : { assessment_date: e };
                            setFairValueCalculation(
                              currentFairValueCalculation
                            );
                          }}
                          required
                        />
                      </div>
                      {isLand && (
                        <div className="mb-md">
                          <label
                            className="form__label mb-s-sm c__blue"
                            aria-hidden="true"
                          >
                            Price Per Sq. Mtr.
                          </label>
                          <Input
                            className="form__input "
                            type="tel"
                            minLength={1}
                            maxLength={16}
                            value={
                              fairValueCalculation[storeAssetId]
                                ? fairValueCalculation[storeAssetId].area_price
                                : ""
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              const currentFairValueCalculation = Object.assign(
                                {},
                                fairValueCalculation
                              );
                              const currentAreaPrice =
                                isNaN(value) || value > 1000000000
                                  ? currentFairValueCalculation[storeAssetId]
                                    ? currentFairValueCalculation[storeAssetId]
                                        .area_price
                                    : ""
                                  : value;
                              currentFairValueCalculation[storeAssetId] =
                                currentFairValueCalculation[storeAssetId]
                                  ? {
                                      ...currentFairValueCalculation[
                                        storeAssetId
                                      ],
                                      area_price: currentAreaPrice,
                                    }
                                  : { area_price: currentAreaPrice };
                              setFairValueCalculation(
                                currentFairValueCalculation
                              );
                            }}
                            placeholder={
                              item.filter(({ default_column_id }) => {
                                return (
                                  default_column_id ===
                                  envConfig.defaultColumn.area_price
                                );
                              })[0]?.value
                            }
                            required
                          />
                          <span className="form__label--warning">
                            {(fairValueCalculation[storeAssetId] &&
                            fairValueCalculation[storeAssetId].area_price
                              ? fairValueCalculation[storeAssetId].area_price
                              : "") === ""
                              ? ""
                              : amountFormat(
                                  fairValueCalculation[storeAssetId].area_price,
                                  0
                                )}
                          </span>
                        </div>
                      )}
                      {!isLand && (
                        <>
                          <div className="mb-md">
                            <label
                              className="form__label mb-s-sm c__blue"
                              aria-hidden="true"
                            >
                              Cost
                            </label>
                            <Input
                              className="form__input "
                              type="tel"
                              minLength="1"
                              maxLength="50"
                              value={
                                fairValueCalculation[storeAssetId]
                                  ? fairValueCalculation[storeAssetId].cost
                                  : ""
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                const currentFairValueCalculation =
                                  Object.assign({}, fairValueCalculation);
                                const currentPurchaseCost =
                                  isNaN(value) || value > 1000000000
                                    ? currentFairValueCalculation[storeAssetId]
                                      ? currentFairValueCalculation[
                                          storeAssetId
                                        ].cost
                                      : ""
                                    : value;
                                currentFairValueCalculation[storeAssetId] =
                                  currentFairValueCalculation[storeAssetId]
                                    ? {
                                        ...currentFairValueCalculation[
                                          storeAssetId
                                        ],
                                        cost: currentPurchaseCost,
                                        residual_value:
                                          currentPurchaseCost === ""
                                            ? ""
                                            : currentFairValueCalculation[
                                                storeAssetId
                                              ].residual_value &&
                                              currentFairValueCalculation[
                                                storeAssetId
                                              ].residual_value >
                                                currentPurchaseCost / 2
                                            ? currentPurchaseCost / 2
                                            : currentFairValueCalculation[
                                                storeAssetId
                                              ].residual_value,
                                      }
                                    : { cost: currentPurchaseCost };
                                setFairValueCalculation(
                                  currentFairValueCalculation
                                );
                              }}
                              placeholder={
                                item.filter(({ default_column_id }) => {
                                  return (
                                    default_column_id ===
                                    envConfig.defaultColumn.initial_cost
                                  );
                                })[0]?.value
                              }
                              required
                            />
                            <span className="form__label--warning">
                              {(fairValueCalculation[storeAssetId] &&
                              fairValueCalculation[storeAssetId].cost
                                ? fairValueCalculation[storeAssetId].cost
                                : "") === ""
                                ? ""
                                : amountFormat(
                                    fairValueCalculation[storeAssetId].cost,
                                    0
                                  )}
                            </span>
                          </div>
                          <div className="mb-md">
                            <label
                              className="form__label mb-s-sm c__blue"
                              aria-hidden="true"
                            >
                              Useful Life
                            </label>
                            <Input
                              className="form__input "
                              type="tel"
                              value={
                                fairValueCalculation[storeAssetId]
                                  ? fairValueCalculation[storeAssetId].life
                                  : ""
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                const currentFairValueCalculation =
                                  Object.assign({}, fairValueCalculation);
                                const currenUsefulLife =
                                  isNaN(value) || parseInt(value) > 200
                                    ? currentFairValueCalculation[storeAssetId]
                                      ? currentFairValueCalculation[
                                          storeAssetId
                                        ].life
                                      : ""
                                    : value === ""
                                    ? value
                                    : parseInt(value);
                                const currentDepreciationPercentage =
                                  !isReducingBalance
                                    ? currenUsefulLife === ""
                                      ? ""
                                      : Math.round((100 / value) * 100) / 100
                                    : currentFairValueCalculation[
                                        storeAssetId
                                      ] &&
                                      currentFairValueCalculation[storeAssetId]
                                        .depreciation_percentage
                                    ? currentFairValueCalculation[storeAssetId]
                                        .depreciation_percentage
                                    : "";
                                currentFairValueCalculation[storeAssetId] =
                                  currentFairValueCalculation[storeAssetId]
                                    ? {
                                        ...currentFairValueCalculation[
                                          storeAssetId
                                        ],
                                        life: currenUsefulLife,
                                        depreciation_percentage:
                                          currentDepreciationPercentage,
                                      }
                                    : {
                                        life: currenUsefulLife,
                                        depreciation_percentage:
                                          currentDepreciationPercentage,
                                      };
                                setFairValueCalculation(
                                  currentFairValueCalculation
                                );
                              }}
                              placeholder={parseInt(
                                item.filter(({ default_column_id }) => {
                                  return (
                                    default_column_id ===
                                    envConfig.defaultColumn.remaining_life
                                  );
                                })[0]?.value
                              )}
                            />
                            <span className="form__label--warning">
                              Value should not more than 200
                            </span>
                          </div>
                          <div className="mb-md">
                            <label
                              className="form__label mb-s-sm c__blue"
                              aria-hidden="true"
                            >
                              Depreciation Percentage
                            </label>
                            <Input
                              className="form__input"
                              type="tel"
                              value={
                                fairValueCalculation[storeAssetId]
                                  ? fairValueCalculation[storeAssetId]
                                      .depreciation_percentage
                                  : ""
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                const currentFairValueCalculation =
                                  Object.assign({}, fairValueCalculation);
                                const currentDepreciationPercentage =
                                  isNaN(value) || parseInt(value) > 100
                                    ? currentFairValueCalculation[
                                        storeAssetId
                                      ] &&
                                      currentFairValueCalculation[storeAssetId]
                                        .depreciation_percentage
                                      ? currentFairValueCalculation[
                                          storeAssetId
                                        ].depreciation_percentage
                                      : ""
                                    : value;
                                currentFairValueCalculation[storeAssetId] =
                                  currentFairValueCalculation[storeAssetId]
                                    ? {
                                        ...currentFairValueCalculation[
                                          storeAssetId
                                        ],
                                        depreciation_percentage:
                                          currentDepreciationPercentage,
                                      }
                                    : {
                                        depreciation_percentage:
                                          currentDepreciationPercentage,
                                      };
                                setFairValueCalculation(
                                  currentFairValueCalculation
                                );
                              }}
                              placeholder={
                                item.filter(({ default_column_id }) => {
                                  return (
                                    default_column_id ===
                                    envConfig.defaultColumn
                                      .depreciation_percentage
                                  );
                                })[0]?.value
                              }
                              required
                            />
                          </div>
                        </>
                      )}
                      {!isLand && isReducingBalance && (
                        <div className="mb-md">
                          <label
                            className="form__label mb-s-sm c__blue"
                            aria-hidden="true"
                          >
                            Reducing Balance
                          </label>
                          <Input
                            className="form__input "
                            type="tel"
                            maxLength="50"
                            minLength="1"
                            value={
                              fairValueCalculation[storeAssetId]
                                ? fairValueCalculation[storeAssetId]
                                    .residual_value
                                : ""
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              const currentFairValueCalculation = Object.assign(
                                {},
                                fairValueCalculation
                              );
                              const currentResidualValue =
                                isNaN(value) ||
                                (currentFairValueCalculation[storeAssetId] &&
                                currentFairValueCalculation[storeAssetId].cost
                                  ? currentFairValueCalculation[storeAssetId]
                                      .cost
                                  : "") === "" ||
                                parseInt(value) >
                                  currentFairValueCalculation[storeAssetId]
                                    .cost /
                                    2
                                  ? currentFairValueCalculation[storeAssetId] &&
                                    currentFairValueCalculation[storeAssetId]
                                      .residual_value
                                    ? currentFairValueCalculation[storeAssetId]
                                        .residual_value
                                    : ""
                                  : value;
                              currentFairValueCalculation[storeAssetId] =
                                currentFairValueCalculation[storeAssetId]
                                  ? {
                                      ...currentFairValueCalculation[
                                        storeAssetId
                                      ],
                                      residual_value: currentResidualValue,
                                    }
                                  : { residual_value: currentResidualValue };
                              setFairValueCalculation(
                                currentFairValueCalculation
                              );
                            }}
                            placeholder={
                              item.filter(({ default_column_id }) => {
                                return (
                                  default_column_id ===
                                  envConfig.defaultColumn.residual_value
                                );
                              })[0]?.value
                            }
                            required
                          />
                          <span className="form__label--warning">
                            Residual value must be an insignificant amount of
                            cost
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className="popup__btns">
                  <Button className="btn popup__btn btn__blue" type="submit">
                    Turn to Fair Value
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    userData: getUserData(state),
    classAssets: getClassAssets(state),
    classAssetInfo: getClassAssetInfo(state),
  }),
  { setMessageUser, clearClassColumn }
)(Assets);
