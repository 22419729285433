import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Button, Form, Input } from "reactstrap";
import swal from "sweetalert";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import envConfig from "../../config/constants";
import { amountFormat } from "../../config/utility";
import { getAssetList } from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import { getAuthKey, getTranslations, getUserData } from "../../redux/selector";

const InvestmentModel = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [classList, setClassList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [presentValue, setPresentValue] = useState(
    state?.presentValue ? state.presentValue : ""
  );
  const [inflationRate, setInflationRate] = useState(
    state?.inflationRate ? state.inflationRate : ""
  );
  const [interestRate, setInterestRate] = useState(
    state?.interestRate ? state.interestRate : ""
  );
  const [paymentCount, setPaymentCount] = useState(
    state?.paymentCount ? state.paymentCount : "12"
  );
  const [yearCount, setYearCount] = useState(
    state?.yearCount ? state.yearCount : ""
  );

  const verifyInput = (type, value) => {
    switch (type) {
      case "present_value":
        setPresentValue(
          isNaN(value) || value > 1000000000 ? presentValue : value
        );
        break;
      case "inflation_rate":
        setInflationRate(
          isNaN(value.trim()) || value.trim() >= 100
            ? inflationRate
            : value.trim()
        );
        break;
      case "interest_rate":
        setInterestRate(
          isNaN(value.trim()) || value.trim() >= 100
            ? interestRate
            : value.trim()
        );
        break;
      case "year_count":
        setYearCount(
          isNaN(value.trim()) || value.trim() > 200
            ? yearCount
            : value.trim() === ""
            ? ""
            : parseInt(value.trim())
        );
        break;
      case "payment_count":
        setPaymentCount(
          isNaN(value.trim()) || value.trim() > 12
            ? paymentCount
            : value.trim() === ""
            ? ""
            : parseInt(value.trim())
        );
        break;
      default:
        break;
    }
  };

  const populateAssets = async (e) => {
    setSelectedClass(e);
    const { payload } = await dispatch(
      getAssetList({ authKey: props.authKey, classId: e.id })
    );
    if (payload.status === 200) {
      let paramAsset = null;
      setAssetList(
        payload.data.map(({ id, asset_id, description }) => {
          if (
            parseInt(params.asset_id) === id ||
            state?.selectedAsset.id === id
          ) {
            paramAsset = {
              id,
              value: id,
              label: `${asset_id}  |   ${description}`,
            };
          }
          return {
            id,
            value: id,
            label: `${asset_id}  |   ${description}`,
          };
        })
      );
      if (paramAsset) {
        setSelectedAsset(paramAsset);
      }
    } else {
      setAssetList([]);
      setSelectedAsset(null);
    }
  };

  useEffect(() => {
    let paramClass = null;
    setClassList(
      props.userData.classes.map(({ id, name }) => {
        if (
          parseInt(params.class_id) === id ||
          state?.selectedClass.id === id
        ) {
          paramClass = {
            id,
            value: id,
            label: name,
          };
        }
        return {
          id,
          value: id,
          label: name,
        };
      })
    );
    if (paramClass) {
      setSelectedClass(paramClass);
      populateAssets(paramClass);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.class_id, props.userData.classes]);
  return (
    <>
      <TopNav />
      <SideNav />
      <div className="listings">
        <BreadCrumbs crumbs={["Investment Model"]} />
        <div className="listing">
          <h3 className="heading-3 mb-sm">
            {props.translations.investmentModel.title.para}
          </h3>
          <div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                if (!selectedClass || !selectedAsset) {
                  props.setMessageUser({
                    type: envConfig.notificationTypes.warning,
                    message: "Please select class and asset",
                  });
                  return;
                }
                if (parseFloat(inflationRate) >= parseFloat(interestRate)) {
                  swal({
                    title: "Bad Investment",
                    text: "This investment is not good as the inflation rate is more than discount / interest rate.",
                    icon: "warning",
                  });
                  return;
                }
                navigate("/app/investment-model-report", {
                  state: {
                    selectedClass,
                    selectedAsset,
                    presentValue,
                    interestRate,
                    inflationRate,
                    paymentCount,
                    yearCount,
                  },
                });
              }}
            >
              <div className="defer__grid2">
                <div className="">
                  <label
                    htmlFor="testing"
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    {props.translations.investmentModel.form.class.label}
                  </label>
                  <Select
                    className="cap"
                    placeholder={"Search and Select"}
                    value={selectedClass}
                    options={classList}
                    onChange={populateAssets}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="testing"
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    {props.translations.investmentModel.form.asset.label}
                  </label>
                  <Select
                    placeholder={"Search and Select"}
                    value={selectedAsset}
                    options={assetList}
                    onChange={(e) => {
                      setSelectedAsset(e);
                    }}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor={`${props.translations.investmentModel.form.present_value.id}`}
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    {
                      props.translations.investmentModel.form.present_value
                        .label
                    }
                  </label>
                  <div className="defer__box">
                    <Input
                      className="form__input defer__input"
                      type={`${props.translations.investmentModel.form.present_value.type}`}
                      minLength={`${props.translations.investmentModel.form.present_value.min_length}`}
                      maxLength={`${props.translations.investmentModel.form.present_value.max_length}`}
                      aria-describedby={`${props.translations.investmentModel.form.present_value.placeholder}`}
                      placeholder={`${props.translations.investmentModel.form.present_value.placeholder}`}
                      value={presentValue}
                      onChange={(e) => {
                        verifyInput("present_value", e.target.value.trim());
                      }}
                      required
                    />
                  </div>
                  <span className="form__label--warning">
                    {presentValue === "" ? "" : amountFormat(presentValue, 0)}
                  </span>
                </div>

                <div className="">
                  <div className="defer__grid2">
                    <div>
                      <label
                        htmlFor={`${props.translations.investmentModel.form.discount_rate.id}`}
                        className="form__label cap mb-s-sm"
                        aria-hidden="true"
                      >
                        {
                          props.translations.investmentModel.form.discount_rate
                            .label
                        }
                      </label>
                      <div className="defer__box">
                        <Input
                          className="form__input defer__input"
                          type={`${props.translations.investmentModel.form.discount_rate.type}`}
                          minLength={`${props.translations.investmentModel.form.discount_rate.min_length}`}
                          maxLength={`${props.translations.investmentModel.form.discount_rate.max_length}`}
                          aria-describedby={`${props.translations.investmentModel.form.discount_rate.placeholder}`}
                          placeholder={`${props.translations.investmentModel.form.discount_rate.placeholder}`}
                          value={interestRate}
                          onChange={(e) => {
                            verifyInput("interest_rate", e.target.value.trim());
                          }}
                          required
                        />
                      </div>
                      <span className="form__label--warning">
                        {
                          props.translations.investmentModel.validation
                            .not_more_hund
                        }
                      </span>
                    </div>
                    <div>
                      <label
                        htmlFor={`${props.translations.investmentModel.form.inflation_rate.id}`}
                        className="form__label cap mb-s-sm"
                        aria-hidden="true"
                      >
                        {
                          props.translations.investmentModel.form.inflation_rate
                            .label
                        }
                      </label>
                      <div className="defer__box">
                        <Input
                          className="form__input defer__input"
                          type={`${props.translations.investmentModel.form.inflation_rate.type}`}
                          minLength={`${props.translations.investmentModel.form.inflation_rate.min_length}`}
                          maxLength={`${props.translations.investmentModel.form.inflation_rate.max_length}`}
                          aria-describedby={`${props.translations.investmentModel.form.inflation_rate.placeholder}`}
                          placeholder={`${props.translations.investmentModel.form.inflation_rate.placeholder}`}
                          value={inflationRate}
                          onChange={(e) => {
                            verifyInput(
                              "inflation_rate",
                              e.target.value.trim()
                            );
                          }}
                          required
                        />
                      </div>
                      <span className="form__label--warning">
                        {
                          props.translations.investmentModel.validation
                            .not_more_discount_or_hund
                        }
                      </span>
                    </div>
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor={`${props.translations.investmentModel.form.payments.id}`}
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    {props.translations.investmentModel.form.payments.label}
                  </label>
                  <div className="defer__box">
                    <Input
                      className="form__input defer__input"
                      type={`${props.translations.investmentModel.form.payments.type}`}
                      minLength={`${props.translations.investmentModel.form.payments.min_length}`}
                      maxLength={`${props.translations.investmentModel.form.payments.max_length}`}
                      aria-describedby={`${props.translations.investmentModel.form.payments.placeholder}`}
                      placeholder={`${props.translations.investmentModel.form.payments.placeholder}`}
                      value={paymentCount}
                      onChange={(e) => {
                        verifyInput("payment_count", e.target.value.trim());
                      }}
                      required
                    />
                  </div>
                  <span className="form__label--warning">
                    {props.translations.investmentModel.validation.not_more_twe}
                  </span>
                </div>

                <div className="">
                  <label
                    htmlFor={`${props.translations.investmentModel.form.years.id}`}
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    {props.translations.investmentModel.form.years.label}
                  </label>
                  <div className="defer__box">
                    <Input
                      className="form__input defer__input"
                      type={`${props.translations.investmentModel.form.years.type}`}
                      minLength={`${props.translations.investmentModel.form.years.min_length}`}
                      maxLength={`${props.translations.investmentModel.form.years.max_length}`}
                      aria-describedby={`${props.translations.investmentModel.form.years.placeholder}`}
                      placeholder={`${props.translations.investmentModel.form.years.placeholder}`}
                      value={yearCount}
                      onChange={(e) => {
                        verifyInput("year_count", e.target.value.trim());
                      }}
                      required
                    />
                  </div>
                  <span className="form__label--warning">
                    {
                      props.translations.investmentModel.validation
                        .not_more_two_hund
                    }
                  </span>
                </div>
              </div>
              <div className="popup__btns mt-sm">
                <Button type="submit" className="btn btn__blue cap">
                  {props.translations.investmentModel.button.submit}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    authKey: getAuthKey(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(InvestmentModel);
