// Environment file
const envConfig = {
  isDev: false,
  // hostURL: "http://localhost:3000/",
  // hostURL: "http://192.168.1.104:3000/",
  // hostURL: "http://virgilproper.terasol.in/",
  hostURL: "https://www.virgilproper.com/",
  // adminURL: "http://virgilproperadmin.terasol.in/app/admin/",
  adminURL: "https://vpadmin.virgilproper.com/app/admin/",
  language: {
    en: "en",
    fr: "fr",
  },
  api: {
    // baseURL: "http://localhost:3001",
    // baseURL: "http://192.168.1.104:3001",
    // baseURL: "http://api.terasol.in:3003",
    baseURL: "https://api.virgilproper.com",
    helpURL: "/docs/tutorial.pdf",
    termsURL: "/docs/terms.pdf",
    login: "/login/user",
    loginAccess: "/login/user/access-key",
    self: "/user/self",
    registerVerify: "/register/user-verify",
    register: "/register/user",
    forgot: "/register/user-forgot",
    forgotVerify: "/register/user-reset",
    countries: "/user/countries",
    updateSelf: "/user/self",
    defaultClass: "/user/class/default",
    defaultColumn: "/user/column/default",
    createColumns: {
      base: "/user/column/",
      multiple: "/multiple",
    },
    getAssets: {
      base: "/user/class/asset/",
      all: "/all",
    },
    createAssets: "/user/class/asset/",
    getAssetDetails: "/user/class/asset/",
    updateAssets: "/user/class/asset/",
    deleteAssets: "/user/class/asset/",
    disposeAssets: {
      base: "/user/class/asset/",
      dispose: "/dispose-asset",
      disposeLand: "/dispose-land-asset",
    },
    statusUpdateAssets: {
      base: "/user/class/asset/",
      status: "/status",
    },
    impairAssets: {
      base: "/user/class/asset/",
      impairment: "/impairment",
    },
    tranferAssets: {
      base: "/user/class/asset/",
      transfer: "/transfer",
    },
    moveAssets: {
      base: "/user/class/asset/",
      movement: "/movement",
    },
    dashboard: "/user/dashboard",
    getAssetList: {
      base: "/user/class/asset/",
      detail: "/detail",
    },
    calculateDeferredTax: {
      base: "/user/class/asset/",
      deferTax: "/defer-tax",
    },
    calculateInvestment: {
      base: "/user/class/asset/",
      investment: "/investment",
    },
    reports: {
      base: "/user/report/",
      schedule: "schedule",
      reconciliationFormat1: "reconciliation-format-1",
      reconciliationFormat2: "reconciliation-format-2",
      additionToPPE: "addition-to-ppe",
      allItems: "all-item",
      workingItems: "working-item",
      nonWorkingItems: "non-working-item",
      depreciatedItems: "depreciation",
    },
    createAccountant: "/user/accountant",
    getAccountants: "/user/accountant/",
    deleteAccountant: "/user/accountant/",
    createSubsidiary: "/user/subsidiary",
    getSubsidiaries: "/user/subsidiary/",
    accessSubsidiaries: {
      base: "/user/subsidiary/",
      accessKey: "/access-key",
    },
    fairValueAssets: {
      base: "/user/class/asset/",
      fairValue: "/fair-value",
    },
    forwardOneYear: {
      base: "/user/class/asset/",
      forward: "/forward",
    },
    checkDuplicate: {
      base: "/user/class/asset/",
      check: "/check",
    },
  },
  reports: {
    ppeSchedule: "PPE Schedule",
    ppeReconciliation: "PPE Reconciliation",
    additionToPPE: "Addition to PPE Report",
    allItems: "All Items Report",
    workingItems: "Working Items Report",
    nonWorkingItems: "Non-working Items Report",
    depreciatedItems: "Fully Depreciated and Working Items Report",
    format: {
      PDF: "PDF",
      XLS: "XLS",
    },
    type: {
      summary: "SUMMARY",
      detailed: "DETAILED",
    },
  },
  storageKeys: {
    authKey: "vpa",
    registerKey: "rpa",
    subsidiaryAuthKey: "spa",
  },
  notificationTypes: {
    success: "success",
    info: "info",
    warning: "warning",
    danger: "danger",
  },
  notificationTime: 3000,
  reportingItems: [
    {
      id: 1,
      label: "International Public Sector Accounting Standards (IPSAS)",
      name: "IPSAS",
    },
    {
      id: 2,
      label: "International Financial Reporting Standards (IFRS)",
      name: "IFRS",
    },
    {
      id: 3,
      label: "Generally Accepted Accounting Practice (GAAP)",
      name: "GAAP",
    },
  ],
  phoneNumbers: ["+264 61 225 841", "+264 81 783 0750"],
  postalAddress: "P.O. Box 55081 Rocky Crest Windhoek Namibia",
  physicalAddress: "64 Jenner Street, Windhoek, Namibia",
  logoMaxSize: 2000000,
  depreciationType: {
    STRAIGHT_LINE: "STRAIGHT_LINE",
    REDUCING_BALANCE: "REDUCING_BALANCE",
  },
  depreciationTypeName: {
    STRAIGHT_LINE: "Straight Line",
    REDUCING_BALANCE: "Reducing Balance",
  },
  defaultClass: {
    land: 1,
    buildings: 2,
    computers: 3,
    office: 4,
    infrastructure: 5,
    motor: 6,
    plant: 7,
    heritage: 8,
    biological: 9,
    other: 10,
  },
  columnType: {
    DEFAULT: "DEFAULT",
    ADDITIONAL: "ADDITIONAL",
  },
  columnDataType: [
    {
      id: 1,
      value: "NUMERIC",
    },
    {
      id: 2,
      value: "TEXT",
    },
  ],
  columnDataTypeValue: {
    NUMERIC: "NUMERIC",
    TEXT: "TEXT",
  },
  accessRights: {
    admin: 0,
    accountant_1: 1,
    accountant_2: 2,
  },
  accessRightsData: [
    {
      name: "Full Access",
      value: 1,
    },
    {
      name: "Read Only",
      value: 2,
    },
  ],
  assetStatus: {
    WORKING: "WORKING",
    NON_WORKING: "NON_WORKING",
    DEPRECIATED: "DEPRECIATED",
    DISPOSED: "DISPOSED",
    FULL_DEPRECIATED_WORKING: "FULL_DEPRECIATED_WORKING",
  },
  assetStatusValue: {
    WORKING: "Fully Working Item",
    NON_WORKING: "Non-Working Item",
    DEPRECIATED: "Fully Depreciated",
    DISPOSED: "Disposed",
    FULL_DEPRECIATED_WORKING: "Fully Depreciated and Working",
  },
  assetType: [
    {
      key: 1,
      name: "Purchase",
      value: "PURCHASE",
    },
    {
      key: 2,
      name: "Construction",
      value: "CONSTRUCTION",
    },
    {
      key: 3,
      name: "Donation",
      value: "DONATION",
    },
  ],
  landType: [
    { key: 1, name: "Serviced", value: "serviced" },
    { key: 2, name: "Unserviced", value: "unserviced" },
  ],
  usefulLifeInfinity: "INFINITY",
  usefulLifeName: "INDEFINITE",
  useFulLife: {
    PROSPECTIVE: { value: 1, name: "Prospective" },
    RETROSPECTIVE: { value: 2, name: "Retrospective" },
  },
  defaultColumn: {
    asset_id: 1,
    description: 2,
    type: 3,
    purchase_date: 4,
    purchase_cost: 5,
    depreciation: 6,
    accumulated_depreciation: 7,
    carrying_amount: 8,
    useful_life: 9,
    consumed_life: 10,
    remaining_life: 11,
    residual_value: 12,
    depreciation_percentage: 13,
    initial_cost: 14,
    initial_date: 15,
    area: 16,
    area_price: 17,
    land_type: 18,
    end_date: 19,
  },
  impairmentNature: ["external", "internal"],
  impairmentSource: [
    "Reduced demand for products or services provided by the item of ppe",
    "Long term changes in technological, legal, or government policy environment in which the entity operates",
    "Available evidence of physical damage",
    "Significant changes in the manner in which the asset was being used or will be used - Plans to restructure the operations to which the asset is being used",
    "Significant changes in the manner in which the asset was being used or will be used - Plans to dispose the asset before the previously set date",
    "Significant changes in the manner in which the asset was being used or will be used - Discontinuing operations undertaken by the asset",
    "Significant changes in the manner in which the asset was being used or will be used - The asset becoming idle",
    "Decision to halt construction or building an asset before it complete or in usable condition",
    "Other evidence demonstrating that the performance of an item of ppe is significantly or will be significantly worse",
  ],
  graphFilter: {
    COST: "COST",
    CARRYING_AMOUNT: "CARRYING AMOUNT",
    ACC_DEPRECIATION: "ACC DEPRECIATION",
    IMPAIRMENT: "IMPAIRMENT",
  },
};

export default envConfig;
