import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import ScreenLoader from "../components/screenLoader";
import { getAuthKey, getUserData } from "../redux/selector";

const PrivateRoute = ({ component: Component, type, authKey, userData }) => {
  return authKey ? (
    userData ? (
      userData.setup ? (
        type === "setup" ? (
          <Navigate to="/app/home" />
        ) : userData.setup_depreciation ? (
          type === "setup-depreciation" ? (
            <Navigate to="/app/home" />
          ) : userData.setup_class ? (
            type === "setup-class" ? (
              <Navigate to="/app/home" />
            ) : userData.setup_column ? (
              type === "setup-column" ? (
                <Navigate to="/app/home" />
              ) : (
                <Component />
              )
            ) : type === "setup-column" ? (
              <Component />
            ) : (
              <Navigate to="/app/setup-column" />
            )
          ) : type === "setup-class" ? (
            <Component />
          ) : (
            <Navigate to="/app/setup-class" />
          )
        ) : type === "setup-depreciation" ? (
          <Component />
        ) : (
          <Navigate to="/app/setup-depreciation" />
        )
      ) : type === "setup" ? (
        <Component />
      ) : (
        <Navigate to="/app/setup" />
      )
    ) : (
      <ScreenLoader />
    )
  ) : (
    <Navigate to="/auth/login" />
  );
};

export default connect((state) => ({
  authKey: getAuthKey(state),
  userData: getUserData(state),
}))(PrivateRoute);
