import { createSlice } from "@reduxjs/toolkit";
import { getCountries } from "../api";

const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    countries: [],
    currencies: [],
    predicted_data: {
      country: "",
      currency: "",
    },
  },
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(getCountries.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          state.countries = action.payload.data.countries;
          state.currencies = action.payload.data.currencies;
          state.predicted_data = action.payload.data.predicted_data;
        }
      })
      .addCase(getCountries.pending, (state) => {
        state.loading = true;
      });
  },
});

export default countriesSlice.reducer;
