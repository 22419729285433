import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-date-picker";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { Button, Form, Input } from "reactstrap";
import swal from "sweetalert";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import {
  cross,
  edit,
  emptyHistory,
  emptyImpairment,
  life1,
  life2,
  warning,
} from "../../config/assets";
import envConfig from "../../config/constants";
import {
  amountFormat,
  checkEditAccessRight,
  verifyClassLand,
  verifyClassNameAndId,
  verifyFairValue,
  verifyReducingBalance,
} from "../../config/utility";
import {
  deleteAssets,
  disposeAssets,
  fastForwardOneYear,
  getAssetDetails,
  updateAssets,
} from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import {
  getAssetDetailData,
  getAuthKey,
  getTranslations,
  getUserData,
} from "../../redux/selector";

const AssetDetail = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLand = verifyClassLand(props.userData.classes, params.class_id);
  const isFairValue = verifyFairValue(props.userData.classes, params.class_id);
  const isReadOnly = checkEditAccessRight(props.userData.access_right);
  const isReducingBalance = verifyReducingBalance(
    props.userData.organization.depreciation_type
  );

  const [editBox, setEditBox] = useState({ show: false, type: "", name: "" });
  const [editBoxUesfulLife, setEditBoxUsefulLife] = useState(false);
  const [disposeBox, setDisposeBox] = useState(false);
  const [historyBox, setHistoryBox] = useState(false);
  const [impairmentBox, setImpairmentBox] = useState(false);
  const [landDisposeLogBox, setLandDisposeLogBox] = useState(false);
  const [disposeAmount, setDisposeAmount] = useState("");
  const [disposeDate, setDisposeDate] = useState("");
  const [editAddition, setEditAddition] = useState({
    id: "",
    value: "",
    type: "",
  });
  const [editAssetType, setEditAssetType] = useState("");
  const [editPurchaseCost, setEditPurchaseCost] = useState("");
  const [editPurchaseDate, setEditPurchaseDate] = useState("");
  const [editResidualValue, setEditResidualValue] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editLandType, setEditLandType] = useState("");
  const [editUsefulLifeWay, setEditUsefulLifeWay] = useState(0);
  const [editUsefulLife, setEditUsefulLife] = useState("");
  const [editDepreciation, setEditDepreciation] = useState("");
  const [deleteBox, setDeleteBox] = useState(false);
  const [deleteWrite, setDeleteWrite] = useState("");

  useEffect(() => {
    if (
      !verifyClassNameAndId(
        props.userData.classes,
        params.class_name,
        params.class_id
      )
    )
      navigate("/app/dashboard");
    else {
      dispatch(
        getAssetDetails({
          authKey: props.authKey,
          classId: params.class_id,
          assetId: params.asset_id,
        })
      );
    }
  }, [
    dispatch,
    navigate,
    params.asset_id,
    params.class_id,
    params.class_name,
    props.authKey,
    props.userData.classes,
  ]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "600",
        fontSize: "15px",
        textTransform: "capitalize",
      },
    },
    rows: {
      style: {
        minHeight: "3rem", // override the row height
      },
    },
  };

  let headers = [],
    data = [],
    assetId = "",
    assetType = { column_id: "", value: "" },
    purchaseCost = { column_id: "", value: "", originalValue: 0 },
    usefulLife = { column_id: "", value: "" },
    purchaseDate = { column_id: "", value: "", originalValue: "" },
    residualValue = { column_id: "", value: "", originalValue: "" },
    description = { column_id: "", value: "" },
    landType = { column_id: "", value: "" },
    depreciation = { column_id: "", value: "" },
    lastUpdated = "";
  props.assetDetails.data.forEach((assetRow, index) => {
    if (index === 0) {
      headers = assetRow.reduce((array, asset) => {
        if (
          asset.default_column_id === envConfig.defaultColumn.asset_id ||
          (!isLand &&
            asset.default_column_id === envConfig.defaultColumn.initial_cost) ||
          asset.default_column_id === envConfig.defaultColumn.initial_date
        )
          return array;
        const selector = array.length;
        array.push({
          ...asset,
          cell: (row, index) => {
            return (
              <div>
                <span className="">
                  {row[selector].value}
                  {!isReadOnly &&
                    index === 0 &&
                    row[selector].id !== null &&
                    row[selector].default_column_id === null && (
                      <img
                        onClick={() => {
                          if (!isReadOnly) {
                            setEditBox({
                              show: true,
                              type: "addition",
                              name: asset.name,
                            });
                            setEditAddition({
                              id: row[selector].additional_column_id,
                              value: row[selector].value,
                              type: asset.type,
                            });
                          }
                        }}
                        className="ml-s-sm cursor"
                        src={edit}
                        alt="edit description"
                      />
                    )}
                </span>
              </div>
            );
          },
          selector: (row) => {
            return row[selector].value;
          },
          right: true,
          wrap: true,
        });
        return array;
      }, []);
    } else {
      data.push(
        assetRow.reduce((array, asset) => {
          if (index === 1) {
            lastUpdated = moment(asset.created_on).format(
              "DD MMM YYYY HH:mm A"
            );
            switch (asset.default_column_id) {
              case envConfig.defaultColumn.asset_id:
                assetId = asset.value;
                break;
              case envConfig.defaultColumn.type:
                assetType = { column_id: asset.column_id, value: asset.value };
                break;
              case envConfig.defaultColumn.purchase_cost:
                purchaseCost = {
                  column_id: asset.column_id,
                  value: asset.value,
                  originalValue: asset.originalValue,
                };
                break;
              case envConfig.defaultColumn.useful_life:
                usefulLife = { column_id: asset.column_id, value: asset.value };
                break;
              case envConfig.defaultColumn.purchase_date:
                purchaseDate = {
                  column_id: asset.column_id,
                  value: asset.value,
                  originalValue: asset.originalValue,
                };
                break;
              case envConfig.defaultColumn.residual_value:
                residualValue = {
                  column_id: asset.column_id,
                  value: asset.value,
                  originalValue: asset.originalValue,
                };
                break;
              case envConfig.defaultColumn.description:
                description = {
                  column_id: asset.column_id,
                  value: asset.value,
                };
                break;
              case envConfig.defaultColumn.land_type:
                landType = {
                  column_id: asset.column_id,
                  value: asset.value,
                };
                break;
              case envConfig.defaultColumn.depreciation_percentage:
                depreciation = {
                  column_id: asset.column_id,
                  value: asset.value,
                };
                break;
              default:
                break;
            }
          }

          if (
            asset.default_column_id === envConfig.defaultColumn.asset_id ||
            (!isLand &&
              asset.default_column_id ===
                envConfig.defaultColumn.initial_cost) ||
            asset.default_column_id === envConfig.defaultColumn.initial_date
          )
            return array;
          array.push(asset);
          return array;
        }, [])
      );
    }
  });

  return (
    <>
      <TopNav />
      <SideNav classId={params.class_id} className={params.class_name} />

      <div className="listings">
        <BreadCrumbs crumbs={[params.class_name, "Details"]} />
        {!isLand && props.assetDetails.is_depreciating && (
          <div
            className="assetinfo__errorsms"
            onClick={() => {
              if (!isReadOnly) setDisposeBox(true);
            }}
          >
            <img src={warning} className="assetinfo__svg" alt="warning" />
            <p>Your asset is depreciating or depreciated please take action</p>
            {!isReadOnly && (
              <div className="tooltip">
                <div className="assetinfo__btns">
                  <Button className="btn assetinfo__btn ">Dispose Asset</Button>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="listing">
          <div className="assetinfo__history">
            <div
              onClick={() => {
                setHistoryBox(true);
              }}
            >
              <span className="font-14 weight-400 border__bottom--gray cursor info__edit">
                Last Updated on {lastUpdated}
              </span>
            </div>

            <div className="assetinfo__gridHistory">
              {props.assetDetails.move_info?.refer_asset_id && (
                <div
                  onClick={() => {
                    navigate(
                      `/app/archive-asset-detail/${props.assetDetails.move_info?.refer_class_name}/${props.assetDetails.move_info?.refer_class_id}/${props.assetDetails.move_info?.refer_asset_id}`
                    );
                  }}
                >
                  <span className="font-14 weight-400 border__bottom--blue cursor info__edit">
                    Pre-transfer History
                  </span>
                </div>
              )}
              {isLand && (
                <div
                  onClick={() => {
                    setLandDisposeLogBox(true);
                  }}
                >
                  <span className="font-14 weight-400 border__bottom--blue cursor info__edit">
                    Disposal History
                  </span>
                </div>
              )}
              <div
                onClick={() => {
                  setImpairmentBox(true);
                }}
              >
                <span className="font-14 weight-400 border__bottom--gray cursor info__edit">
                  Impairment History
                </span>
              </div>
            </div>
          </div>
          <div className="assetinfo__title mt-md">
            {/* asset_get_value- */}
            <div>
              <p className="weight-700">Asset ID </p>
              <p className="info__para ">{assetId}</p>
            </div>
            {/* asset_type */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm">Asset Type</span>
                {!isReadOnly && (
                  <img
                    onClick={() => {
                      if (!isReadOnly) {
                        setEditBox({
                          show: true,
                          type: "type",
                          name: "Asset Type",
                        });
                        setEditAssetType(assetType.value.toUpperCase());
                      }
                    }}
                    className="ml-s-sm cursor"
                    src={edit}
                    alt="edit description"
                  />
                )}
              </div>
              <p className="info__para">{assetType.value}</p>
            </div>

            {/* cost */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm">
                  {isFairValue ? "Fair value" : "Cost"} (
                  {props.userData.organization?.currency_symbol})
                </span>
                {props.assetDetails.is_purchase_changeable && !isReadOnly && (
                  <img
                    onClick={() => {
                      setEditBox({
                        show: true,
                        type: "purchase_cost",
                        name: "Cost",
                      });
                      setEditPurchaseCost(purchaseCost.originalValue);
                    }}
                    className="ml-s-sm cursor"
                    src={edit}
                    alt="edit description"
                  />
                )}
              </div>
              <p className="info__para ">{purchaseCost.value}</p>
            </div>
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm">Useful Life</span>
                {!isReadOnly && !isLand && (
                  <img
                    onClick={() => {
                      setEditBoxUsefulLife(true);
                      setEditUsefulLife(
                        usefulLife.originalValue ===
                          envConfig.usefulLifeInfinity
                          ? ""
                          : usefulLife.originalValue
                      );
                      setEditDepreciation(depreciation.value);
                    }}
                    className="ml-s-sm cursor"
                    src={edit}
                    alt="userfull life"
                  />
                )}
              </div>
              <p className="info__para ">{usefulLife.value}</p>
            </div>

            {/* purchase-date */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm">Recognition Date</span>
                {props.assetDetails.is_purchase_changeable && !isReadOnly && (
                  <img
                    onClick={() => {
                      setEditBox({
                        show: true,
                        type: "purchase_date",
                        name: "Recognition Date",
                      });
                      setEditPurchaseDate(new Date(purchaseDate.originalValue));
                    }}
                    className="ml-s-sm cursor"
                    src={edit}
                    alt="edit description"
                  />
                )}
              </div>
              <p className="info__para ">{purchaseDate.value}</p>
            </div>
          </div>
          <div className="assetinfo__title">
            {/* Depreciation type */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm ">Depreciation Type</span>
              </div>

              <p className="info__para ">
                {
                  envConfig.depreciationTypeName[
                    props.userData.organization.depreciation_type
                  ]
                }
              </p>
            </div>
            {isReducingBalance && (
              <div>
                <div className="">
                  <span className="weight-700 mb-s-sm">
                    Residual Value (
                    {props.userData.organization?.currency_symbol})
                  </span>
                  {!isReadOnly && (
                    <img
                      onClick={() => {
                        setEditBox({
                          show: true,
                          type: "residual_value",
                          name: "Residual Value",
                        });
                        setEditResidualValue(residualValue.originalValue);
                      }}
                      className="ml-s-sm cursor"
                      src={edit}
                      alt="edit description"
                    />
                  )}
                </div>
                <p className="info__para ">{residualValue.value}</p>
              </div>
            )}
            {/* description */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm ">Description</span>
                {!isReadOnly && (
                  <img
                    onClick={() => {
                      setEditBox({
                        show: true,
                        type: "description",
                        name: "Description",
                      });
                      setEditDescription(description.value);
                    }}
                    className="ml-s-sm cursor"
                    src={edit}
                    alt="edit description"
                  />
                )}
              </div>
              <p className="info__para ">{description.value}</p>
            </div>
            {isLand && (
              <div>
                <div className="">
                  <span className="weight-700 mb-s-sm ">Type of Land</span>
                  {!isReadOnly && (
                    <img
                      onClick={() => {
                        setEditBox({
                          show: true,
                          type: "land_type",
                          name: "Type of Land",
                        });
                        setEditLandType(landType.value.toLowerCase());
                      }}
                      className="ml-s-sm cursor"
                      src={edit}
                      alt="edit description"
                    />
                  )}
                </div>
                <p className="info__para ">{landType.value}</p>
              </div>
            )}
          </div>
          {!isReadOnly && (
            <div className="assetinfo__history">
              <div></div>
              <div
                onClick={() => {
                  setDeleteBox(true);
                }}
              >
                <span className="font-14 weight-400 border__bottom--danger cursor">
                  Permanently delete asset
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="listing asset-listing">
          <DataTable
            columns={headers}
            data={data}
            highlightOnHover={true}
            pagination={true}
            persistTableHead={true}
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight="300px"
          />
        </div>
      </div>

      {/* common popup */}
      <Popup
        open={editBox.show}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal history">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue upper">Edit</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setEditBox({ show: false, type: "", name: "" });
                setEditDescription("");
                setEditLandType("");
                setEditPurchaseCost("");
                setEditPurchaseDate("");
                setEditResidualValue("");
                setEditAssetType("");
                setEditAddition({
                  id: "",
                  value: "",
                  type: "",
                });
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>

          <div className="content">
            <Form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault();
                let payload = {};
                switch (editBox.type) {
                  case "type":
                    payload = {
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: params.asset_id,
                      body: JSON.stringify({
                        column_id: assetType.column_id,
                        value: editAssetType,
                      }),
                    };
                    break;
                  case "land_type":
                    payload = {
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: params.asset_id,
                      body: JSON.stringify({
                        column_id: landType.column_id,
                        value: editLandType,
                      }),
                    };
                    break;
                  case "purchase_cost":
                    payload = {
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: params.asset_id,
                      body: JSON.stringify({
                        column_id: purchaseCost.column_id,
                        value: editPurchaseCost,
                      }),
                    };
                    break;
                  case "purchase_date":
                    payload = {
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: params.asset_id,
                      body: JSON.stringify({
                        column_id: purchaseDate.column_id,
                        value: moment(editPurchaseDate).format("YYYY-MM-DD"),
                      }),
                    };
                    break;
                  case "residual_value":
                    payload = {
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: params.asset_id,
                      body: JSON.stringify({
                        column_id: residualValue.column_id,
                        value: editResidualValue,
                      }),
                    };
                    break;
                  case "description":
                    payload = {
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: params.asset_id,
                      body: JSON.stringify({
                        column_id: description.column_id,
                        value: editDescription,
                      }),
                    };
                    break;
                  case "addition":
                    payload = {
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: params.asset_id,
                      body: JSON.stringify({
                        column_id: null,
                        additional_column_id: editAddition.id,
                        value: editAddition.value,
                      }),
                    };
                    break;
                  default:
                    break;
                }
                const response = await dispatch(updateAssets(payload));
                if (response.payload.status === 200) {
                  setEditBox({ show: false, name: "", type: "" });
                  setEditDescription("");
                  setEditLandType("");
                  setEditPurchaseCost("");
                  setEditPurchaseDate("");
                  setEditResidualValue("");
                  setEditAssetType("");
                  setEditAddition({
                    id: "",
                    value: "",
                    type: "",
                  });
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.success,
                  });
                  dispatch(
                    getAssetDetails({
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: params.asset_id,
                    })
                  );
                } else {
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.danger,
                  });
                }
              }}
            >
              <div className="mb-md mt-md">
                <label
                  htmlFor="commonEdit"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  {editBox.name}
                </label>
                {editBox.type === "type" && (
                  <select
                    className="form__select form__select__size-2"
                    name="text"
                    required
                    value={editAssetType}
                    onChange={(e) => {
                      setEditAssetType(e.target.value);
                    }}
                  >
                    {envConfig.assetType.map((type) => {
                      return (
                        <option key={type.id} value={type.value} required>
                          {type.name}
                        </option>
                      );
                    })}
                  </select>
                )}
                {editBox.type === "land_type" && (
                  <select
                    className="form__select form__select__size-2"
                    name="text"
                    required
                    value={editLandType}
                    onChange={(e) => {
                      setEditLandType(e.target.value);
                    }}
                  >
                    {envConfig.landType.map((type) => {
                      return (
                        <option key={type.id} value={type.value} required>
                          {type.name}
                        </option>
                      );
                    })}
                  </select>
                )}
                {editBox.type === "purchase_cost" && (
                  <>
                    <Input
                      className="form__input "
                      type="tel"
                      minLength="1"
                      maxLength="50"
                      aria-describedby="Cost"
                      value={editPurchaseCost}
                      onChange={(e) => {
                        setEditPurchaseCost(
                          isNaN(e.target.value) || e.target.value > 1000000000
                            ? editPurchaseCost
                            : e.target.value
                        );
                      }}
                      required
                    />
                    <span className="form__label--warning">
                      {editPurchaseCost === ""
                        ? ""
                        : amountFormat(editPurchaseCost, 0)}
                    </span>
                  </>
                )}
                {editBox.type === "purchase_date" && (
                  <DatePicker
                    name="date"
                    id="recognition date"
                    className="date__input"
                    minDate={new Date(props.userData.organization.fy_start)}
                    maxDate={new Date()}
                    value={editPurchaseDate}
                    onChange={(e) => setEditPurchaseDate(e)}
                    required
                  />
                )}
                {editBox.type === "residual_value" && (
                  <>
                    <Input
                      className="form__input "
                      type="tel"
                      minLength="1"
                      maxLength="50"
                      aria-describedby="Cost"
                      value={editResidualValue}
                      onChange={(e) => {
                        setEditResidualValue(
                          isNaN(e.target.value) ||
                            parseInt(e.target.value) >
                              purchaseCost.originalValue / 2
                            ? editResidualValue
                            : e.target.value
                        );
                      }}
                      required
                    />
                    <span className="form__label--warning">
                      {editResidualValue === ""
                        ? ""
                        : amountFormat(editResidualValue, 0)}
                    </span>
                  </>
                )}
                {editBox.type === "description" && (
                  <Input
                    className="form__input "
                    type="text"
                    autoFocus
                    maxLength={128}
                    minLength={3}
                    value={editDescription}
                    onChange={(e) => {
                      setEditDescription(e.target.value);
                    }}
                    required
                  />
                )}
                {editBox.type === "addition" && (
                  <Input
                    className="form__input"
                    type="text"
                    autoFocus
                    maxLength={128}
                    minLength={3}
                    value={editAddition.value}
                    onChange={(e) => {
                      setEditAddition({
                        id: editAddition.id,
                        value:
                          editAddition.type ===
                            envConfig.columnDataTypeValue.NUMERIC &&
                          isNaN(e.target.value)
                            ? editAddition.value
                            : e.target.value,
                      });
                    }}
                    required
                  />
                )}
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* useful life popup */}
      <Popup
        open={editBoxUesfulLife}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue">Useful life changed.</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setEditBoxUsefulLife(false);
                setEditUsefulLifeWay(0);
                setEditUsefulLife("");
                setEditDepreciation("");
              }}
            >
              <img src={cross} alt="cross" />
            </button>
            <p className="c__9b mb-md mt-s-sm">
              How should it affect depreciation?
            </p>
          </div>
          <div className="content">
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                if (editUsefulLifeWay === 0) {
                  props.setMessageUser({
                    message: `Please select one of ${envConfig.useFulLife.PROSPECTIVE.name} or ${envConfig.useFulLife.RETROSPECTIVE.name}`,
                    type: envConfig.notificationTypes.danger,
                  });
                  return;
                }
                const response = await dispatch(
                  updateAssets({
                    authKey: props.authKey,
                    classId: params.class_id,
                    assetId: params.asset_id,
                    body: JSON.stringify({
                      column_id: usefulLife.column_id,
                      value: editUsefulLife,
                      depreciation_percentage: editDepreciation,
                    }),
                    usefulLifeWay: editUsefulLifeWay,
                  })
                );
                if (response.payload.status === 200) {
                  setEditBoxUsefulLife(false);
                  setEditUsefulLifeWay(0);
                  setEditUsefulLife("");
                  setEditDepreciation("");
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.success,
                  });
                  dispatch(
                    getAssetDetails({
                      authKey: props.authKey,
                      classId: params.class_id,
                      assetId: params.asset_id,
                    })
                  );
                } else {
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.danger,
                  });
                }
              }}
            >
              <div className="editItem__cards">
                <div
                  onClick={() => {
                    setEditUsefulLifeWay(
                      envConfig.useFulLife.PROSPECTIVE.value
                    );
                  }}
                  className={`editItem__card ${
                    editUsefulLifeWay === envConfig.useFulLife.PROSPECTIVE.value
                      ? "editItem__card__selected"
                      : ""
                  }`}
                >
                  <img src={life1} alt="useful_life" />
                  <p className="mt-sm">
                    {envConfig.useFulLife.PROSPECTIVE.name}
                  </p>
                </div>
                {props.assetDetails.is_useful_life_retrospective_available && (
                  <div
                    onClick={() => {
                      setEditUsefulLifeWay(
                        envConfig.useFulLife.RETROSPECTIVE.value
                      );
                    }}
                    className={`editItem__card ${
                      editUsefulLifeWay ===
                      envConfig.useFulLife.RETROSPECTIVE.value
                        ? "editItem__card__selected"
                        : ""
                    }`}
                  >
                    <img src={life2} alt="useful_life" />
                    <p className="mt-sm">
                      {envConfig.useFulLife.RETROSPECTIVE.name}
                    </p>
                  </div>
                )}
              </div>
              <div className="mb-md mt-md editItem__cards">
                <div>
                  <label
                    htmlFor="useful_life"
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    Useful Life
                  </label>
                  <Input
                    className="form__input"
                    type="text"
                    name="useful_life"
                    autoFocus
                    minLength="1"
                    maxLength="50"
                    aria-describedby="useful_life"
                    placeholder="Input"
                    value={editUsefulLife}
                    onChange={(e) => {
                      const currenUsefulLife =
                        isNaN(e.target.value) || parseInt(e.target.value) > 200
                          ? editUsefulLife
                          : e.target.value === ""
                          ? e.target.value
                          : parseInt(e.target.value);
                      setEditUsefulLife(currenUsefulLife);
                      if (!isReducingBalance) {
                        setEditDepreciation(
                          currenUsefulLife === ""
                            ? ""
                            : Math.round((100 / currenUsefulLife) * 100) / 100
                        );
                      }
                    }}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="depreciation_percentage"
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    Depreciation Percentage
                  </label>
                  <Input
                    className="form__input"
                    type="text"
                    name="depreciation_percentage"
                    autoFocus
                    minLength="1"
                    maxLength="50"
                    aria-describedby="depreciation_percentage"
                    placeholder="Input"
                    value={editDepreciation}
                    onChange={(e) => {
                      setEditDepreciation(
                        isNaN(e.target.value) || parseInt(e.target.value) > 100
                          ? editDepreciation
                          : e.target.value
                      );
                    }}
                    required
                  />
                </div>
              </div>

              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* dispose asset */}
      <Popup
        open={disposeBox}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue">Dispose Asset</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setDisposeBox(false);
                setDisposeAmount("");
                setDisposeDate("");
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>
          <div className="content">
            <Form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault();
                const response = await dispatch(
                  disposeAssets({
                    authKey: props.authKey,
                    classId: params.class_id,
                    assetId: params.asset_id,
                    body: JSON.stringify({
                      date: moment(disposeDate).format("YYYY-MM-DD hh:mm:ss"),
                      amount: disposeAmount,
                    }),
                  })
                );
                if (response.payload.status === 200) {
                  setDisposeBox(false);
                  setDisposeAmount("");
                  setDisposeDate("");
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.success,
                  });
                  navigate(
                    "/app/assets/" + params.class_name + "/" + params.class_id
                  );
                } else {
                  props.setMessageUser({
                    message: response.payload.message,
                    type: envConfig.notificationTypes.danger,
                  });
                }
              }}
            >
              <div className="mb-md">
                <label
                  htmlFor="amount"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  Dispose Amount
                </label>
                <Input
                  className="form__input"
                  type="text"
                  name="amount"
                  autoFocus
                  minLength="1"
                  maxLength="50"
                  value={disposeAmount}
                  onChange={(e) => {
                    setDisposeAmount(
                      isNaN(e.target.value) || e.target.value > 1000000000
                        ? disposeAmount
                        : e.target.value
                    );
                  }}
                  required
                />
                <span
                  className="form__label--warning"
                  role="alert"
                  aria-live="assertive"
                >
                  {disposeAmount === "" ? "" : amountFormat(disposeAmount)}
                </span>
              </div>
              <div className="mb-md">
                <label
                  htmlFor="date_dispose"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  Date of Disposing
                </label>
                <DatePicker
                  name="date_dispose"
                  id="date_dispose"
                  className="date__input"
                  minDate={new Date(purchaseDate.originalValue)}
                  maxDate={new Date()}
                  value={disposeDate}
                  onChange={(e) => {
                    setDisposeDate(e);
                  }}
                  required
                />
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Move
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* delete asset */}
      <Popup
        open={deleteBox}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue">Delete Asset</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setDeleteBox(false);
                setDeleteWrite("");
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>
          <div className="content">
            <Form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault();
                if (assetId === deleteWrite)
                  swal({
                    title: "Are you sure?",
                    text: "You want to delete the asset. This cannot be reverted",
                    icon: "error",
                    buttons: true,
                  }).then(async (result) => {
                    if (result) {
                      const response = await dispatch(
                        deleteAssets({
                          authKey: props.authKey,
                          classId: params.class_id,
                          assetId: params.asset_id,
                          body: JSON.stringify({ write_asset_id: deleteWrite }),
                        })
                      );
                      if (response.payload.status === 200) {
                        navigate(
                          "/app/assets/" +
                            params.class_name +
                            "/" +
                            params.class_id
                        );
                      } else {
                        props.setMessageUser({
                          message: response.payload.message,
                          type: envConfig.notificationTypes.danger,
                        });
                      }
                    } else {
                      setDeleteBox(false);
                      setDeleteWrite("");
                    }
                  });
              }}
            >
              <div className="mb-md">
                <label
                  htmlFor="amount"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  Write the asset id "{assetId}" in the text box below
                </label>
                <Input
                  className="form__input"
                  type="text"
                  name="writeText"
                  autoFocus
                  minLength="1"
                  maxLength="64"
                  value={deleteWrite}
                  onChange={(e) => {
                    setDeleteWrite(e.target.value);
                  }}
                  placeholder={assetId}
                  required
                />
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Delete
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* update history */}
      {historyBox && (
        <div className="history__popup">
          <div className="history__box">
            <span
              className="close-icon"
              onClick={() => {
                setHistoryBox(false);
              }}
            ></span>
            <div className="history__content">
              <div className="assetinfo__cross">
                <h2
                  className="heading-2"
                  onDoubleClick={() => {
                    swal({
                      title: "Are you sure?",
                      text: "You want to forward time",
                      icon: "warning",
                      buttons: true,
                    }).then(async (result) => {
                      if (result) {
                        const response = await dispatch(
                          fastForwardOneYear({
                            authKey: props.authKey,
                            classId: params.class_id,
                            assetId: params.asset_id,
                          })
                        );
                        if (response.payload.status === 200) {
                          navigate(
                            "/app/assets/" +
                              params.class_name +
                              "/" +
                              params.class_id
                          );
                        } else {
                          props.setMessageUser({
                            message: response.payload.message,
                            type: envConfig.notificationTypes.danger,
                          });
                        }
                      }
                    });
                  }}
                >
                  Log
                </h2>
                <div
                  className="assetinfo__crossImg cursor"
                  onClick={() => {
                    setHistoryBox(false);
                  }}
                >
                  <img className="" src={cross} alt="cut history popup" />
                </div>
              </div>

              <div className="dashboards__card">
                {props.assetDetails.activity_log.length === 0 ? (
                  <div className="cap">
                    <div className="assetinfo__empty">
                      <img
                        className="assetinfo__EmptySvg"
                        src={emptyHistory}
                        alt="empty history"
                      />
                    </div>
                  </div>
                ) : (
                  props.assetDetails.activity_log.map((log, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="dashboards__underline"></div>
                        <div className="dashboards__dots">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className="dashboards__blueBg cap">
                            {log.created_on}
                          </p>
                        </div>

                        <div className="dashboards__dots dashboards__end">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className=" cap">{log.user_name}</p>
                        </div>
                        <p className="dashboards__subFull dashboards__gray">
                          {log.message}
                        </p>
                      </React.Fragment>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* impairment history */}
      {impairmentBox && (
        <div className="history__popup">
          <div className="history__box">
            <span
              className="close-icon"
              onClick={() => {
                setImpairmentBox(false);
              }}
            ></span>
            <div className="history__content">
              <div className="assetinfo__cross">
                <h2 className="heading-2">Impairment Log</h2>
                <div
                  className="assetinfo__crossImg cursor"
                  onClick={() => {
                    setImpairmentBox(false);
                  }}
                >
                  <img className="" src={cross} alt="cut history popup" />
                </div>
              </div>

              <div className="dashboards__card">
                {props.assetDetails.impairment_log.length === 0 ? (
                  <div className="cap">
                    <div className="assetinfo__empty">
                      <img
                        className="assetinfo__EmptySvg"
                        src={emptyImpairment}
                        alt="empty history"
                      />
                      <p className="mt-sm c__aa">No Impairement Added Yet</p>
                    </div>
                  </div>
                ) : (
                  props.assetDetails.impairment_log.map((log, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="dashboards__underline"></div>
                        <div className="dashboards__dots">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className="dashboards__blueBg cap">
                            {log.created_on}
                          </p>
                        </div>
                        <div className="dashboards__dots dashboards__end">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className=" cap">{log.amount}</p>
                        </div>
                        <p className="dashboards__subFull cap">
                          {log.nature_type}
                        </p>
                        <p className="dashboards__subFull dashboards__gray">
                          {log.sources}
                        </p>
                      </React.Fragment>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* land dispose history */}
      {landDisposeLogBox && (
        <div className="history__popup">
          <div className="history__box">
            <span
              className="close-icon"
              onClick={() => {
                setLandDisposeLogBox(false);
              }}
            ></span>
            <div className="history__content">
              <div className="assetinfo__cross">
                <h2 className="heading-2">Disposal Logs</h2>
                <div
                  className="assetinfo__crossImg cursor"
                  onClick={() => {
                    setLandDisposeLogBox(false);
                  }}
                >
                  <img className="" src={cross} alt="cut history popup" />
                </div>
              </div>

              <div className="dashboards__card">
                {props.assetDetails.land_dispose_log.length === 0 ? (
                  <div className="cap">
                    <div className="assetinfo__empty">
                      <img
                        className="assetinfo__EmptySvg"
                        src={emptyImpairment}
                        alt="empty history"
                      />
                      <p className="mt-sm c__aa">No Disposal Added Yet</p>
                    </div>
                  </div>
                ) : (
                  props.assetDetails.land_dispose_log.map((log, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="dashboards__underline"></div>
                        <div className="dashboards__dots">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className="dashboards__blueBg cap">
                            {log.created_on}
                          </p>
                        </div>
                        <div className="dashboards__dots dashboards__end">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className=" cap">{log.amount}</p>
                        </div>
                        <p className="dashboards__subFull cap">
                          Dispose Area (Sq.M.) {log.area}
                        </p>
                        <p className="dashboards__subFull dashboards__gray">
                          Dispose Price per sq.m. {log.area_price}
                        </p>
                      </React.Fragment>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslations(state),
    userData: getUserData(state),
    assetDetails: getAssetDetailData(state),
  }),
  { setMessageUser }
)(AssetDetail);
