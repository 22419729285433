import { createSlice } from "@reduxjs/toolkit";
import envConfig from "../../config/constants";
import getTranslation from "../../translations";

const languageSlice = createSlice({
  name: "language",
  initialState: {
    translations: getTranslation(envConfig.language.en),
  },
  reducers: {
    setLanguage: (state, language) => {
      state.translations = getTranslation(language);
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
