import { connect, useDispatch } from "react-redux";
import { getRegisterData, getTranslations } from "../../../redux/selector";
import { Button, Form, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { logo, arrRight, otpImage } from "../../../config/assets";
import { useEffect, useState } from "react";
import { registerUser, registerVerifyUser } from "../../../redux/api";
import validators from "../../../config/validators";

const RegisterVerify = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [otpValid, setOtpValid] = useState(false);

  useEffect(() => {
    if (
      props.userData.name === "" ||
      props.userData.phone === "" ||
      props.userData.email === "" ||
      props.userData.password === "" ||
      props.userData.reporting_framework === ""
    )
      navigate("/auth/register");
  }, [navigate, props.userData]);

  return (
    <>
      <div className="enterEmail__story">
        <img
          className="enterEmail__img"
          src={otpImage}
          alt={`${props.translations.registerOTPPage.alt.otp}`}
        />
        <div className="enterEmail__text">
          <p className="enterEmail__text--size">
            {props.translations.registerOTPPage.advertise.message}
          </p>
          <img
            className="enterEmail__img--2"
            src={logo}
            alt={`${props.translations.registerOTPPage.alt.logo}`}
          />
        </div>
      </div>
      <div className="otp__content">
        <Form
          className="otp__box"
          onSubmit={async (e) => {
            e.preventDefault();
            const response = await dispatch(
              registerVerifyUser(
                JSON.stringify({ email: props.userData.email, otp })
              )
            );
            if (response.payload.status === 200)
              navigate("/auth/info", {
                state: { message: response.payload.message },
              });
          }}
        >
          <h2 className="heading-1 mb-md">
            {props.translations.registerOTPPage.title.h2}{" "}
          </h2>
          <p className="">
            {props.translations.registerOTPPage.title.para}{" "}
            {props.userData.email}
          </p>
          <Link to={-2} className="font-12 c__white border__bottom mb-md-4">
            {props.translations.registerOTPPage.redirect.link}
          </Link>
          <div className="mb-md mt-md-4">
            <div className="otp__resends">
              <label htmlFor="otp" className="form__label" aria-hidden="true">
                {props.translations.registerOTPPage.form.otp.label}
              </label>
              <p
                className="otp__resend"
                onClick={() => {
                  dispatch(
                    registerUser(
                      JSON.stringify({
                        name: props.userData.name,
                        email: props.userData.email,
                        phone: props.userData.phone,
                        password: props.userData.password,
                        reporting_framework: props.userData.reporting_framework,
                      })
                    )
                  );
                }}
              >
                {props.translations.registerOTPPage.form.otp.resend}
              </p>
            </div>
            <div className="inputs__icons">
              <Input
                className="form__input"
                type="tel"
                name={`${props.translations.registerOTPPage.form.otp.name}`}
                autoFocus
                maxLength={`${props.translations.registerOTPPage.form.otp.minLength}`}
                minLength={`${props.translations.registerOTPPage.form.otp.maxLength}`}
                aria-describedby={`${props.translations.registerOTPPage.form.otp.placeholder}`}
                placeholder={`${props.translations.registerOTPPage.form.otp.placeholder}`}
                value={otp}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  setOtp(value);
                  setOtpValid(value.match(validators.otp));
                }}
                required
              />
            </div>
            <span
              className="form__label--error"
              role="alert"
              aria-live="assertive"
            >
              {otpValid === null
                ? props.translations.registerOTPPage.validation.otpInvalid
                : ""}
            </span>
          </div>
          <div className="depricaition__btns">
            <Button className="btn btn-svg enterEmail__btn" type="submit">
              {props.translations.registerOTPPage.button.submit}
              <img src={arrRight} alt="" />
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default connect((state) => ({
  translations: getTranslations(state),
  userData: getRegisterData(state),
}))(RegisterVerify);
