import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input } from "reactstrap";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import { getAuthKey, getTranslations, getUserData } from "../../redux/selector";
import {
  amountFormat,
  checkEditAccessRight,
  verifyClassLand,
  verifyClassNameAndId,
  verifyFairValue,
  verifyReducingBalance,
} from "../../config/utility";
import envConfig from "../../config/constants";
import { checkDuplicateAsset, createAssets } from "../../redux/api";
import moment from "moment";
import { setMessageUser } from "../../redux/features/user";
import BreadCrumbs from "../../components/breadCrumbs";
import swal from "sweetalert";

const AssetNew = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLand = verifyClassLand(props.userData.classes, params.class_id);
  const isFairValue = verifyFairValue(props.userData.classes, params.class_id);
  const isReadOnly = checkEditAccessRight(props.userData.access_right);
  const isReducingBalance = verifyReducingBalance(
    props.userData.organization.depreciation_type
  );

  const [description, setDescription] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [isDisposed, setIsDisposed] = useState(false);
  const [disposeDate, setDisposeDate] = useState("");
  const [disposeMinDate, setDisposeMinDate] = useState(new Date("1970-01-01"));
  const [disposeAmount, setDisposeAmount] = useState("");
  const [purchaseCost, setPurchaseCost] = useState("");
  const [type, setType] = useState("");
  const [usefulLife, setUsefulLife] = useState("");
  const [residualValue, setResidualValue] = useState("");
  const [depreciationPercentage, setDepreciationPercentage] = useState("");
  const [area, setArea] = useState("");
  const [areaPrice, setAreaPrice] = useState("");
  const [landType, setLandType] = useState("");

  const verifyResidualValue = (currentPurchaseCost) => {
    if (currentPurchaseCost === "") setResidualValue("");
    else if (residualValue > currentPurchaseCost / 2)
      setResidualValue(currentPurchaseCost / 2);
  };

  const checkDisposed = ({ datePurchased, assetLife }) => {
    if (datePurchased === "" || assetLife === "") {
      return;
    }
    setDisposeMinDate(datePurchased);
    datePurchased = moment.utc(datePurchased).add(assetLife, "years");
    if (datePurchased < moment.utc()) {
      setIsDisposed(true);
    } else {
      setIsDisposed(false);
    }
  };

  const verifyInput = (type, value) => {
    switch (type) {
      case "description":
        setDescription(value);
        break;
      case "purchase_date":
        setPurchaseDate(value);
        checkDisposed({ datePurchased: value, assetLife: usefulLife });
        break;
      case "purchase_cost":
        const currentPurchaseCost =
          isNaN(value) || value > 1000000000 ? purchaseCost : value;
        setPurchaseCost(currentPurchaseCost);
        verifyResidualValue(currentPurchaseCost);
        break;
      case "type":
        setType(value);
        break;
      case "useful_life":
        const currenUsefulLife =
          isNaN(value) || parseInt(value) > 200
            ? usefulLife
            : value === ""
            ? value
            : parseInt(value);
        setUsefulLife(currenUsefulLife);
        if (!isReducingBalance) {
          setDepreciationPercentage(
            currenUsefulLife === "" ? "" : Math.round((100 / value) * 100) / 100
          );
        }
        checkDisposed({
          datePurchased: purchaseDate,
          assetLife: currenUsefulLife,
        });
        break;
      case "residual_value":
        setResidualValue(
          isNaN(value) ||
            purchaseCost === "" ||
            parseInt(value) > purchaseCost / 2
            ? residualValue
            : value
        );
        break;
      case "depreciation":
        setDepreciationPercentage(
          isNaN(value) || parseInt(value) > 100 ? depreciationPercentage : value
        );
        break;
      case "area":
        const currentArea = isNaN(value) || value > 1000000000 ? area : value;
        setArea(currentArea);
        if (currentArea === "" || areaPrice === "") {
          setPurchaseCost("");
          setResidualValue("");
        } else {
          setPurchaseCost(currentArea * areaPrice);
          verifyResidualValue(currentArea * areaPrice);
        }
        break;
      case "area_price":
        const currentAreaPrice =
          isNaN(value) || value > 1000000000 ? areaPrice : value;
        setAreaPrice(currentAreaPrice);
        if (area === "" || currentAreaPrice === "") {
          setPurchaseCost("");
          setResidualValue("");
        } else {
          setPurchaseCost(area * currentAreaPrice);
          verifyResidualValue(area * currentAreaPrice);
        }
        break;
      case "dispose_amount":
        setDisposeAmount(
          isNaN(value) || value > 1000000000 ? disposeAmount : value
        );
        break;
      case "land_type":
        setLandType(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      !verifyClassNameAndId(
        props.userData.classes,
        params.class_name,
        params.class_id
      ) ||
      isReadOnly
    )
      navigate("/app/dashboard");
  }, [
    isReadOnly,
    navigate,
    params.class_id,
    params.class_name,
    props.userData.classes,
  ]);

  const createNewAsset = async () => {
    const response = await dispatch(
      createAssets({
        authKey: props.authKey,
        classId: params.class_id,
        body: JSON.stringify({
          description,
          purchase_date: moment(purchaseDate).format("YYYY-MM-DD"),
          purchase_cost: purchaseCost,
          type,
          useful_life: isLand ? envConfig.usefulLifeInfinity : usefulLife,
          residual_value: isLand || !isReducingBalance ? 0 : residualValue,
          depreciation_percentage: isLand ? 0 : depreciationPercentage,
          area: isLand ? area : 0,
          area_price: isLand ? areaPrice : 0,
          land_type: isLand ? landType : envConfig.landType[0].value,
          ...(isLand || !isDisposed
            ? {}
            : {
                dispose_date:
                  disposeDate === ""
                    ? undefined
                    : moment(disposeDate).format("YYYY-MM-DD hh:mm:ss"),
                dispose_amount:
                  disposeAmount === "" ? undefined : disposeAmount,
              }),
        }),
      })
    );
    if (response.payload.status === 200) {
      navigate("/app/assets/" + params.class_name + "/" + params.class_id);
      props.setMessageUser({
        message: response.payload.message,
        type: envConfig.notificationTypes.success,
      });
    } else {
      props.setMessageUser({
        message: response.payload.message,
        type: envConfig.notificationTypes.danger,
      });
    }
  };

  const processCreateAsset = async () => {
    const response = await dispatch(
      checkDuplicateAsset({
        authKey: props.authKey,
        classId: params.class_id,
        body: JSON.stringify({
          description,
          purchase_date: moment(purchaseDate).format("YYYY-MM-DD"),
          purchase_cost: purchaseCost,
          area: isLand ? area : 0,
          area_price: isLand ? areaPrice : 0,
          land_type: isLand ? landType : envConfig.landType[0].value,
        }),
      })
    );
    if (response.payload.status === 200) {
      if (response.payload.matched_assets.length > 0) {
        swal({
          title: "Identical asset(s) found",
          text: `Asset with asset id "${response.payload.matched_assets.join(
            ", "
          )}" have been found to be identical with this asset. Are you sure you still want to create new asset?`,
          icon: "warning",
          buttons: true,
        }).then(async (result) => {
          if (result) {
            await createNewAsset();
          }
        });
      } else {
        await createNewAsset();
      }
    } else {
      props.setMessageUser({
        message: response.payload.message,
        type: envConfig.notificationTypes.danger,
      });
    }
  };

  return (
    <>
      <TopNav />
      <SideNav classId={params.class_id} className={params.class_name} />

      <div className="listings">
        <BreadCrumbs crumbs={[params.class_name, "New Asset"]} />
        <div className="listing">
          <h3 className="heading-3 mb-md">
            {props.translations.assetNew.title}
          </h3>

          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              if (isDisposed && !isLand) {
                swal({
                  title: "Attention",
                  text: `Since asset is already depreciated, ${
                    disposeDate === "" || disposeAmount === ""
                      ? "please add dispose date and amount to archive the asset, else the asset will be treated as fully depreciated and working"
                      : "please ensure that the dispose date and amount for archiving are correct."
                  } `,
                  icon: "warning",
                  buttons: ["Review", "Add Asset"],
                }).then(async (result) => {
                  if (result) {
                    await processCreateAsset();
                  }
                });
              } else {
                await processCreateAsset();
              }
            }}
          >
            <div className="">
              <div className="mb-sm">
                <label
                  htmlFor="name"
                  className="form__label mb-s-sm c__blue"
                  aria-hidden="true"
                >
                  {props.translations.assetNew.form.description}
                </label>
                <Input
                  className="form__input "
                  type="text"
                  autoFocus
                  maxLength={128}
                  minLength={3}
                  aria-describedby={
                    props.translations.assetNew.form.descriptionPlaceholder
                  }
                  placeholder={
                    props.translations.assetNew.form.descriptionPlaceholder
                  }
                  value={description}
                  onChange={(e) => {
                    verifyInput("description", e.target.value);
                  }}
                  required
                />
              </div>

              {/* </div> */}
              <div className="addItems">
                {/* 2 Recognition Date */}
                <div className="addItem">
                  <label
                    htmlFor="name"
                    className="form__label mb-s-sm c__blue"
                    aria-hidden="true"
                  >
                    {props.translations.assetNew.form.purchaseDate}
                  </label>
                  <DatePicker
                    className="date__input"
                    placeholderText={
                      props.translations.assetNew.form.purchaseDatePlaceholder
                    }
                    value={purchaseDate}
                    onChange={(e) => {
                      verifyInput("purchase_date", e);
                    }}
                    minDate={new Date("1970-01-01")}
                    maxDate={new Date()}
                    required
                  />
                </div>

                {/* 4 Type*/}
                <div className="addItem">
                  <label
                    htmlFor="type"
                    className="form__label mb-s-sm c__blue"
                    aria-hidden="true"
                  >
                    {props.translations.assetNew.form.type}
                  </label>
                  <select
                    className="form__select form__select__area-2"
                    value={type}
                    onChange={(e) => {
                      verifyInput("type", e.target.value);
                    }}
                    required
                  >
                    <option value="">
                      {props.translations.assetNew.form.typePlaceholder}
                    </option>
                    {envConfig.assetType.map((type) => {
                      return (
                        <option key={type.id} value={type.value} required>
                          {type.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {isLand && (
                  <>
                    {/*  LAND_TYPE*/}
                    <div className="addItem">
                      <label
                        htmlFor="lan"
                        className="form__label mb-s-sm c__blue"
                        aria-hidden="true"
                      >
                        {props.translations.assetNew.form.typeLand}
                      </label>
                      <select
                        className="form__select form__select__area-2"
                        value={landType}
                        onChange={(e) => {
                          verifyInput("land_type", e.target.value);
                        }}
                        required={isLand}
                      >
                        <option value="">
                          {props.translations.assetNew.form.typeLandPlaceholder}
                        </option>
                        {envConfig.landType.map((type) => {
                          return (
                            <option key={type.id} value={type.value} required>
                              {type.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* 6 Size (in Sq. Mtr)*/}
                    <div className="addItem">
                      <label
                        htmlFor="area"
                        className="form__label mb-s-sm c__blue"
                        aria-hidden="true"
                        required={isLand}
                      >
                        {props.translations.assetNew.form.size}
                      </label>
                      <Input
                        className="form__input "
                        type="tel"
                        minLength={1}
                        maxLength={16}
                        value={area}
                        onChange={(e) => {
                          verifyInput("area", e.target.value);
                        }}
                        aria-describedby={
                          props.translations.assetNew.form.sizePlaceholder
                        }
                        placeholder={
                          props.translations.assetNew.form.sizePlaceholder
                        }
                      />
                    </div>

                    {/*  Price Per Sq. mtr*/}
                    <div className="addItem">
                      <label
                        htmlFor="area_price"
                        className="form__label mb-s-sm c__blue"
                        aria-hidden="true"
                        required={isLand}
                      >
                        {props.translations.assetNew.form.sizePrice}
                      </label>
                      <Input
                        className="form__input "
                        type="tel"
                        minLength={1}
                        maxLength={16}
                        value={areaPrice}
                        onChange={(e) => {
                          verifyInput("area_price", e.target.value);
                        }}
                        aria-placeholder={
                          props.translations.assetNew.form.sizePricePlaceholder
                        }
                        placeholder={
                          props.translations.assetNew.form.sizePricePlaceholder
                        }
                      />
                      <span className="form__label--warning">
                        {areaPrice === "" ? "" : amountFormat(areaPrice, 0)}
                      </span>
                    </div>
                  </>
                )}

                {/* 3 Cost */}
                <div className="addItem">
                  <label
                    htmlFor="purchase_cost"
                    className="form__label mb-s-sm c__blue"
                    aria-hidden="true"
                  >
                    {isFairValue
                      ? "Fair value"
                      : props.translations.assetNew.form.cost}
                  </label>
                  <Input
                    className="form__input "
                    type="tel"
                    minLength="1"
                    maxLength="50"
                    aria-describedby="Cost"
                    value={purchaseCost}
                    onChange={(e) => {
                      verifyInput("purchase_cost", e.target.value);
                    }}
                    placeholder={
                      props.translations.assetNew.form.costPlaceholder
                    }
                    readOnly={isLand}
                    required
                  />
                  <span className="form__label--warning">
                    {purchaseCost === "" ? "" : amountFormat(purchaseCost, 0)}
                  </span>
                </div>

                {!isLand && (
                  <>
                    {isReducingBalance ? (
                      <>
                        {/* Depreciation Percentage */}
                        <div className="addItem">
                          <label
                            htmlFor="depreciation_percentage"
                            className="form__label mb-s-sm c__blue"
                            aria-hidden="true"
                          >
                            {props.translations.assetNew.form.depreciation}
                          </label>
                          <Input
                            className="form__input"
                            type="tel"
                            value={depreciationPercentage}
                            onChange={(e) => {
                              verifyInput("depreciation", e.target.value);
                            }}
                            placeholder={
                              props.translations.assetNew.form
                                .depreciationPlaceholder
                            }
                            required={!isLand}
                          />
                        </div>

                        {/* Useful life */}
                        <div className="addItem">
                          <label
                            htmlFor="useful_life"
                            className="form__label mb-s-sm c__blue"
                            aria-hidden="true"
                          >
                            {props.translations.assetNew.form.usefulLife}
                          </label>
                          <Input
                            className="form__input "
                            type="tel"
                            required={!isLand}
                            value={usefulLife}
                            onChange={(e) => {
                              verifyInput("useful_life", e.target.value);
                            }}
                            placeholder={
                              props.translations.assetNew.form
                                .usefulLifePlaceholder
                            }
                          />
                          <span className="form__label--warning">
                            Value should not more than 200
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Useful life */}
                        <div className="addItem">
                          <label
                            htmlFor="useful_life"
                            className="form__label mb-s-sm c__blue"
                            aria-hidden="true"
                          >
                            {props.translations.assetNew.form.usefulLife}
                          </label>
                          <Input
                            className="form__input "
                            type="tel"
                            required={!isLand}
                            value={usefulLife}
                            onChange={(e) => {
                              verifyInput("useful_life", e.target.value);
                            }}
                            placeholder={
                              props.translations.assetNew.form
                                .usefulLifePlaceholder
                            }
                          />
                          <span className="form__label--warning">
                            Value should not more than 200
                          </span>
                        </div>

                        {/* Depreciation Percentage */}
                        <div className="addItem">
                          <label
                            htmlFor="depreciation_percentage"
                            className="form__label mb-s-sm c__blue"
                            aria-hidden="true"
                          >
                            {props.translations.assetNew.form.depreciation}
                          </label>
                          <Input
                            className="form__input"
                            type="tel"
                            value={depreciationPercentage}
                            onChange={(e) => {
                              verifyInput("depreciation", e.target.value);
                            }}
                            placeholder={
                              props.translations.assetNew.form
                                .depreciationPlaceholder
                            }
                            required={!isLand}
                          />
                        </div>
                      </>
                    )}

                    {/* Residual Value */}
                    {isReducingBalance && (
                      <div className="addItem">
                        <label
                          htmlFor="residual_value"
                          className="form__label mb-s-sm c__blue"
                          aria-hidden="true"
                        >
                          {props.translations.assetNew.form.reducingBalance}
                        </label>
                        <Input
                          className="form__input "
                          type="tel"
                          maxLength="50"
                          minLength="1"
                          aria-describedby="Amount"
                          value={residualValue}
                          onChange={(e) => {
                            verifyInput("residual_value", e.target.value);
                          }}
                          placeholder={
                            props.translations.assetNew.form
                              .reducingBalancePlaceholder
                          }
                          required={!isLand && isReducingBalance}
                        />
                        <span className="form__label--warning">
                          Residual value must be an insignificant amount of cost
                        </span>
                      </div>
                    )}
                  </>
                )}

                {isDisposed && !isLand && (
                  <>
                    {/* 2 Dispose Date */}
                    <div className="addItem">
                      <label
                        className="form__label mb-s-sm c__blue"
                        aria-hidden="true"
                      >
                        Disposal Date
                      </label>
                      <DatePicker
                        className="date__input"
                        value={disposeDate}
                        onChange={(e) => {
                          setDisposeDate(e);
                        }}
                        minDate={disposeMinDate}
                        maxDate={new Date()}
                      />
                    </div>

                    {/* 3 Dispose Amount */}
                    <div className="addItem">
                      <label
                        className="form__label mb-s-sm c__blue"
                        aria-hidden="true"
                      >
                        Disposal Amount
                      </label>
                      <Input
                        className="form__input "
                        type="tel"
                        minLength="1"
                        maxLength="50"
                        aria-describedby="Cost"
                        value={disposeAmount}
                        onChange={(e) => {
                          verifyInput("dispose_amount", e.target.value);
                        }}
                        placeholder="Dispose Amount"
                        readOnly={isLand}
                      />
                      <span className="form__label--warning">
                        {disposeAmount === ""
                          ? ""
                          : amountFormat(disposeAmount, 0)}
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div className="popup__btns mt-sm mb-sm">
                {/* <Button
                  className="btn popup__btn btn__border btn__brd"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Cancel
                </Button> */}
                <Button
                  className="btn popup__btn btn__blue btn__brd"
                  type="submit"
                >
                  Add Item
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslations(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(AssetNew);
