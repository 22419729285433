const validators = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  password:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
  name: /^[A-zÀ-ÿ][A-zÀ-ÿ' -]*$/,
  phone: /^[+]{0,1}[0-9]{9,16}$/,
  postal_code: /^[0-9]{5,7}$/,
  address: /^[A-zÀ-ÿ0-9][A-zÀ-ÿ'0-9 \-,\.\/]+$/,
  url: /((http(s)?:\/\/)?(www.)?([A-z-0-9]{2,})\.([A-z-0-9]{2,})((\.([A-z-0-9]{2,}))*)?(:[0-9]*)?((\/[A-z-0-9]+)*)?(\.[A-Za-z-]+)?((\?)([;&A-z0-9%_.~+=-]*))?)/g,
  html_tags: /(<(([^><])*)>)|(\&(.)*;)/g,
  otp: /^[0-9]{4}$/,
  page: /\/app(?:(\/assets\/[a-z%20]+\/[0-9]{1,2})|(\/asset-add\/[a-z%20]+\/[0-9]{1,2})|(\/asset-detail\/[a-z%20]+\/[0-9]{1,2})|(\/deferred-tax)|(\/investment-model)|(\/edit-columns)|(\/archive-assets\/[a-z%20]+\/[0-9]{1,2})|(\/archive-asset-detail\/[a-z%20]+\/[0-9]{1,2})|(\/accountants)|(\/accountant-add)|(\/accountant-edit)|(\/subsidiaries)|(\/subsidiary-add)|(\/subsidiary-edit)|(\/account-profile)|(\/company-profile)|(\/company-profile-edit)|(\/edit-classes))/g,
};

export default validators;
