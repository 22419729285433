import { selfUser } from "../redux/api";
import { setUser } from "../redux/features/user";
import envConfig from "./constants";

export const verifyClassNameAndId = (classes, class_name, class_id) => {
  return (
    classes.filter(({ name, id }) => {
      return name === class_name && id === parseInt(class_id);
    }).length === 1
  );
};

export const verifyClassLand = (classes, class_id) => {
  return (
    classes.filter(({ default_class_id, id }) => {
      return (
        id === parseInt(class_id) &&
        default_class_id === envConfig.defaultClass.land
      );
    }).length === 1
  );
};

export const verifyFairValue = (classes, class_id) => {
  return (
    classes.filter(({ fair_value, id }) => {
      return id === parseInt(class_id) && fair_value === 1;
    }).length === 1
  );
};

export const verifyReducingBalance = (depreciation_type) => {
  return depreciation_type === envConfig.depreciationType.REDUCING_BALANCE;
};

export const amountFormat = (amount, minimumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits,
  });
  return formatter.format(amount);
};

export const textAmountFormat = (amount) => {
  let checkNegative = amount < 0,
    amountValue = amount * Math.sign(amount);
  const formattedAmount = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  }).format(amountValue);
  return checkNegative ? "-" + formattedAmount : formattedAmount;
};

export const checkEditAccessRight = (access_right) => {
  return Number(access_right) === envConfig.accessRights.accountant_2;
};

export const checkAdmin = (access_right) => {
  return Number(access_right) === envConfig.accessRights.admin;
};

export const backToAdmin = async (resetUser, dispatch, navigate) => {
  if (localStorage.getItem(envConfig.storageKeys.subsidiaryAuthKey)) {
    let authKey;
    try {
      authKey = JSON.parse(
        atob(localStorage.getItem(envConfig.storageKeys.subsidiaryAuthKey))
      ).auth_key;
    } catch (e) {}
    if (authKey) {
      localStorage.setItem(
        envConfig.storageKeys.authKey,
        localStorage.getItem(envConfig.storageKeys.subsidiaryAuthKey)
      );
      localStorage.removeItem(envConfig.storageKeys.subsidiaryAuthKey);
      await dispatch(setUser({ auth_key: authKey }));
      await dispatch(selfUser({ authKey }));
      navigate("/app/subsidiaries");
    } else {
      resetUser();
    }
  } else {
    resetUser();
    window.location = envConfig.adminURL;
  }
};
