import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Button, Form, Input } from "reactstrap";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import envConfig from "../../config/constants";
import { getAssetList } from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import { getAuthKey, getTranslations, getUserData } from "../../redux/selector";

const DeferredTax = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [classList, setClassList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [corporationTax, setCorporationTax] = useState(
    state?.corporationTax ? state.corporationTax : ""
  );
  const [yearlyTax, setYearlyTax] = useState([
    {
      name: "First Year",
      value: state?.yearlyTaxList?.length > 0 ? state.yearlyTaxList[0] : "",
    },
    {
      name: "Second Year",
      value: state?.yearlyTaxList?.length > 1 ? state.yearlyTaxList[1] : "",
    },
    {
      name: "Third Year",
      value: state?.yearlyTaxList?.length > 2 ? state.yearlyTaxList[2] : "",
    },
    {
      name: "Fourth Year",
      value: state?.yearlyTaxList?.length > 3 ? state.yearlyTaxList[3] : "",
    },
    {
      name: "Fifth Year",
      value: state?.yearlyTaxList?.length > 4 ? state.yearlyTaxList[4] : "",
    },
    {
      name: "Sixth Year",
      value: state?.yearlyTaxList?.length > 5 ? state.yearlyTaxList[5] : "",
    },
  ]);

  const populateAssets = async (e) => {
    setSelectedClass(e);
    const { payload } = await dispatch(
      getAssetList({ authKey: props.authKey, classId: e.id })
    );
    if (payload.status === 200) {
      let paramAsset = null;
      setAssetList(
        payload.data.map(({ id, asset_id, description }) => {
          if (
            parseInt(params.asset_id) === id ||
            state?.selectedAsset.id === id
          ) {
            paramAsset = {
              id,
              value: id,
              label: `${asset_id}  |   ${description}`,
            };
          }
          return {
            id,
            value: id,
            label: `${asset_id}  |   ${description}`,
          };
        })
      );
      if (paramAsset) {
        setSelectedAsset(paramAsset);
      }
    } else {
      setAssetList([]);
      setSelectedAsset(null);
    }
  };

  useEffect(() => {
    let paramClass = null;
    setClassList(
      props.userData.classes.map(({ id, name }) => {
        if (
          parseInt(params.class_id) === id ||
          state?.selectedClass.id === id
        ) {
          paramClass = {
            id,
            value: id,
            label: name,
          };
        }
        return {
          id,
          value: id,
          label: name,
        };
      })
    );
    if (paramClass) {
      setSelectedClass(paramClass);
      populateAssets(paramClass);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.class_id, props.userData.classes]);
  return (
    <>
      <TopNav />
      <SideNav />
      <div className="listings">
        <BreadCrumbs crumbs={["Deferred Tax"]} />
        <div className="listing">
          <h3 className="heading-3">
            {props.translations.deferredTax.title.deferredTax}
          </h3>
          <div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                const yearlyTaxList = yearlyTax.reduce((array, rest) => {
                  if (rest.value !== "") array.push(rest.value);
                  return array;
                }, []);
                if (!selectedClass || !selectedAsset) {
                  props.setMessageUser({
                    type: envConfig.notificationTypes.warning,
                    message: "Please select class and asset",
                  });
                  return;
                }
                if (yearlyTaxList.length === 0) {
                  props.setMessageUser({
                    type: envConfig.notificationTypes.warning,
                    message: "Please enter atleast one year tax",
                  });
                  return;
                }
                navigate("/app/deferred-tax-report", {
                  state: {
                    selectedClass,
                    selectedAsset,
                    corporationTax,
                    yearlyTaxList,
                  },
                });
              }}
            >
              <div className="defer__grid2">
                <div className="mt-sm">
                  <label
                    htmlFor="testing"
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    {props.translations.deferredTax.form.class.label}
                  </label>
                  <Select
                    className="cap"
                    placeholder={"Search and Select"}
                    value={selectedClass}
                    options={classList}
                    onChange={populateAssets}
                  />
                </div>

                <div className="mt-sm">
                  <label
                    htmlFor="testing"
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    {props.translations.deferredTax.form.asset.label}
                  </label>
                  <Select
                    placeholder={"Search and Select"}
                    value={selectedAsset}
                    options={assetList}
                    onChange={(e) => {
                      setSelectedAsset(e);
                    }}
                  />
                </div>
                <div className="defer__inputYear">
                  <label
                    htmlFor={`${props.translations.deferredTax.form.corporation_tax.name}`}
                    className="form__label cap mb-s-sm"
                    aria-hidden="true"
                  >
                    {props.translations.deferredTax.form.corporation_tax.label}
                  </label>
                  <div className="defer__years">
                    <div className="defer__year">
                      <div className="defer__box">
                        <Input
                          className="form__input defer__input"
                          type="tel"
                          placeholder={`${props.translations.deferredTax.form.corporation_tax.placeholder} %`}
                          maxLength="5"
                          onChange={(e) => {
                            setCorporationTax(
                              isNaN(e.target.value.trim()) ||
                                e.target.value.trim() >= 100
                                ? corporationTax
                                : e.target.value.trim()
                            );
                          }}
                          value={corporationTax}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="defer__inputYear">
                  <label
                    htmlFor="testing"
                    className="form__label  cap mb-s-sm"
                    aria-hidden="true"
                  >
                    <b>Input Yearly tax rate</b>
                  </label>
                  <div className="defer__years">
                    {yearlyTax.map((taxRate, index) => {
                      return (
                        <div className="defer__year" key={index}>
                          <label
                            htmlFor={"year_" + index}
                            className="form__label cap mb-s-sm"
                            aria-hidden="true"
                          >
                            {taxRate.name}
                          </label>
                          <div className="defer__box">
                            <Input
                              className="form__input defer__input"
                              type="tel"
                              placeholder={taxRate.name + " %"}
                              maxLength="5"
                              value={taxRate.value}
                              onChange={(e) => {
                                const tempList = Object.assign([], yearlyTax);
                                tempList[index].value =
                                  isNaN(e.target.value.trim()) ||
                                  e.target.value.trim() >= 100
                                    ? tempList[index].value
                                    : e.target.value.trim();
                                setYearlyTax(tempList);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="popup__btns mt-sm">
                <Button type="submit" className="btn btn__blue cap">
                  {props.translations.deferredTax.button.submit}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    authKey: getAuthKey(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(DeferredTax);
