import { connect, useDispatch } from "react-redux";
import { getForgotData, getTranslations } from "../../../redux/selector";
import { Button, Form, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  logo,
  arrRight,
  loginMain,
  viewIcon,
  hideIcon,
} from "../../../config/assets";
import { useEffect, useState } from "react";
import { forgotUser, forgotVerifyUser } from "../../../redux/api";
import validators from "../../../config/validators";
import { setForgotUser } from "../../../redux/features/user";

const ForgotVerify = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [otpValid, setOtpValid] = useState(false);
  const [password, setPassword] = useState("");
  const [isText, setIsText] = useState(false);
  const [rPassword, setRPassword] = useState("");
  const [isRText, setIsRText] = useState(false);

  useEffect(() => {
    if (props.userData.email === "") navigate("/auth/forgot");
  }, [navigate, props.userData]);

  const verifyInput = (type, value) => {
    value = value.trim();
    switch (type) {
      case "otp":
        setOtp(value);
        setOtpValid(value.match(validators.otp));
        break;
      case "password":
        setPassword(value);
        break;
      case "rpassword":
        setRPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="login__story">
        <img className="enterEmail__img" src={loginMain} alt="reset" />
        <div className="enterEmail__text">
          <p className="enterEmail__text--size">
            {props.translations.forgotVerifyPage.advertise.message}
          </p>
          <img
            className="enterEmail__img--2"
            src={logo}
            alt="enterEmail page logo with name"
          />
        </div>
      </div>
      <div className="login__content">
        <Form
          className="login__box"
          onSubmit={async (e) => {
            e.preventDefault();
            const response = await dispatch(
              forgotVerifyUser(
                JSON.stringify({ email: props.userData.email, otp, password })
              )
            );
            if (response.payload.status === 200)
              navigate("/auth/login", {
                state: { message: response.payload.message },
              });
          }}
        >
          <h2 className="heading-1 mb-sm">
            {props.translations.forgotVerifyPage.title.h2}
          </h2>
          <p className="">{props.translations.forgotVerifyPage.title.para}</p>
          <Link
            to="/auth/forgot"
            className="font-12 c__white border__bottom mb-md mt-s-sm"
          >
            {props.translations.forgotVerifyPage.title.subParaLink}
          </Link>

          <div className="mb-md mt-md-4">
            <div className="otp__resends">
              <label htmlFor="otp" className="form__label" aria-hidden="true">
                {props.translations.forgotVerifyPage.form.enterOTP.label}
              </label>
              <p
                className="otp__resend"
                onClick={() => {
                  dispatch(
                    forgotUser(JSON.stringify({ email: props.userData.email }))
                  );
                }}
              >
                {props.translations.forgotVerifyPage.form.enterOTP.resend}
              </p>
            </div>

            <div className="inputs__icons">
              <Input
                className="form__input"
                type="tel"
                name={`${props.translations.forgotVerifyPage.form.enterOTP.name}`}
                autoFocus
                maxLength={`${props.translations.forgotVerifyPage.form.enterOTP.maxLength}`}
                minLength={`${props.translations.forgotVerifyPage.form.enterOTP.minLength}`}
                aria-describedby={`${props.translations.forgotVerifyPage.form.enterOTP.placeholder}`}
                placeholder={`${props.translations.forgotVerifyPage.form.enterOTP.placeholder}`}
                value={otp}
                onChange={(e) => verifyInput("otp", e.target.value.trim())}
                required
              />
            </div>

            <span
              className="form__label--error"
              role="alert"
              aria-live="assertive"
            >
              {otpValid == null
                ? props.translations.forgotVerifyPage.validation.otpInvalid
                : ""}
            </span>

            <div className="mb-sm mt-sm">
              <label
                htmlFor="password"
                className="form__label "
                aria-hidden="true"
              >
                {props.translations.forgotVerifyPage.form.newPassword.label}
              </label>

              <div className="inputs__icons">
                <Input
                  className="form__input register__w32"
                  type={isText ? "text" : "password"}
                  name={`${props.translations.forgotVerifyPage.form.newPassword.name}`}
                  minLength={`${props.translations.forgotVerifyPage.form.newPassword.minLength}`}
                  maxLength={`${props.translations.forgotVerifyPage.form.newPassword.maxLength}`}
                  aria-describedby={`${props.translations.forgotVerifyPage.form.newPassword.placeholder}`}
                  placeholder={`${props.translations.forgotVerifyPage.form.newPassword.placeholder}`}
                  value={password}
                  onChange={(e) =>
                    verifyInput("password", e.target.value.trim())
                  }
                  required
                />
                <span
                  className="inputs__svg cursor"
                  onClick={() => {
                    setIsText(!isText);
                  }}
                >
                  {isText ? (
                    <img
                      src={viewIcon}
                      alt={`${props.translations.forgotVerifyPage.form.newPassword.alt.view}`}
                    />
                  ) : (
                    <img
                      src={hideIcon}
                      alt={`${props.translations.forgotVerifyPage.form.newPassword.alt.hide}`}
                    />
                  )}
                </span>
              </div>
            </div>

            <div className="mb-md">
              <label
                htmlFor="resPassword"
                className="form__label "
                aria-hidden="true"
              >
                {props.translations.forgotVerifyPage.form.reEnterPassword.label}
              </label>

              <div className="inputs__icons">
                <Input
                  className="form__input register__w32"
                  type={isRText ? "text" : "password"}
                  name={`${props.translations.forgotVerifyPage.form.reEnterPassword.name}`}
                  minLength={`${props.translations.forgotVerifyPage.form.reEnterPassword.minLength}`}
                  maxLength={`${props.translations.forgotVerifyPage.form.reEnterPassword.maxLength}`}
                  aria-describedby={`${props.translations.forgotVerifyPage.form.reEnterPassword.placeholder}`}
                  placeholder={`${props.translations.forgotVerifyPage.form.reEnterPassword.placeholder}`}
                  value={rPassword}
                  onChange={(e) =>
                    verifyInput("rpassword", e.target.value.trim())
                  }
                  required
                />

                <span
                  className="inputs__svg cursor"
                  onClick={() => {
                    setIsRText(!isRText);
                  }}
                >
                  {isRText ? (
                    <img
                      src={viewIcon}
                      alt={`${props.translations.forgotVerifyPage.form.reEnterPassword.alt.view}`}
                    />
                  ) : (
                    <img
                      src={hideIcon}
                      alt={`${props.translations.forgotVerifyPage.form.reEnterPassword.alt.hide}`}
                    />
                  )}
                </span>
              </div>
              <span
                className="form__label--error"
                role="alert"
                aria-live="assertive"
              >
                {rPassword === password
                  ? ""
                  : props.translations.forgotVerifyPage.validation
                      .matchBothPasswordInvalid}
              </span>
            </div>
          </div>
          <Button className="btn btn-svg login__btn" type="submit">
            {props.translations.forgotVerifyPage.button.submit}
            <img src={arrRight} alt="" />
          </Button>
        </Form>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    userData: getForgotData(state),
  }),
  { setForgotUser }
)(ForgotVerify);
