import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { Button, Form, Input } from "reactstrap";
import swal from "sweetalert";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import { arrRight, cross, Down } from "../../config/assets";
import envConfig from "../../config/constants";
import {
  checkEditAccessRight,
  verifyClassNameAndId,
} from "../../config/utility";
import { createColumns, getColumns, getDefaultColumn } from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import {
  getAuthKey,
  getClassColumnData,
  getDefaultColumnData,
  getTranslations,
  getUserData,
} from "../../redux/selector";

const EditColumns = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isReadOnly = checkEditAccessRight(props.userData.access_right);

  const [addColumn, setAddColumn] = useState(false);
  const [hasClassColumn, setHasClassColumn] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [customColumn, setCustomColumn] = useState([]);
  const [columnName, setColumnName] = useState("");
  const [columnType, setColumnType] = useState("");

  useEffect(() => {
    dispatch(getColumns({ authKey: props.authKey, classId: params.class_id }));
  }, [dispatch, params.class_id, props.authKey]);

  useEffect(() => {
    dispatch(getDefaultColumn({ authKey: props.authKey }));
  }, [dispatch, props.authKey]);

  if (
    props.columns.length > 0 &&
    selectedColumn.length === 0 &&
    props.classColumns.length >= 0 &&
    !hasClassColumn
  ) {
    setHasClassColumn(true);
    setSelectedColumn(
      props.columns
        .map((column, index) => ({
          ...column,
          position: props.classColumns.reduce((position, data) => {
            return data.default_column_id === column.id
              ? data.position
              : position;
          }, index),
        }))
        .concat(
          props.classColumns.reduce((array, data) => {
            if (data.column_type === envConfig.columnType.ADDITIONAL) {
              array.push({
                name: data.name,
                type: null,
                custom: true,
                position: data.position,
              });
            }
            return array;
          }, [])
        )
        .sort((a, b) => {
          return a.position - b.position;
        })
    );
  }

  const movePosition = (index, direction) => {
    if (
      (index === selectedColumn.length - 1 && direction === 1) ||
      (index === 0 && direction === -1)
    )
      return;
    let temp = Object.assign([], selectedColumn);
    const element = temp.splice(index, 1)[0];
    temp.splice(index + direction, 0, element);
    setSelectedColumn(temp);
  };

  const addCustomColumn = (index) => {
    let temp = Object.assign([], selectedColumn);
    let tempCustom = Object.assign([], customColumn);
    const element = tempCustom.splice(index, 1)[0];
    temp.push(element);
    setCustomColumn(tempCustom);
    setSelectedColumn(temp);
  };
  const removeCustomColumn = (index) => {
    let temp = Object.assign([], selectedColumn);
    let tempCustom = Object.assign([], customColumn);
    const element = temp.splice(index, 1)[0];
    tempCustom.push(element);
    setCustomColumn(tempCustom);
    setSelectedColumn(temp);
  };

  useEffect(() => {
    if (
      !verifyClassNameAndId(
        props.userData.classes,
        params.class_name,
        params.class_id
      ) ||
      isReadOnly
    )
      navigate("/app/dashboard");
  }, [
    isReadOnly,
    navigate,
    params.class_id,
    params.class_name,
    props.userData.classes,
  ]);

  return (
    <>
      <TopNav />
      <SideNav classId={params.class_id} className={params.class_name} />
      <div className="listings">
        <BreadCrumbs crumbs={[params.class_name, "Edit Columns"]} />
        <div className="record__container pt0">
          <h2 className="heading-1 record__blue">
            Select-Rearrange Records/Columns for{" "}
            <span className="cap">{params.class_name}</span>
          </h2>
          <div className="record__records">
            <div className="record__addRecord">
              <div className="record__grid-2">
                <p>{props.translations.setupColumn.title.leftRecord}</p>
                <div
                  className="record__boxBtn"
                  onClick={() => {
                    setAddColumn(true);
                  }}
                >
                  <Button className=" record__plusBtn">+</Button>
                </div>
              </div>
              <div className="record__leftnav">
                {customColumn.map((column, index) => {
                  return (
                    <div className="record__tab" key={index}>
                      <p className="cap">{column.name}</p>
                      <div className="record__boxBtn">
                        <Button
                          type="text"
                          className="record__tabBtn"
                          onClick={() => {
                            addCustomColumn(index);
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="">
              <p className="record__rearrangeName">
                {props.translations.setupColumn.title.rightRecord}
              </p>
              <div className="record__contents">
                {selectedColumn.map((column, index) => {
                  return (
                    <div className="record__rearranges" key={index}>
                      <p className="record__listname cap">
                        {("0" + (index + 1)).slice(-2)} =&gt; {column.name}
                      </p>
                      <div className="record__updowns">
                        <div
                          className="record__boxBtn"
                          onClick={() => {
                            movePosition(index, -1);
                          }}
                        >
                          <span className="record__plusBtn record__plusBtn-blue">
                            <Down
                              className={`record__arr-angle-180 record__arr-${
                                index === 0 ? "grey" : "blue"
                              }`}
                            />
                          </span>
                        </div>
                        <div
                          className="record__boxBtn"
                          onClick={() => {
                            movePosition(index, 1);
                          }}
                        >
                          <span className="record__plusBtn record__plusBtn-blue">
                            <Down
                              className={`record__arr-angle record__arr-${
                                index === selectedColumn.length - 1
                                  ? "grey"
                                  : "blue"
                              }`}
                            />
                          </span>
                        </div>
                        <div className="record__boxBtn">
                          <Button
                            className={`record__plusBtn record__plusBtn-${
                              column.custom && column.type ? "red" : "grey"
                            }`}
                            onClick={
                              column.custom && column.type
                                ? () => {
                                    removeCustomColumn(index);
                                  }
                                : null
                            }
                          >
                            -
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <Button
            className="btn btn-svgs record__btn"
            onClick={() => {
              swal({
                title: "Are you sure?",
                text: "You have added and selected all the required columns",
                icon: "warning",
                buttons: true,
              }).then(async (result) => {
                if (result) {
                  const body = JSON.stringify({
                    columns: selectedColumn.reduce(
                      (array, { custom, name, type, id }) => {
                        if (custom) {
                          array.push({
                            default_column_id: null,
                            name,
                            type,
                            position: array.length + 1,
                            status: 1,
                          });
                        } else {
                          array.push({
                            default_column_id: id,
                            name: null,
                            type: null,
                            position: array.length + 1,
                          });
                        }
                        return array;
                      },
                      []
                    ),
                  });
                  const response = await dispatch(
                    createColumns({
                      authKey: props.authKey,
                      classId: params.class_id,
                      body,
                    })
                  );
                  if (response.payload.status === 200) {
                    navigate(-1);
                  }
                  props.setMessageUser({
                    message: response.payload.message,
                    type:
                      response.payload.status === 200
                        ? envConfig.notificationTypes.success
                        : envConfig.notificationTypes.danger,
                  });
                }
              });
            }}
          >
            {props.translations.setupColumn.button.apply}
            <img src={arrRight} alt="" />
          </Button>
        </div>
      </div>
      {/* Add Custom Column */}
      <Popup
        open={addColumn}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header">
            <h2 className="headin-2 popup__blue">
              {props.translations.setupColumn.popupAddRecord.heading}
            </h2>
            <button
              className="popup__close close"
              onClick={() => {
                setAddColumn(false);
              }}
            >
              <img
                src={cross}
                alt={props.translations.setupColumn.alt.removePopup}
              />
            </button>
          </div>
          <div className="content">
            <Form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                let temp = Object.assign([], customColumn);
                temp.push({
                  name: columnName,
                  type: columnType,
                  custom: true,
                });
                setCustomColumn(temp);
                setAddColumn(false);
                setColumnName("");
                setColumnType("");
              }}
            >
              <div className="mb-md">
                <label
                  htmlFor="name"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  {
                    props.translations.setupColumn.popupAddRecord.form
                      .columnName.label
                  }
                </label>
                <Input
                  className="form__input"
                  type={`${props.translations.setupColumn.popupAddRecord.form.columnName.type}`}
                  id={`${props.translations.setupColumn.popupAddRecord.form.columnName.id}`}
                  name={`${props.translations.setupColumn.popupAddRecord.form.columnName.name}`}
                  autoFocus
                  minLength={`${props.translations.setupColumn.popupAddRecord.form.columnName.minLength}`}
                  maxLength={`${props.translations.setupColumn.popupAddRecord.form.columnName.maxLength}`}
                  aria-describedby={`${props.translations.setupColumn.popupAddRecord.form.columnName.placeholder}`}
                  placeholder={`${props.translations.setupColumn.popupAddRecord.form.columnName.placeholder}`}
                  value={columnName}
                  onChange={(e) => {
                    setColumnName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="mb-md">
                <label
                  htmlFor="name"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  {
                    props.translations.setupColumn.popupAddRecord.form
                      .recordType.label
                  }
                </label>
                <select
                  className="form__select form__select__size-2"
                  name={`${props.translations.setupColumn.popupAddRecord.form.recordType.name}`}
                  id={`${props.translations.setupColumn.popupAddRecord.form.recordType.id}`}
                  value={columnType}
                  onChange={(e) => {
                    setColumnType(e.target.value);
                  }}
                  required
                >
                  <option value="">
                    {
                      props.translations.setupColumn.popupAddRecord.form
                        .recordType.option
                    }
                  </option>
                  {envConfig.columnDataType.map((dataType, index) => {
                    return (
                      <option value={dataType.id} key={index}>
                        {dataType.value}
                      </option>
                    );
                  })}
                </select>
                <span
                  className="form__label--error"
                  role="alert"
                  aria-live="assertive"
                ></span>
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  {props.translations.setupColumn.button.add}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslations(state),
    columns: getDefaultColumnData(state),
    classColumns: getClassColumnData(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(EditColumns);
