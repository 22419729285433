import { connect } from "react-redux";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import { getAuthKey, getUserData } from "../../redux/selector";
import { setMessageUser } from "../../redux/features/user";
import BreadCrumbs from "../../components/breadCrumbs";
import envConfig from "../../config/constants";
import { edit, upload } from "../../config/assets";
import getTranslation from "../../translations";
import { Link } from "react-router-dom";
import { checkAdmin } from "../../config/utility";

const CompanyProfile = (props) => {
  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs crumbs={["Company Profile"]} />
        <div className="listing companyProfile">
          <div className="companyProfile__header">
            <h3 className="heading-3">Company Profile</h3>
            <p className="companyProfile__header-end">
              <span className="c__9b">Last Updated on : </span>{" "}
              {props.userData.updated_on}
            </p>
          </div>
          <div className="companyProfile__labels">
            <div className="sSetup__grid--logo">
              <div className="sSetup__upload">
                <div className=" sSetup__input-financialYear">
                  {props.userData.organization.logo === "" ? (
                    <img className="sSetup__previewImg" src={upload} alt="" />
                  ) : (
                    <img
                      className="sSetup__previewImg"
                      src={props.userData.organization.logo}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div className="companyProfile__label">
                <div className="companyProfile__label-name c__9b weight-700">
                  Name of organisation/entity
                </div>
                <div className="companyProfile__label-data c__black cap">
                  {props.userData.organization.name}
                </div>
              </div>
            </div>
            <div className="companyProfile__labels">
              <div className="companyProfile__label">
                <div className="companyProfile__label-name c__9b weight-700">
                  Financial year
                </div>
                <div className="companyProfile__label-data c__black">
                  <p>
                    {props.userData.organization.fy_start_format} -{" "}
                    {props.userData.organization.fy_end_format}
                  </p>
                </div>
              </div>
              <div className="companyProfile__label">
                <div className="companyProfile__label-name c__9b weight-700 cap">
                  Depreciation type
                </div>
                <div className="companyProfile__label-data c__black">
                  {
                    envConfig.depreciationTypeName[
                      props.userData.organization.depreciation_type
                    ]
                  }
                </div>
              </div>
            </div>

            <div className="physical__box">
              <div className="companyProfile__label ">
                <div className="companyProfile__label-name c__9b weight-700">
                  Physical address of organisation/entity
                </div>
                <div className="companyProfile__label-data c__black companyProfile__mtb">
                  <p>
                    {props.userData.organization.physical_area},{" "}
                    {props.userData.organization.physical_locality},{" "}
                    {props.userData.organization.physical_state},{" "}
                    {props.userData.organization.physical_country},{" "}
                    {props.userData.organization.physical_zip}
                  </p>
                </div>
              </div>
            </div>

            <div className="postal__box">
              <div className="companyProfile__label ">
                <div className="companyProfile__label-name c__9b weight-700">
                  Postal address of organisation/entity
                </div>
                <div className="companyProfile__label-data c__black companyProfile__mtb">
                  <p>
                    {props.userData.organization.postal_area},{" "}
                    {props.userData.organization.postal_locality},{" "}
                    {props.userData.organization.postal_state},{" "}
                    {props.userData.organization.postal_country},{" "}
                    {props.userData.organization.postal_zip}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="companyProfile__links">
            {checkAdmin(props.userData.access_right) && (
              <Link
                to="/app/company-profile-edit"
                className="btn btn__blue companyProfile__btn ml-sm"
              >
                Edit
              </Link>
            )}
          </div>
        </div>

        <div className="listing">
          <h3 className="heading-3">
            Classes
            {checkAdmin(props.userData.access_right) && (
              <Link to="/app/edit-classes">
                <img className="ml-s-sm" src={edit} alt="edit description" />
              </Link>
            )}
          </h3>
          <div className="pills">
            {props.userData.classes.map((classData, index) => {
              return (
                <span key={index} className="pill">
                  {classData.name}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslation(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(CompanyProfile);
