import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  archive,
  Biological,
  Buildings,
  Computers,
  DashboardReact,
  Heritage,
  Infrastructure,
  Land,
  Motor,
  Office,
  Other,
  Plant,
} from "../config/assets";
import { getTranslations, getUserData } from "../redux/selector";

const SideNav = ({ userData, translations, classId, className }) => {
  const params = useParams();
  const [navClass, setNavClass] = useState([]);
  useEffect(() => {
    setNavClass(
      userData.classes.map((classData) => ({
        ...classData,
        selected:
          parseInt(classId) === classData.id && className === classData.name,
      }))
    );
  }, [setNavClass, classId, userData, className]);
  return (
    <div className="sidenavs">
      <div className="sidenav">
        {window.location.pathname.indexOf("archive-") > -1 && (
          <Link
            className="sidenav__archives"
            to={`/app/assets/${params?.class_name}/${params.class_id}`}
          >
            <div className="sidenav__archive">
              <img src={archive} alt="" />
            </div>
            <span className={`sidenav__content `}>Archived</span>
          </Link>
        )}
        <Link
          to={{
            pathname: "/app/dashboard",
          }}
          className={`sidenav__li ${
            `/app/dashboard` === window.location.pathname
              ? "sidenav__active"
              : ""
          }`}
        >
          <DashboardReact className="sidenav__svg" />
          <p className="sidenav__content">
            {translations.sideNav.heading.dashboard}
          </p>
        </Link>
        {navClass.map((classData, index) => {
          let classTag;
          switch (classData.default_class_id) {
            case 1:
              classTag = <Land className={`sidenav__svg`} />;
              break;
            case 2:
              classTag = <Buildings className={`sidenav__svg`} />;
              break;
            case 3:
              classTag = <Computers className={`sidenav__svg`} />;
              break;
            case 4:
              classTag = <Office className={`sidenav__svg`} />;
              break;
            case 5:
              classTag = <Infrastructure className={`sidenav__svg`} />;
              break;
            case 6:
              classTag = <Motor className={`sidenav__svg`} />;
              break;
            case 7:
              classTag = <Plant className={`sidenav__svg`} />;
              break;
            case 8:
              classTag = <Heritage className={`sidenav__svg`} />;
              break;
            case 9:
              classTag = <Biological className={`sidenav__svg`} />;
              break;
            case 10:
              classTag = <Other className={`sidenav__svg`} />;
              break;
            default:
              classTag = <></>;
              break;
          }
          return (
            <Link
              key={index}
              to={`/app/${
                window.location.pathname.indexOf("archive-") > -1
                  ? "archive-"
                  : ""
              }assets/${classData.name}/${classData.id}`}
              className={`sidenav__li ${
                classData.selected ? "sidenav__active" : ""
              }`}
            >
              {classTag}
              <p className="sidenav__content">{classData.name}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default connect((state) => ({
  translations: getTranslations(state),
  userData: getUserData(state),
}))(SideNav);
