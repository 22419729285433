import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Form } from "reactstrap";
import swal from "sweetalert";
import DesktopSetupTopBar from "../../components/desktopSetupTopBar";
import MobileSetupTopBar from "../../components/mobileSetupTopBar";
import { arrRight, tick } from "../../config/assets";
import envConfig from "../../config/constants";
import { selfUser, updateSelfUser } from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import { getAuthKey, getTranslations } from "../../redux/selector";

const SetupClass = (props) => {
  const dispatch = useDispatch();
  const [depreciationType, setDepreciationType] = useState(null);

  return (
    <div className="Depreciation">
      {/* Mobile Navbar */}
      <MobileSetupTopBar />
      {/* Desktop Navbar */}
      <DesktopSetupTopBar />
      <div className="Depreciation__container">
        <h2 className="heading-1 Depreciation__blue">
          {props.translations.setupDepreciationPage.title.h2}
        </h2>
        <p className="mt-sm Depreciation__blue mb-sm">
          {props.translations.setupDepreciationPage.title.para}
        </p>
        <Form
          className="Depreciation__form"
          onSubmit={(e) => {
            e.preventDefault();
            if (depreciationType === null)
              props.setMessageUser({
                message:
                  props.translations.setupDepreciationPage.validation
                    .depreciation,
              });
            else {
              swal({
                title: "Are you sure?",
                text: "Once selected you will not be able to change it again",
                icon: "warning",
                buttons: true,
              }).then(async (result) => {
                if (result) {
                  const response = await dispatch(
                    updateSelfUser({
                      authKey: props.authKey,
                      body: JSON.stringify({
                        depreciation_type: depreciationType,
                      }),
                    })
                  );
                  if (response.payload.status === 200)
                    dispatch(selfUser({ authKey: props.authKey }));
                }
              });
            }
          }}
        >
          <div className="Depreciation__cards">
            {/* card 1*/}
            <label
              onClick={() => {
                setDepreciationType(envConfig.depreciationType.STRAIGHT_LINE);
              }}
              className="checked__label"
              htmlFor="STRAIGHT_LINE"
            >
              <div
                className={` Depreciation__card jobcreate__links--${
                  depreciationType === envConfig.depreciationType.STRAIGHT_LINE
                    ? "selected"
                    : ""
                } mt-sm`}
              >
                <svg
                  className={`Depreciation__svg ${
                    depreciationType ===
                    envConfig.depreciationType.STRAIGHT_LINE
                      ? "Depreciation__selected--svg"
                      : ""
                  }`}
                  viewBox="0 0 50 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0.706871 28.6998L18.7502 10.6323L28.3964 20.2786L28.7713 20.6534L29.1236 20.2573L46.4963 0.72837L49.313 3.54514L28.7286 26.6965L19.1036 17.0714L18.7497 16.7176L18.3962 17.0717L3.74971 31.7426L0.706871 28.6998Z" />
                </svg>
                <img
                  className={`jobcreate__tic jobcreate__tic${
                    depreciationType ===
                    envConfig.depreciationType.STRAIGHT_LINE
                      ? "--display"
                      : ""
                  }`}
                  src={tick}
                  alt={`${props.translations.setupDepreciationPage.alt.selectedCard}`}
                />
                <p
                  className={`Depreciation__para ${
                    depreciationType ===
                    envConfig.depreciationType.STRAIGHT_LINE
                      ? "Depreciation__blue"
                      : ""
                  }`}
                >
                  {
                    props.translations.setupDepreciationPage.form.straightLine
                      .label
                  }
                </p>
              </div>
            </label>

            {/* card 2*/}
            <label
              onClick={() => {
                setDepreciationType(
                  envConfig.depreciationType.REDUCING_BALANCE
                );
              }}
              className="checked__label"
              htmlFor="REDUCING_BALANCE"
            >
              <div
                className={` Depreciation__card jobcreate__links--${
                  depreciationType ===
                  envConfig.depreciationType.REDUCING_BALANCE
                    ? "selected"
                    : ""
                } mt-sm`}
              >
                <svg
                  className={`Depreciation__svg ${
                    depreciationType ===
                    envConfig.depreciationType.REDUCING_BALANCE
                      ? "Depreciation__selected--svg"
                      : ""
                  }`}
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M38.5765 11.5015L38.7135 11.6111H38.8889H49.5V46.7222H0.5V12.1111L10.8111 19.8444L11.2205 20.1515L11.518 19.7351L25.1 0.720283L38.5765 11.5015ZM44.1373 39.1445L44.9444 39.773V38.75V16.6667V16.1667H44.4444H37.1198L26.4235 7.60957L26.011 7.27958L25.7041 7.70955L12.2238 26.5984L5.85556 21.8222L5.05556 21.2222V22.2222V32.2222V32.4625L5.24321 32.6127L13.5765 39.2793L13.9853 39.6064L14.2933 39.183L25.1005 24.323L44.1373 39.1445Z" />
                </svg>
                <img
                  className={`jobcreate__tic jobcreate__tic${
                    depreciationType ===
                    envConfig.depreciationType.REDUCING_BALANCE
                      ? "--display"
                      : ""
                  }`}
                  src={tick}
                  alt="tic"
                />
                <p
                  className={`Depreciation__para ${
                    depreciationType ===
                    envConfig.depreciationType.REDUCING_BALANCE
                      ? "Depreciation__blue"
                      : ""
                  }`}
                >
                  {
                    props.translations.setupDepreciationPage.form
                      .reducingBalance.label
                  }
                </p>
              </div>
            </label>
          </div>
          <div className="Depreciation__btns">
            <Button className="btn btn-svgs Depreciation__btn" type="submit">
              {props.translations.setupDepreciationPage.button.submit}
              <img src={arrRight} alt="" />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslations(state),
  }),
  { setMessageUser }
)(SetupClass);
