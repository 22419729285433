import { connect, useDispatch } from "react-redux";
import { getForgotData, getTranslations } from "../../../redux/selector";
import { Button, Form, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { logo, arrRight, loginMain } from "../../../config/assets";
import { useState } from "react";
import { forgotUser } from "../../../redux/api";
import validators from "../../../config/validators";
import { setForgotUser } from "../../../redux/features/user";
import envConfig from "../../../config/constants";

const Forgot = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [unchanged, setUnchanged] = useState(true);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");

  const verifyInput = (value) => {
    setUnchanged(false);
    setEmail(value);
    setEmailValid(value.match(validators.email));
    setEmailMessage(
      value === ""
        ? ""
        : value.match(validators.email)
        ? props.translations.forgotPage.validation.emailVerify
        : props.translations.forgotPage.validation.emailInvalid
    );
  };

  if (
    unchanged &&
    email === "" &&
    props.userData.email &&
    props.userData.email !== ""
  )
    verifyInput(props.userData.email);

  return (
    <>
      <div className="enterEmail__story">
        <img className="enterEmail__img" src={loginMain} alt="reset" />
        <div className="enterEmail__text">
          <p className="enterEmail__text--size">
            {props.translations.forgotPage.advertise.message}
          </p>
          <img
            className="enterEmail__img--2"
            src={logo}
            alt="enterEmail page logo with name"
          />
        </div>
      </div>
      <div className="enterEmail__content">
        <Form
          className="enterEmail__box"
          onSubmit={async (e) => {
            e.preventDefault();
            const response = await dispatch(
              forgotUser(JSON.stringify({ email }))
            );
            if (response.payload.status === 200) {
              props.setForgotUser({ email });
              navigate("/auth/forgot-verify");
            }
          }}
        >
          <h2 className="heading-1 mb-s-sm">
            {props.translations.forgotPage.heading.h2}
          </h2>
          <p className="mb-sm">{props.translations.forgotPage.heading.para}</p>
          <div className="mb-md mt-sm">
            <label htmlFor="email" className="form__label" aria-hidden="true">
              {props.translations.forgotPage.form.label.email}
            </label>
            <Input
              className="form__input enterEmail__w32"
              type="email"
              name="email"
              autoFocus
              maxLength={`${props.translations.forgotPage.form.input.maxLength}`}
              minLength={`${props.translations.forgotPage.form.input.minLength}`}
              aria-describedby="Enter Email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => verifyInput(e.target.value.trim())}
              required
            />
            <span
              className={`enterEmail__err form__label--${
                emailValid ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {emailMessage}
            </span>
          </div>
          <Button className="btn btn-svg enterEmail__btn" type="submit">
            {props.translations.forgotPage.button.submit}
            <img src={arrRight} alt="" />
          </Button>
          <p className="mt-md">
            <Link to="/auth/login" className="enterEmail__link">
              {props.translations.forgotPage.link.login}
            </Link>
          </p>
          <p className="mt-sm">
            <Link
              onClick={() => {
                window
                  .open(
                    envConfig.api.baseURL + envConfig.api.termsURL,
                    "_blank"
                  )
                  .focus();
              }}
              className="register__link small_font"
            >
              By using <b>Virgilproper</b>, you agree to our{" "}
              <u>Terms and Conditions</u>
            </Link>
          </p>
        </Form>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    userData: getForgotData(state),
  }),
  { setForgotUser }
)(Forgot);
