import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import { peopleGrowing } from "../../config/assets";
import envConfig from "../../config/constants";
import { calculateInvestment } from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import { getAuthKey, getTranslations, getUserData } from "../../redux/selector";

const InvestmentModelReport = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [report, setReport] = useState(null);

  const fetchReport = async () => {
    const { payload: investmentReport } = await dispatch(
      calculateInvestment({
        authKey: props.authKey,
        classId: state.selectedClass.id,
        assetId: state.selectedAsset.id,
        body: JSON.stringify({
          number_of_payments: state.paymentCount,
          number_of_years: state.yearCount,
          inflation: state.inflationRate,
          interest: state.interestRate,
          present_value: state.presentValue,
        }),
      })
    );
    if (investmentReport.status === 200) {
      setReport(investmentReport.data);
    } else {
      props.setMessageUser({
        type: envConfig.notificationTypes.danger,
        message: investmentReport.message,
      });
    }
  };

  useEffect(() => {
    if (
      !(
        state &&
        state.presentValue &&
        state.interestRate &&
        state.inflationRate &&
        state.paymentCount &&
        state.yearCount
      )
    ) {
      navigate("/app/dashboard");
      return;
    }
    fetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, state]);

  return (
    <>
      <TopNav />
      <SideNav />

      {state && report && (
        <>
          <div className="listings">
            <BreadCrumbs crumbs={["Investment Model", "Report"]} />
          </div>
          <div className="messages">
            <div className="message__box">
              {/* <div className="companyProfile__header"> */}
              <h3 className="heading-3">Investment Model</h3>
              {/* </div> */}
              <div className="message__img">
                <img className="message__svg" src={peopleGrowing} alt="" />
              </div>
              <p className="font-20 line_height-15 cap ">
                Dear{" "}
                <span className="c__blue weight-600">
                  {props.userData.name}
                </span>
                ,
              </p>
              <p className=" font-20 line_height-15">
                <span>
                  For your asset{" "}
                  <span className="c__blue weight-600">
                    {state.selectedAsset.label}
                  </span>{" "}
                  of class{" "}
                  <span className="c__blue weight-600 cap">
                    {state.selectedClass.label}
                  </span>
                  .
                </span>
              </p>
              <p className=" font-20 line_height-15">
                The{" "}
                <span className="c__blue weight-600">
                  {state.yearCount} year(s)
                </span>{" "}
                future value of asset is{" "}
                <span className="c__blue weight-600">
                  {report.future_value}
                </span>{" "}
                <span>
                  and you will have to invest{" "}
                  <span className="c__blue weight-600">
                    {report.period_investment}
                  </span>
                </span>{" "}
                <span>
                  from{" "}
                  <span className="c__blue weight-600">{report.period}.</span>
                </span>
              </p>
              {/* <div className="message__link mt-sm"> */}
              <span
                onClick={() => {
                  navigate("/app/investment-model", {
                    state: {
                      selectedClass: state.selectedClass,
                      selectedAsset: state.selectedAsset,
                      yearCount: state.yearCount,
                      paymentCount: state.paymentCount,
                      presentValue: state.presentValue,
                      interestRate: state.interestRate,
                      inflationRate: state.inflationRate,
                    },
                  });
                }}
                className="message__link cap"
              >
                Recalculate
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    authKey: getAuthKey(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(InvestmentModelReport);
