import { connect, useDispatch } from "react-redux";
import { Button, Form, Input } from "reactstrap";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import {
  getAuthKey,
  getClassAssetInfo,
  getClassAssets,
  getUserData,
} from "../../redux/selector";
import { setMessageUser } from "../../redux/features/user";
import BreadCrumbs from "../../components/breadCrumbs";
import { clearClassColumn } from "../../redux/features/asset";
import envConfig from "../../config/constants";
import { useEffect, useState } from "react";
import { hideIcon, viewIcon } from "../../config/assets";
import validators from "../../config/validators";
import getTranslation from "../../translations";
import { updateAccountant } from "../../redux/api";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAdmin } from "../../config/utility";

const AccountantEdit = (props) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accessRight, setAccessRight] = useState(state?.access_right);
  const [name, setName] = useState(state?.name);
  const [nameValid, setNameValid] = useState(false);
  const [nameMessage, setNameMessage] = useState("");
  const [email, setEmail] = useState(state?.email);
  const [emailValid, setEmailValid] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [phone, setPhone] = useState(state?.phone);
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneMessage, setPhoneMessage] = useState("");
  const [password, setPassword] = useState("");
  const [isText, setIsText] = useState(false);

  const verifyInput = (type, value) => {
    switch (type) {
      case "name":
        setName(value);
        setNameValid(value.match(validators.name));
        setNameMessage(
          value === ""
            ? ""
            : value.match(validators.name)
            ? props.translations.registerPage.validation.nameVerify
            : props.translations.registerPage.validation.nameInvalid
        );
        break;
      case "email":
        setEmail(value);
        setEmailValid(value.match(validators.email));
        setEmailMessage(
          value === ""
            ? ""
            : value.match(validators.email)
            ? props.translations.registerPage.validation.emailVerify
            : props.translations.registerPage.validation.emailInvalid
        );
        break;
      case "phone":
        setPhone(value);
        setPhoneValid(value.match(validators.phone));
        setPhoneMessage(
          value === ""
            ? ""
            : value.match(validators.phone)
            ? props.translations.registerPage.validation.phoneVerify
            : props.translations.registerPage.validation.phoneInvalid
        );
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!(state && state.id) || !checkAdmin(props.userData.access_right)) {
      navigate("/app/dashboard");
    }
  }, [navigate, props.userData.access_right, state]);

  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs crumbs={["Accountant", "Edit"]} />
        <div className="listing">
          <div className="companyProfile__header">
            <h3 className="heading-3">Add Accountant </h3>
          </div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              if (accessRight === -1) {
                props.setMessageUser({
                  message: "Please select access right",
                  type: envConfig.notificationTypes.warning,
                });
                return;
              }
              const response = await dispatch(
                updateAccountant({
                  authKey: props.authKey,
                  accountantId: state.id,
                  body: JSON.stringify({
                    name,
                    email,
                    access_right: accessRight,
                    phone,
                    password: password === "" ? undefined : password,
                  }),
                })
              );
              if (response.payload.status === 200) {
                navigate("/app/accountants");
                props.setMessageUser({
                  message: response.payload.message,
                  type: envConfig.notificationTypes.success,
                });
              } else {
                props.setMessageUser({
                  message: response.payload.message,
                  type: envConfig.notificationTypes.danger,
                });
              }
            }}
          >
            <div className="companyProfile__labels">
              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="name"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Name of Accountant
                  </label>
                  <Input
                    className="form__input "
                    type="text"
                    placeholder="Enter Name"
                    minLength="3"
                    maxLength="32"
                    value={name}
                    onChange={(e) => {
                      verifyInput("name", e.target.value);
                    }}
                    required
                  />
                  <span
                    className={`login__err form__label--${
                      nameValid ? "dark" : "error"
                    }`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {nameMessage}
                  </span>
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="email"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Email of Accountant
                  </label>
                  <Input
                    className="form__input "
                    type="email"
                    placeholder="Enter Email"
                    minLength="5"
                    maxLength="64"
                    value={email}
                    onChange={(e) => {
                      verifyInput("email", e.target.value);
                    }}
                    required
                  />
                  <span
                    className={`login__err form__label--${
                      emailValid ? "dark" : "error"
                    }`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {emailMessage}
                  </span>
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="password"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Password
                  </label>
                  <Input
                    className="form__input "
                    type={isText ? "text" : "password"}
                    placeholder="Enter Password"
                    minLength="6"
                    maxLength="16"
                    value={password}
                    onChange={(e) => {
                      verifyInput("password", e.target.value);
                    }}
                  />
                  <span
                    className="companyProfile__label__icons cursor"
                    onClick={() => {
                      setIsText(!isText);
                    }}
                  >
                    {isText ? (
                      <img src={hideIcon} alt="" />
                    ) : (
                      <img src={viewIcon} alt="" />
                    )}
                  </span>
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="phone"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Phone Number
                  </label>
                  <Input
                    className="form__input "
                    type="tel"
                    placeholder="Enter Phone"
                    minLength="7"
                    maxLength="16"
                    value={phone}
                    onChange={(e) => {
                      verifyInput("phone", e.target.value);
                    }}
                    required
                  />
                  <span
                    className={`login__err form__label--${
                      phoneValid ? "dark" : "error"
                    }`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {phoneMessage}
                  </span>
                </div>
              </div>

              <div className="sSetup__grid-2">
                <div className="">
                  Access Right
                  <div className="accMgmt__grid--2">
                    {envConfig.accessRightsData.map(
                      (accessRightData, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setAccessRight(accessRightData.value);
                            }}
                          >
                            <label className="form__label mt-s-sm cursor">
                              <p
                                className={`accMgmt__edit accMgmt__edit--${
                                  accessRight === accessRightData.value
                                    ? "blue"
                                    : "gray"
                                }`}
                              >
                                {accessRightData.name}
                              </p>
                            </label>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="popup__btns editProfile__btns mt-md">
              <Button className="btn popup__btn btn__blue cap" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslation(state),
    userData: getUserData(state),
    classAssets: getClassAssets(state),
    classAssetInfo: getClassAssetInfo(state),
  }),
  { setMessageUser, clearClassColumn }
)(AccountantEdit);
