import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import { cross, emptyHistory, emptyImpairment } from "../../config/assets";
import envConfig from "../../config/constants";
import {
  checkEditAccessRight,
  verifyClassLand,
  verifyClassNameAndId,
  verifyReducingBalance,
} from "../../config/utility";
import { deleteAssets, getAssetDetails } from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import {
  getAssetDetailData,
  getAuthKey,
  getTranslations,
  getUserData,
} from "../../redux/selector";
import { Button, Form, Input } from "reactstrap";
import swal from "sweetalert";
import Popup from "reactjs-popup";

const AssetDetail = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isReadOnly = checkEditAccessRight(props.userData.access_right);
  const isLand = verifyClassLand(props.userData.classes, params.class_id);
  const isReducingBalance = verifyReducingBalance(
    props.userData.organization.depreciation_type
  );

  const [historyBox, setHistoryBox] = useState(false);
  const [impairmentBox, setImpairmentBox] = useState(false);
  const [landDisposeLogBox, setLandDisposeLogBox] = useState(false);
  const [deleteBox, setDeleteBox] = useState(false);
  const [deleteWrite, setDeleteWrite] = useState("");

  useEffect(() => {
    if (
      !verifyClassNameAndId(
        props.userData.classes,
        params.class_name,
        params.class_id
      )
    )
      navigate("/app/dashboard");
    else {
      dispatch(
        getAssetDetails({
          authKey: props.authKey,
          classId: params.class_id,
          assetId: params.asset_id,
        })
      );
    }
  }, [
    dispatch,
    navigate,
    params.asset_id,
    params.class_id,
    params.class_name,
    props.authKey,
    props.userData.classes,
  ]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "600",
        fontSize: "15px",
        textTransform: "capitalize",
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
    rows: {
      style: {
        minHeight: "3rem", // override the row height
      },
    },
  };

  let headers = [],
    data = [],
    assetId = "",
    assetType = { column_id: "", value: "" },
    purchaseCost = { column_id: "", value: "", originalValue: 0 },
    usefulLife = { column_id: "", value: "" },
    purchaseDate = { column_id: "", value: "", originalValue: "" },
    residualValue = { column_id: "", value: "", originalValue: "" },
    description = { column_id: "", value: "" },
    landType = { column_id: "", value: "" },
    lastUpdated = "";
  props.assetDetails.data.forEach((assetRow, index) => {
    if (index === 0) {
      headers = assetRow.reduce((array, asset) => {
        if (
          asset.default_column_id === envConfig.defaultColumn.asset_id ||
          asset.default_column_id === envConfig.defaultColumn.initial_cost ||
          asset.default_column_id === envConfig.defaultColumn.initial_date
        )
          return array;
        const selector = array.length;
        array.push({
          ...asset,
          selector: (row) => {
            return row[selector].value;
          },
          right: true,
          wrap: true,
        });
        return array;
      }, []);
    } else {
      data.push(
        assetRow.reduce((array, asset) => {
          if (index === 1) {
            lastUpdated = moment(asset.created_on).format(
              "DD MMM YYYY HH:mm A"
            );
            switch (asset.default_column_id) {
              case envConfig.defaultColumn.asset_id:
                assetId = asset.value;
                break;
              case envConfig.defaultColumn.type:
                assetType = { column_id: asset.column_id, value: asset.value };
                break;
              case envConfig.defaultColumn.purchase_cost:
                purchaseCost = {
                  column_id: asset.column_id,
                  value: asset.value,
                  originalValue: asset.originalValue,
                };
                break;
              case envConfig.defaultColumn.useful_life:
                usefulLife = { column_id: asset.column_id, value: asset.value };
                break;
              case envConfig.defaultColumn.purchase_date:
                purchaseDate = {
                  column_id: asset.column_id,
                  value: asset.value,
                  originalValue: asset.originalValue,
                };
                break;
              case envConfig.defaultColumn.residual_value:
                residualValue = {
                  column_id: asset.column_id,
                  value: asset.value,
                  originalValue: asset.originalValue,
                };
                break;
              case envConfig.defaultColumn.description:
                description = {
                  column_id: asset.column_id,
                  value: asset.value,
                };
                break;
              case envConfig.defaultColumn.land_type:
                landType = {
                  column_id: asset.column_id,
                  value: asset.value,
                };
                break;
              default:
                break;
            }
          }

          if (
            asset.default_column_id === envConfig.defaultColumn.asset_id ||
            asset.default_column_id === envConfig.defaultColumn.initial_cost ||
            asset.default_column_id === envConfig.defaultColumn.initial_date
          )
            return array;
          array.push(asset);
          return array;
        }, [])
      );
    }
  });

  return (
    <>
      <TopNav />
      <SideNav classId={params.class_id} className={params.class_name} />

      <div className="listings">
        <BreadCrumbs crumbs={["Archived " + params.class_name, "Details"]} />
        <div className="listing ">
          <div className="assetinfo__history">
            <div
              onClick={() => {
                setHistoryBox(true);
              }}
            >
              <span className="font-14 weight-400 border__bottom--gray cursor info__edit">
                Last Updated on {lastUpdated}
              </span>
            </div>
            <div className="assetinfo__gridHistory">
              {props.assetDetails.move_info?.refer_asset_id && (
                <div
                  onClick={() => {
                    navigate(
                      `/app/archive-asset-detail/${props.assetDetails.move_info?.refer_class_name}/${props.assetDetails.move_info?.refer_class_id}/${props.assetDetails.move_info?.refer_asset_id}`
                    );
                  }}
                >
                  <span className="font-14 weight-400 border__bottom--blue cursor info__edit">
                    Pre-transfer History
                  </span>
                </div>
              )}
              {isLand && (
                <div
                  onClick={() => {
                    setLandDisposeLogBox(true);
                  }}
                >
                  <span className="font-14 weight-400 border__bottom--blue cursor info__edit">
                    Dispose History
                  </span>
                </div>
              )}
              <div
                onClick={() => {
                  setImpairmentBox(true);
                }}
              >
                <span className="font-14 weight-400 border__bottom--gray cursor info__edit">
                  Impairment History
                </span>
              </div>
            </div>
          </div>
          <div className="assetinfo__title mt-md">
            {/* asset_get_value- */}
            <div>
              <p className="weight-700">Archived Asset ID </p>
              <p className="info__para ">{assetId}</p>
            </div>
            {/* asset_type */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm">Asset Type</span>
              </div>
              <p className="info__para">{assetType.value}</p>
            </div>

            {/* cost */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm">Cost</span>
              </div>
              <p className="info__para ">{purchaseCost.value}</p>
            </div>
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm">Useful Life</span>
              </div>
              <p className="info__para ">{usefulLife.value}</p>
            </div>

            {/* purchase-date */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm">Recognition Date</span>
              </div>
              <p className="info__para ">{purchaseDate.value}</p>
            </div>
          </div>
          <div className="assetinfo__title">
            {/* Depreciation type */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm ">Depreciation Type</span>
              </div>

              <p className="info__para ">
                {
                  envConfig.depreciationTypeName[
                    props.userData.organization.depreciation_type
                  ]
                }
              </p>
            </div>
            {isReducingBalance && (
              <div>
                <div className="">
                  <span className="weight-700 mb-s-sm">Residual Value</span>
                </div>
                <p className="info__para ">{residualValue.value}</p>
              </div>
            )}
            {/* description */}
            <div>
              <div className="">
                <span className="weight-700 mb-s-sm ">Description</span>
              </div>
              <p className="info__para ">{description.value}</p>
            </div>
            {isLand && (
              <div>
                <div className="">
                  <span className="weight-700 mb-s-sm ">Type of Land</span>
                </div>
                <p className="info__para ">{landType.value}</p>
              </div>
            )}
            <div>
              {/* Dispose Amount  */}
              <div className="">
                <span className="weight-700 mb-s-sm c-orange ">
                  Dispose Amount
                </span>
              </div>

              <p className="info__para c-orange">
                {props.assetDetails?.dispose_log?.dispose_amount}
              </p>
            </div>
            <div>
              {/* Dispose Date*/}
              <div className="">
                <span className="weight-700 mb-s-sm c-orange">
                  Dispose Date
                </span>
              </div>

              <p className="info__para c-orange">
                {props.assetDetails?.dispose_log?.dispose_date}
              </p>
            </div>
          </div>
          {!isReadOnly && (
            <div className="assetinfo__history">
              <div></div>
              <div
                onClick={() => {
                  setDeleteBox(true);
                }}
              >
                <span className="font-14 weight-400 border__bottom--danger cursor">
                  Permanently delete asset
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="listing asset-listing">
          <DataTable
            columns={headers}
            data={data}
            highlightOnHover={true}
            pagination={true}
            persistTableHead={true}
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight="300px"
          />
        </div>
      </div>

      {/* update history */}
      {historyBox && (
        <div className="history__popup">
          <div className="history__box">
            <span
              className="close-icon"
              onClick={() => {
                setHistoryBox(false);
              }}
            ></span>
            <div className="history__content">
              <div className="assetinfo__cross">
                <h2 className="heading-2">Log</h2>
                <div
                  className="assetinfo__crossImg cursor"
                  onClick={() => {
                    setHistoryBox(false);
                  }}
                >
                  <img className="" src={cross} alt="cut history popup" />
                </div>
              </div>

              <div className="dashboards__card">
                {props.assetDetails.activity_log.length === 0 ? (
                  <div className="cap">
                    <div className="assetinfo__empty">
                      <img
                        className="assetinfo__EmptySvg"
                        src={emptyHistory}
                        alt="empty history"
                      />
                    </div>
                  </div>
                ) : (
                  props.assetDetails.activity_log.map((log, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="dashboards__underline"></div>
                        <div className="dashboards__dots">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className="dashboards__blueBg cap">
                            {log.created_on}
                          </p>
                        </div>

                        <div className="dashboards__dots dashboards__end">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className=" cap">{log.user_name}</p>
                        </div>
                        <p className="dashboards__subFull dashboards__gray">
                          {log.message}
                        </p>
                      </React.Fragment>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* impairment history */}
      {impairmentBox && (
        <div className="history__popup">
          <div className="history__box">
            <span
              className="close-icon"
              onClick={() => {
                setImpairmentBox(false);
              }}
            ></span>
            <div className="history__content">
              <div className="assetinfo__cross">
                <h2 className="heading-2">Impairment Log</h2>
                <div
                  className="assetinfo__crossImg cursor"
                  onClick={() => {
                    setImpairmentBox(false);
                  }}
                >
                  <img className="" src={cross} alt="cut history popup" />
                </div>
              </div>

              <div className="dashboards__card">
                {props.assetDetails.impairment_log.length === 0 ? (
                  <div className="cap">
                    <div className="assetinfo__empty">
                      <img
                        className="assetinfo__EmptySvg"
                        src={emptyImpairment}
                        alt="empty history"
                      />
                      <p className="mt-sm c__aa">No Impairement Added Yet</p>
                    </div>
                  </div>
                ) : (
                  props.assetDetails.impairment_log.map((log, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="dashboards__underline"></div>
                        <div className="dashboards__dots">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className="dashboards__blueBg cap">
                            {log.created_on}
                          </p>
                        </div>
                        <div className="dashboards__dots dashboards__end">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className=" cap">{log.amount}</p>
                        </div>
                        <p className="dashboards__subFull cap">
                          {log.nature_type}
                        </p>
                        <p className="dashboards__subFull dashboards__gray">
                          {log.sources}
                        </p>
                      </React.Fragment>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* land dispose history */}
      {landDisposeLogBox && (
        <div className="history__popup">
          <div className="history__box">
            <span
              className="close-icon"
              onClick={() => {
                setLandDisposeLogBox(false);
              }}
            ></span>
            <div className="history__content">
              <div className="assetinfo__cross">
                <h2 className="heading-2">Disposal Logs</h2>
                <div
                  className="assetinfo__crossImg cursor"
                  onClick={() => {
                    setLandDisposeLogBox(false);
                  }}
                >
                  <img className="" src={cross} alt="cut history popup" />
                </div>
              </div>

              <div className="dashboards__card">
                {props.assetDetails.land_dispose_log.length === 0 ? (
                  <div className="cap">
                    <div className="assetinfo__empty">
                      <img
                        className="assetinfo__EmptySvg"
                        src={emptyImpairment}
                        alt="empty history"
                      />
                      <p className="mt-sm c__aa">No Disposal Added Yet</p>
                    </div>
                  </div>
                ) : (
                  props.assetDetails.land_dispose_log.map((log, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="dashboards__underline"></div>
                        <div className="dashboards__dots">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className="dashboards__blueBg cap">
                            {log.created_on}
                          </p>
                        </div>
                        <div className="dashboards__dots dashboards__end">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className=" cap">{log.amount}</p>
                        </div>
                        <p className="dashboards__subFull cap">
                          Dispose Area (Sq.M.) {log.area}
                        </p>
                        <p className="dashboards__subFull dashboards__gray">
                          Dispose Price per sq.m. {log.area_price}
                        </p>
                      </React.Fragment>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* delete asset */}
      <Popup
        open={deleteBox}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue">Delete Asset</h2>
            <button
              className="popup__close close"
              onClick={() => {
                setDeleteBox(false);
                setDeleteWrite("");
              }}
            >
              <img src={cross} alt="cross" />
            </button>
          </div>
          <div className="content">
            <Form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault();
                if (assetId === deleteWrite)
                  swal({
                    title: "Are you sure?",
                    text: "You want to delete the asset. This cannot be reverted",
                    icon: "error",
                    buttons: true,
                  }).then(async (result) => {
                    if (result) {
                      const response = await dispatch(
                        deleteAssets({
                          authKey: props.authKey,
                          classId: params.class_id,
                          assetId: params.asset_id,
                          body: JSON.stringify({ write_asset_id: deleteWrite }),
                        })
                      );
                      if (response.payload.status === 200) {
                        props.setMessageUser({
                          message: response.payload.message,
                          type: envConfig.notificationTypes.success,
                        });
                        navigate(
                          "/app/archive-assets/" +
                            params.class_name +
                            "/" +
                            params.class_id
                        );
                      } else {
                        props.setMessageUser({
                          message: response.payload.message,
                          type: envConfig.notificationTypes.danger,
                        });
                      }
                    } else {
                      setDeleteBox(false);
                      setDeleteWrite("");
                    }
                  });
              }}
            >
              <div className="mb-md">
                <label
                  htmlFor="amount"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  Write the asset id "{assetId}" in the text box below
                </label>
                <Input
                  className="form__input"
                  type="text"
                  name="writeText"
                  autoFocus
                  minLength="1"
                  maxLength="64"
                  value={deleteWrite}
                  onChange={(e) => {
                    setDeleteWrite(e.target.value);
                  }}
                  placeholder={assetId}
                  required
                />
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  Delete
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslations(state),
    userData: getUserData(state),
    assetDetails: getAssetDetailData(state),
  }),
  { setMessageUser }
)(AssetDetail);
