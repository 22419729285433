import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { Button, Form, Input } from "reactstrap";
import swal from "sweetalert";
import DesktopSetupTopBar from "../../components/desktopSetupTopBar";
import MobileSetupTopBar from "../../components/mobileSetupTopBar";
import { arrRight, cross, Down } from "../../config/assets";
import envConfig from "../../config/constants";
import { createColumns, getDefaultColumn, selfUser } from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import {
  getAuthKey,
  getDefaultColumnData,
  getTranslations,
  getUserData,
} from "../../redux/selector";

const SetupColumn = (props) => {
  const dispatch = useDispatch();

  const [addColumn, setAddColumn] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [customColumn, setCustomColumn] = useState([]);
  const [columnName, setColumnName] = useState("");
  const [columnType, setColumnType] = useState("");
  const [showClasses, setShowClasses] = useState(false);
  const [viewColumnId, setViewColumnId] = useState(-1);

  useEffect(() => {
    dispatch(getDefaultColumn({ authKey: props.authKey }));
  }, [dispatch, props.authKey]);

  if (props.columns.length > 0 && selectedColumn.length === 0)
    setSelectedColumn(props.columns);

  const movePosition = (index, direction) => {
    if (
      (index === selectedColumn.length - 1 && direction === 1) ||
      (index === 0 && direction === -1)
    )
      return;
    let temp = Object.assign([], selectedColumn);
    const element = temp.splice(index, 1)[0];
    temp.splice(index + direction, 0, element);
    setSelectedColumn(temp);
  };

  const addCustomColumn = (index) => {
    let temp = Object.assign([], selectedColumn);
    let tempCustom = Object.assign([], customColumn);
    const element = tempCustom.splice(index, 1)[0];
    temp.push(element);
    setCustomColumn(tempCustom);
    setSelectedColumn(temp);
  };
  const removeCustomColumn = (index) => {
    let temp = Object.assign([], selectedColumn);
    let tempCustom = Object.assign([], customColumn);
    const element = temp.splice(index, 1)[0];
    tempCustom.push(element);
    setCustomColumn(tempCustom);
    setSelectedColumn(temp);
  };

  return (
    <>
      <div className="record">
        {/* Mobile Navbar */}
        <MobileSetupTopBar />
        {/* Desktop Navbar */}
        <DesktopSetupTopBar />
        <div className="record__container">
          <h2 className="heading-1 record__blue">
            {props.translations.setupColumn.title.h2}
          </h2>
          <p className="mt-sm record__blue">
            {props.translations.setupColumn.title.para}
          </p>
          <div className="record__records">
            <div className="record__addRecord">
              <div className="record__grid-2">
                <p>{props.translations.setupColumn.title.leftRecord}</p>
                <div
                  className="record__boxBtn"
                  onClick={() => {
                    setAddColumn(true);
                  }}
                >
                  <Button className=" record__plusBtn">+</Button>
                </div>
              </div>
              <div className="record__leftnav">
                {customColumn.map((column, index) => {
                  return (
                    <div className="record__tab" key={index}>
                      <p className="cap">{column.name}</p>
                      <div className="record__boxBtn">
                        <Button
                          type="text"
                          className="record__tabBtn"
                          onClick={() => {
                            addCustomColumn(index);
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="">
              <p className="record__rearrangeName">
                {props.translations.setupColumn.title.rightRecord}
              </p>
              <div className="record__contents">
                {selectedColumn.map((column, index) => {
                  return (
                    <div className="record__rearranges" key={index}>
                      <p className="record__listname cap">
                        {("0" + (index + 1)).slice(-2)} =&gt; {column.name}
                      </p>
                      {column.custom && (
                        <p
                          className="record__classBnts"
                          onClick={() => {
                            setViewColumnId(index);
                            setShowClasses(true);
                          }}
                        >
                          {column.classes.length}
                        </p>
                      )}
                      <div className="record__updowns">
                        <div
                          className="record__boxBtn"
                          onClick={() => {
                            movePosition(index, -1);
                          }}
                        >
                          <span className="record__plusBtn record__plusBtn-blue">
                            <Down
                              className={`record__arr-angle-180 record__arr-${
                                index === 0 ? "grey" : "blue"
                              }`}
                            />
                          </span>
                        </div>
                        <div
                          className="record__boxBtn"
                          onClick={() => {
                            movePosition(index, 1);
                          }}
                        >
                          <span className="record__plusBtn record__plusBtn-blue">
                            <Down
                              className={`record__arr-angle record__arr-${
                                index === selectedColumn.length - 1
                                  ? "grey"
                                  : "blue"
                              }`}
                            />
                          </span>
                        </div>
                        <div className="record__boxBtn">
                          <Button
                            className={`record__plusBtn record__plusBtn-${
                              column.custom ? "red" : "grey"
                            }`}
                            onClick={
                              column.custom
                                ? () => {
                                    removeCustomColumn(index);
                                  }
                                : null
                            }
                          >
                            -
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <Button
            className="btn btn-svgs record__btn"
            onClick={() => {
              swal({
                title: "Are you sure?",
                text: "You have added and selected all the required columns",
                icon: "warning",
                buttons: true,
              }).then(async (result) => {
                if (result) {
                  let updateCount = 0;
                  for (const classDetails of props.userData.classes) {
                    const body = JSON.stringify({
                      columns: selectedColumn.reduce(
                        (array, { classes = [], custom, name, type, id }) => {
                          if (custom) {
                            if (classes.indexOf(classDetails.id) > -1) {
                              array.push({
                                default_column_id: null,
                                name,
                                type,
                                position: array.length + 1,
                              });
                            }
                          } else {
                            array.push({
                              default_column_id: id,
                              name: null,
                              type: null,
                              position: array.length + 1,
                            });
                          }
                          return array;
                        },
                        []
                      ),
                    });
                    const response = await dispatch(
                      createColumns({
                        authKey: props.authKey,
                        classId: classDetails.id,
                        body,
                      })
                    );
                    if (response.payload.status === 200) updateCount++;
                  }
                  dispatch(selfUser({ authKey: props.authKey }));
                  props.setMessageUser({
                    message:
                      updateCount +
                      props.translations.setupColumn.validate.column,
                    type: envConfig.notificationTypes.success,
                  });
                }
              });
            }}
          >
            {props.translations.setupColumn.button.apply}
            <img src={arrRight} alt="" />
          </Button>
        </div>
      </div>
      {/* Add Custom Column */}
      <Popup
        open={addColumn}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header">
            <h2 className="headin-2 popup__blue">
              {props.translations.setupColumn.popupAddRecord.heading}
            </h2>
            <button
              className="popup__close close"
              onClick={() => {
                setAddColumn(false);
              }}
            >
              <img
                src={cross}
                alt={props.translations.setupColumn.alt.removePopup}
              />
            </button>
          </div>
          <div className="content">
            <Form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                let temp = Object.assign([], customColumn);
                temp.push({
                  name: columnName,
                  type: columnType,
                  custom: true,
                  classes: props.userData.classes.map(
                    (classData) => classData.id
                  ),
                });
                setCustomColumn(temp);
                setAddColumn(false);
                setColumnName("");
                setColumnType("");
              }}
            >
              <div className="mb-md">
                <label
                  htmlFor="name"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  {
                    props.translations.setupColumn.popupAddRecord.form
                      .columnName.label
                  }
                </label>
                <Input
                  className="form__input"
                  type={`${props.translations.setupColumn.popupAddRecord.form.columnName.type}`}
                  id={`${props.translations.setupColumn.popupAddRecord.form.columnName.id}`}
                  name={`${props.translations.setupColumn.popupAddRecord.form.columnName.name}`}
                  autoFocus
                  minLength={`${props.translations.setupColumn.popupAddRecord.form.columnName.minLength}`}
                  maxLength={`${props.translations.setupColumn.popupAddRecord.form.columnName.maxLength}`}
                  aria-describedby={`${props.translations.setupColumn.popupAddRecord.form.columnName.placeholder}`}
                  placeholder={`${props.translations.setupColumn.popupAddRecord.form.columnName.placeholder}`}
                  value={columnName}
                  onChange={(e) => {
                    setColumnName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="mb-md">
                <label
                  htmlFor="name"
                  className="form__label cap mb-s-sm"
                  aria-hidden="true"
                >
                  {
                    props.translations.setupColumn.popupAddRecord.form
                      .recordType.label
                  }
                </label>
                <select
                  className="form__select form__select__size-2"
                  name={`${props.translations.setupColumn.popupAddRecord.form.recordType.name}`}
                  id={`${props.translations.setupColumn.popupAddRecord.form.recordType.id}`}
                  value={columnType}
                  onChange={(e) => {
                    setColumnType(e.target.value);
                  }}
                  required
                >
                  <option value="">
                    {
                      props.translations.setupColumn.popupAddRecord.form
                        .recordType.option
                    }
                  </option>
                  {envConfig.columnDataType.map((dataType, index) => {
                    return (
                      <option value={dataType.id} key={index}>
                        {dataType.value}
                      </option>
                    );
                  })}
                </select>
                <span
                  className="form__label--error"
                  role="alert"
                  aria-live="assertive"
                ></span>
              </div>
              <div className="popup__btns">
                <Button className="btn popup__btn btn__blue" type="submit">
                  {props.translations.setupColumn.button.add}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Popup>

      {/* Select Custom Column Classes */}
      <Popup
        open={showClasses}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="modal popup__modal">
          <div className="header popup__header mb-sm">
            <h2 className="headin-2 popup__blue cap">
              {props.translations.setupColumn.popup.heading}
            </h2>
            <button
              className="popup__close close"
              onClick={() => {
                setShowClasses(false);
                setViewColumnId(-1);
              }}
            >
              <img
                src={cross}
                alt={props.translations.setupColumn.alt.removePopup}
              />
            </button>
          </div>
          <p className="c__9b font-20">
            {props.translations.setupColumn.popup.para}
          </p>
          <div className="content">
            <div className="mb-md mt-md">
              {props.userData.classes.map((classData, index) => {
                return (
                  <label
                    key={index}
                    className={`record__popupClasses record__popupClasses--${
                      viewColumnId !== -1 &&
                      selectedColumn[viewColumnId].classes.indexOf(
                        classData.id
                      ) > -1
                        ? "selected"
                        : "unselected"
                    }
                    `}
                    htmlFor={classData.default_class_id}
                    onClick={() => {
                      let temp = Object.assign([], selectedColumn);
                      if (
                        selectedColumn[viewColumnId].classes.indexOf(
                          classData.id
                        ) > -1
                      ) {
                        temp[viewColumnId].classes.splice(
                          selectedColumn[viewColumnId].classes.indexOf(
                            classData.id
                          ),
                          1
                        );
                      } else {
                        temp[viewColumnId].classes.push(classData.id);
                      }
                      setSelectedColumn(temp);
                    }}
                  >
                    <span className={`cap`}>{classData.name}</span>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslations(state),
    columns: getDefaultColumnData(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(SetupColumn);
