import { createSlice } from "@reduxjs/toolkit";
import {
  calculateDeferredTax,
  calculateInvestment,
  checkDuplicateAsset,
  createAssets,
  createColumns,
  deleteAssets,
  disposeAssets,
  disposeLandAssets,
  fairValueAssets,
  fastForwardOneYear,
  fetchDashboard,
  getArchiveAssets,
  getAssetDetails,
  getAssetList,
  getAssets,
  getColumns,
  getDefaultClass,
  getDefaultColumn,
  impairAssets,
  moveAssets,
  transferAssets,
  updateAssets,
  updateStatusAssets,
} from "../api";

const assetSlice = createSlice({
  name: "asset",
  initialState: {
    defaultClass: [],
    defaultColumn: [],
    classAssets: [],
    classColumn: [],
    classAssetInfo: {
      depreciatedCount: 0,
      nonWorkingCount: 0,
      totalCount: 0,
      workingCount: 0,
      fairValue: 0,
      canUpdateFairValue: false,
      canMoveAsset: false,
      fairValueData: [],
    },
    assetDetails: { data: [] },
    classArchiveAssets: [],
    dashboardData: {
      classTrends: [],
      assetAllocationData: [],
      tilesData: {},
      recentEditsData: [],
      classAnalysis: {},
    },
  },
  reducers: {
    setLoading: (state, data) => {
      state.loading = data.payload;
    },
    clearClassColumn: (state) => {
      state.classColumn = [];
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(getDefaultClass.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.defaultClass = action.payload.data;
      })
      .addCase(getDefaultClass.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDefaultColumn.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.defaultColumn = action.payload.data;
        else state.defaultColumn = [];
      })
      .addCase(getDefaultColumn.pending, (state) => {
        state.loading = true;
        state.defaultColumn = [];
      })
      .addCase(createColumns.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createColumns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssets.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          state.classAssets = action.payload.data;
          state.classAssetInfo = {
            depreciatedCount: action.payload.depreciated_count,
            nonWorkingCount: action.payload.non_working_count,
            totalCount: action.payload.total_count,
            workingCount: action.payload.working_count,
            fairValue: action.payload.fair_value,
            fairValueData: action.payload.fair_value_data,
            canUpdateFairValue: action.payload.can_update_fair_value,
            canMoveAsset: action.payload.can_move_asset,
            total: action.payload.total,
            total_working: action.payload.total_working,
            total_non_working: action.payload.total_non_working,
            total_depreciated_working: action.payload.total_depreciated_working,
          };
        } else {
          state.classAssets = [];
          state.classAssetInfo = {
            depreciatedCount: 0,
            nonWorkingCount: 0,
            totalCount: 0,
            workingCount: 0,
            fairValue: 0,
            fairValueData: [],
            canUpdateFairValue: false,
            canMoveAsset: false,
          };
        }
      })
      .addCase(getAssets.pending, (state) => {
        state.classAssets = [];
        state.classAssetInfo = {
          depreciatedCount: 0,
          nonWorkingCount: 0,
          totalCount: 0,
          workingCount: 0,
          fairValue: 0,
          fairValueData: [],
          canUpdateFairValue: false,
          canMoveAsset: false,
        };
        state.loading = true;
      })
      .addCase(createAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssetDetails.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) state.assetDetails = action.payload;
        else state.assetDetails = { data: [] };
      })
      .addCase(getAssetDetails.pending, (state) => {
        state.loading = true;
        state.assetDetails = { data: [] };
      })
      .addCase(updateAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(disposeAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(disposeAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(disposeLandAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(disposeLandAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStatusAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateStatusAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(impairAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(impairAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(transferAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(transferAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(moveAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(moveAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.dashboardData = action.payload.data;
        else
          state.dashboardData = {
            classTrends: [],
            assetAllocationData: [],
            tilesData: {},
            recentEditsData: [],
            classAnalysis: {},
          };
      })
      .addCase(fetchDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssetList.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getAssetList.pending, (state) => {
        state.loading = true;
      })
      .addCase(calculateDeferredTax.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(calculateDeferredTax.pending, (state) => {
        state.loading = true;
      })
      .addCase(calculateInvestment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(calculateInvestment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getColumns.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.classColumn = action.payload.data;
        else state.classColumn = [];
      })
      .addCase(getColumns.pending, (state) => {
        state.loading = true;
        state.classColumn = [];
      })
      .addCase(getArchiveAssets.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          state.classArchiveAssets = action.payload.data;
        } else {
          state.classArchiveAssets = [];
        }
      })
      .addCase(getArchiveAssets.pending, (state) => {
        state.classArchiveAssets = [];
        state.loading = true;
      })
      .addCase(fairValueAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fairValueAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(fastForwardOneYear.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fastForwardOneYear.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkDuplicateAsset.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(checkDuplicateAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAssets.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteAssets.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { setLoading, clearClassColumn } = assetSlice.actions;

export default assetSlice.reducer;
