const ScreenLoader = () => {
  return (
    <div className="screen__loader__container">
      <div className="screen__loader__box">
        <div className="screen__loader"></div>
      </div>
    </div>
  );
};

export default ScreenLoader;
