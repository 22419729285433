import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUserData } from "../redux/selector";
import "./topNav.scss";

const BreadCrumbs = ({ userData, crumbs = [] }) => {
  return (
    <div className="listing__breadcrum-2 bp__medium__none">
      <div>
        <h3 className="heading-3">{userData?.organization.name}</h3>
      </div>
      <span className="font-14 op-2 cap">
        <Link className='op-2 cap"' to="/app/dashboard">
          Home
        </Link>
        {crumbs.map((crumb, index) => {
          return (
            <span key={index} className="c__blue">
              {" "}
              / {crumb}
            </span>
          );
        })}
      </span>
    </div>
  );
};

export default connect((state) => ({
  userData: getUserData(state),
}))(BreadCrumbs);
