import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import {
  getAuthKey,
  getSubsidiariesData,
  getUserData,
} from "../../redux/selector";
import DataTable from "react-data-table-component";
import { acUnit, edit, eye, inactiveFreeze } from "../../config/assets";
import envConfig from "../../config/constants";
import { setMessageUser } from "../../redux/features/user";
import BreadCrumbs from "../../components/breadCrumbs";
import {
  accessSubsidiary,
  getSubsidiaries,
  loginAccessUser,
  updateSubsidiary,
} from "../../redux/api";
import swal from "sweetalert";
import { checkAdmin } from "../../config/utility";

const Subsidiaries = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "600",
        fontSize: "15px",
        textTransform: "capitalize",
      },
    },
    rows: {
      style: {
        minHeight: "3rem", // override the row height
      },
    },
  };

  let headers = [
      {
        name: "Subsidiary Name",
        selector: (row) => row.subsidiary_name,
        sortable: true,
      },
      {
        name: "User Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Phone",
        selector: (row) => row.phone,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Created On",
        selector: (row) => row.created_on,
        sortable: true,
      },
      {
        name: "Actions",
        center: true,
        cell: (row) => (
          <>
            <Link
              className="accMgmt__link"
              state={row}
              to={"/app/subsidiary-edit"}
            >
              <div className="tooltip">
                <span className="tooltiptext cap">Edit</span>
                <img src={edit} alt="edit" />
              </div>
            </Link>
            <div
              className="tooltip"
              onClick={() => {
                swal({
                  title: "Are you sure?",
                  text: `Type ${row.status === 1 ? "Freeze" : "Unfreeze"} to ${
                    row.status === 1 ? "freeze" : "unfreeze"
                  } subsidiary`,
                  content: "input",
                  buttons: true,
                }).then(async (value) => {
                  if (
                    value.toLowerCase() ===
                    (row.status === 1 ? "freeze" : "unfreeze")
                  ) {
                    const response = await dispatch(
                      updateSubsidiary({
                        authKey: props.authKey,
                        subsidiaryId: row.id,
                        body: JSON.stringify({
                          status: row.status === 1 ? 2 : 1,
                        }),
                      })
                    );
                    if (response.payload.status === 200) {
                      dispatch(getSubsidiaries({ authKey: props.authKey }));
                      props.setMessageUser({
                        message: response.payload.message,
                        type: envConfig.notificationTypes.success,
                      });
                    } else {
                      props.setMessageUser({
                        message: response.payload.message,
                        type: envConfig.notificationTypes.danger,
                      });
                    }
                  }
                });
              }}
            >
              <span className="tooltiptext cap">
                {row.status === 1 ? "Click to Freeze" : "Click to UnFreeze"}
              </span>
              <img
                src={row.status === 1 ? acUnit : inactiveFreeze}
                className="accMgmt__link cursor"
                alt=" Dispose svg icon"
              />
            </div>

            <span
              className="accMgmt__link"
              onClick={async () => {
                if (row.status === 1) {
                  const response = await dispatch(
                    accessSubsidiary({
                      authKey: props.authKey,
                      subsidiaryId: row.id,
                    })
                  );
                  if (response.payload.status === 200) {
                    navigate("/app/dashboard");
                    dispatch(
                      loginAccessUser({
                        body: JSON.stringify({
                          access_key: response.payload.data.access_key,
                        }),
                        isAdmin: false,
                      })
                    );
                  } else {
                    props.setMessageUser({
                      message: response.payload.message,
                      type: envConfig.notificationTypes.danger,
                    });
                  }
                }
              }}
            >
              <div className="tooltip">
                <span className="tooltiptext cap">
                  {row.status === 1
                    ? "Login to Subsidiary"
                    : "Unfreeze to login"}
                </span>
                <img src={eye} className="cursor" alt="login " />
              </div>
            </span>

            {/* {row.status === 1 ? (
              <div className="tooltip">
                <span className="tooltiptext cap">Disable</span>
                <img
                  src={noDisposed}
                  className="accMgmt__link not-allowed"
                  alt=" un freeze svg icon"
                />
              </div>
            ) : (
              <div className="tooltip">
                <span className="tooltiptext cap">UnFreeze</span>
                <img
                  src={activeDisposed}
                  className="accMgmt__link cursor"
                  alt=" un freeze svg icon"
                />
              </div>
            )} */}
          </>
        ),
      },
    ],
    data = props.subsidiaries;

  if (searchText !== "") {
    data = data.filter((row) => {
      return (
        row.subsidiary_name.toLowerCase().indexOf(searchText.toLowerCase()) >
          -1 ||
        row.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        row.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        row.phone.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
    });
  }

  useEffect(() => {
    if (
      props.userData.organization.is_subsidiary ||
      !checkAdmin(props.userData.access_right)
    ) {
      navigate("/app/dashboard");
    } else {
      dispatch(getSubsidiaries({ authKey: props.authKey }));
    }
  }, [
    dispatch,
    navigate,
    props.authKey,
    props.userData.access_right,
    props.userData.organization.is_subsidiary,
  ]);

  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs crumbs={["Subsidiaries"]} />
        <div className="listing pb0">
          <div className="listing__header listing__arvhiveHeader">
            <h3 className="heading-3 cap">Subsidiary Management</h3>
            <div className="listing__header--box">
              {/* Mobile search */}
              <div className="form__groupd">
                <Input
                  className="form__search"
                  type="search"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>

              {Number(props.userData.access_right) ===
                envConfig.accessRights.admin && (
                <Link
                  to={{
                    pathname: "/app/subsidiary-add",
                  }}
                  className="btn btn__blue btn__brd"
                >
                  + Add Subsidiary
                </Link>
              )}
            </div>
          </div>
          <DataTable
            columns={headers}
            data={data}
            highlightOnHover={true}
            pagination={true}
            persistTableHead={true}
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight="300px"
          />
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    userData: getUserData(state),
    subsidiaries: getSubsidiariesData(state),
  }),
  { setMessageUser }
)(Subsidiaries);
