import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { icon, logout, logoutPower } from "../config/assets";
import { backToAdmin } from "../config/utility";
import { resetUser } from "../redux/features/user";
import { getTranslations, getUserData } from "../redux/selector";

const DesktopSetupTopBar = ({ userData, resetUser, translations }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="record__back subnav subnav__desktop">
      <div className="topnav__logos">
        <img src={icon} alt={`${translations.setupPage.alt.logo}`} />
        <p className="topnav__logo--name">
          {translations.setupPage.logoName.name}
        </p>
      </div>
      <div></div>
      {userData.using_key ? (
        <div
          className="tooltip mr-s-sm"
          onClick={() => {
            backToAdmin(resetUser, dispatch, navigate);
          }}
        >
          <span className="tooltiptext cap ">
            {translations.setupPage.title.backAdmin}
          </span>
          <img
            className="listing__svg cursor"
            src={logoutPower}
            alt="logout or back to user"
          />
        </div>
      ) : (
        <p
          className="subnav__logut cursor"
          onClick={() => {
            resetUser();
          }}
        >
          <img src={logout} alt={`${translations.setupPage.alt.logout}`} />
        </p>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    userData: getUserData(state),
  }),
  { resetUser }
)(DesktopSetupTopBar);
