import envConfig from "../config/constants"
import enTranslations from "./en"

const getTranslation = (language) => {
    switch (language) {
        case envConfig.language.en:
        default:
            return enTranslations
    }
}
export default getTranslation