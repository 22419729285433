import { connect, useDispatch } from "react-redux";
import ReactDOM from "react-dom/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import swal from "sweetalert";
import {
  account,
  additionToPPE,
  allItems,
  archive,
  Biological,
  Buildings,
  companyProfile,
  Computers,
  deferTax,
  detailed,
  downArrow,
  downWhite,
  format,
  fullReport,
  fullyDepreciated,
  fullyWorkingItems,
  help,
  Heritage,
  Infrastructure,
  investmentModel,
  Land,
  logoOnly,
  logooutNav,
  logoutPower,
  Motor,
  nonWorkingItems,
  Office,
  Other,
  pdf,
  phone,
  Plant,
  ppeReconcilliation,
  premium,
  profile,
  summary,
  terms,
  xel,
} from "../config/assets";
import envConfig from "../config/constants";
import { resetUser } from "../redux/features/user";
import { getAuthKey, getTranslations, getUserData } from "../redux/selector";
import "./topNav.scss";
import { useState } from "react";
import { downloadReport } from "../redux/api";
import { setLoading } from "../redux/features/asset";
import { backToAdmin, checkEditAccessRight } from "../config/utility";

const SwalSelect = ({ yearList }) => {
  const [year, setYear] = useState(yearList[0].value);
  return (
    <select
      className="form__input cap"
      value={year}
      onChange={(e) => {
        setYear(e.target.value);
        swal.setActionValue(e.target.value);
      }}
    >
      {yearList.map((year) => {
        return (
          <option key={year.value} value={year.value}>
            {year.name}
          </option>
        );
      })}
    </select>
  );
};

const TopNav = ({ userData, authKey, translations, resetUser, setLoading }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isReadOnly = checkEditAccessRight(userData.access_right);
  const processReports = (report, reportName, format, type = "") => {
    if (
      report === envConfig.api.reports.schedule ||
      report === envConfig.api.reports.reconciliationFormat1 ||
      report === envConfig.api.reports.reconciliationFormat2 ||
      report === envConfig.api.reports.additionToPPE
    ) {
      const content = document.createElement("div");
      const yearList = [];
      const currentFY = userData.organization?.fy_start.split("-")[0];
      for (
        let index = report === envConfig.api.reports.additionToPPE ? 0 : 1;
        index < 4;
        index++
      ) {
        yearList.push({
          value: currentFY - index,
          name: `${currentFY - index}-${currentFY - index + 1}`,
        });
      }
      const root = ReactDOM.createRoot(content);
      root.render(<SwalSelect yearList={yearList} />);
      swal({
        title: "Select the financial year to generate",
        text: reportName,
        buttons: "Generate",
        content,
      }).then(async (value) => {
        if (value) {
          setLoading(true);
          const response = await downloadReport({
            authKey,
            reportParam: `${report}?year=${
              value === true ? yearList[0].value : value
            }&format=${format}&type=${type}&class_id=${
              params.class_id ? params.class_id : ""
            }`,
          });
          setLoading(false);
          if (response.status === 200) {
            window
              .open(window.URL.createObjectURL(response.blob), "_blank")
              .focus();
          }
        }
      });
    } else {
      swal({
        title: "Are you sure you to generate?",
        text: reportName,
        buttons: "Generate",
      }).then(async (value) => {
        if (value) {
          setLoading(true);
          const response = await downloadReport({
            authKey,
            reportParam: `${report}?format=${format}&type=${type}&class_id=${
              params.class_id ? params.class_id : ""
            }`,
          });
          setLoading(false);
          if (response.status === 200) {
            window
              .open(window.URL.createObjectURL(response.blob), "_blank")
              .focus();
          }
        }
      });
    }
  };

  return (
    <>
      <div
        className={`topnav ${
          userData.using_key === true ? "topnav__navAccesRight" : ""
        }`}
      >
        <div className="topnav__logos cursor">
          {/* {window.location.pathname.indexOf("/app/dashboard") === -1 &&
            window.location.pathname.indexOf("/app/home") === -1 && (
              <Link
                onClick={() => {
                  window.history.back();
                }}
              >
                <img
                  src={logoArr}
                  className="topnav__logo--svg"
                  alt="back logo"
                />
              </Link>
            )} */}
          <img src={logoOnly} className="" alt="back logo" />
          <p className="topnav__logo--name">{translations.appInfo.appName}</p>
        </div>
        <div className={`topnav__title topnav__borderLeft`}>
          <p>{translations.appInfo.tagLine}</p>
        </div>

        <div
          className={`${userData.using_key ? "reports__column-4" : "reports"}`}
        >
          <div className="container__ul topnav__ul cursor bp__medium__none">
            <div className="container__li">
              <div className={`report__dropdown report__name`}>
                <Button className="btn btn__blueSemi">
                  <span>
                    <img src={premium} alt="" />
                  </span>
                  <span> Premium</span>
                  <span>
                    <img src={downWhite} alt="" />
                  </span>
                </Button>
              </div>
              {/* Premium dropdown */}
              <div className="content__ul report__ul content__dropdown">
                {/* Defer Tax */}
                <div className="container__ul">
                  <div className="container__li">
                    <Link
                      to={`/app/deferred-tax${
                        params.class_name ? "/" + params.class_name : ""
                      }${params.class_id ? "/" + params.class_id : ""}${
                        params.asset_id ? "/" + params.asset_id : ""
                      }`}
                      className={`cursor`}
                    >
                      <div className={`content__links content__links__sub`}>
                        <img
                          src={deferTax}
                          className="content__logo"
                          alt="full report svg icon"
                        />
                        <p>{translations.topNav.heading.deferTax}</p>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* Investment Model */}
                <div className="container__ul">
                  <div className="container__li">
                    <Link
                      to={`/app/investment-model${
                        params.class_name ? "/" + params.class_name : ""
                      }${params.class_id ? "/" + params.class_id : ""}${
                        params.asset_id ? "/" + params.asset_id : ""
                      }`}
                      className={`cursor`}
                    >
                      <div className={`content__links content__links__sub`}>
                        <img
                          src={investmentModel}
                          className="content__logo"
                          alt="full report svg icon"
                        />
                        <p>Investment Model</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Generate Report */}
          <div className="container__ul topnav__ul cursor bp__medium__none">
            <div className="container__li">
              <div className="report__dropdown report__name topnav__borderRight">
                <p className=" bp__smallLarge__none">
                  {translations.topNav.heading.generateReport}
                </p>
                <img
                  src={downArrow}
                  className="dropdown__icon"
                  alt="up arrow of profile icon "
                />
              </div>
              <div className="content__ul report__ul content__dropdown">
                {/* FULL_REPORT */}
                <div className="container__ul">
                  <div className="container__li">
                    <div className={`cursor`}>
                      <div className={`content__links content__links__sub`}>
                        <img
                          src={fullReport}
                          className="content__logo"
                          alt="full report svg icon"
                        />
                        <p>{translations.topNav.dropDown.fullReport}</p>
                      </div>
                    </div>

                    <div className="content__ul content__ul--3 content__dropdown">
                      <div className="content__li">
                        {/* PPE REPORT */}
                        <div className="container__ul">
                          <div className="container__li">
                            <div className={`cursor`}>
                              <div
                                className={`content__links content__links__sub`}
                              >
                                <img
                                  src={fullReport}
                                  className="content__logo"
                                  alt="PPE REPORT svg icon"
                                />
                                <p>{translations.topNav.dropDown.ppe}</p>
                              </div>
                            </div>

                            <div className="content__ul content__ul--4 content__dropdown">
                              <div className="content__li">
                                {/* PDF */}
                                <div
                                  className="content__li"
                                  onClick={() => {
                                    processReports(
                                      envConfig.api.reports.schedule,
                                      envConfig.reports.ppeSchedule,
                                      envConfig.reports.format.PDF
                                    );
                                  }}
                                >
                                  <div className="content__links">
                                    <img
                                      src={pdf}
                                      className="content__logo report__visible__none9999"
                                      alt="Dashboard svg icon"
                                    />
                                    <p>{translations.topNav.dropDown.pdf}</p>
                                  </div>
                                </div>
                                {/* EXCEL */}
                                <div
                                  className="content__li"
                                  onClick={() => {
                                    processReports(
                                      envConfig.api.reports.schedule,
                                      envConfig.reports.ppeSchedule,
                                      envConfig.reports.format.XLS
                                    );
                                  }}
                                >
                                  <div className="content__links">
                                    <img
                                      src={xel}
                                      className="content__logo report__visible__none9999"
                                      alt="Dashboard svg icon"
                                    />
                                    <p>{translations.topNav.dropDown.excel}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ### PPE REPORT */}

                        {/* PPE Reconcilliation */}
                        <div className="container__ul">
                          <div className="container__li">
                            <div className={`cursor`}>
                              <div
                                className={`content__links content__links__sub`}
                              >
                                <img
                                  src={ppeReconcilliation}
                                  className="content__logo"
                                  alt="PPE Reconcilliation svg icon"
                                />
                                <p>
                                  {
                                    translations.topNav.dropDown
                                      .ppeReconciliation
                                  }
                                </p>
                              </div>
                            </div>

                            <div className="content__ul content__ul--4 content__dropdown">
                              <div className="content__li">
                                {/* Format 1 */}
                                <div className="container__ul">
                                  <div className="container__li">
                                    <div className="cursor">
                                      <div className="content__links content__links__sub">
                                        <img
                                          src={format}
                                          className="content__logo"
                                          alt="format 1 svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.format1}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="content__ul content__ul--5 content__dropdown">
                                      <div className="content__li">
                                        {/* PDF */}
                                        <div
                                          className="content__li"
                                          onClick={() => {
                                            processReports(
                                              envConfig.api.reports
                                                .reconciliationFormat1,
                                              envConfig.reports
                                                .ppeReconciliation,
                                              envConfig.reports.format.PDF
                                            );
                                          }}
                                        >
                                          <div className="content__links">
                                            <img
                                              src={pdf}
                                              className="content__logo report__visible__none9999"
                                              alt="Dashboard svg icon"
                                            />
                                            <p>
                                              {translations.topNav.dropDown.pdf}
                                            </p>
                                          </div>
                                        </div>
                                        {/* EXCEL */}
                                        <div
                                          className="content__li"
                                          onClick={() => {
                                            processReports(
                                              envConfig.api.reports
                                                .reconciliationFormat1,
                                              envConfig.reports
                                                .ppeReconciliation,
                                              envConfig.reports.format.XLS
                                            );
                                          }}
                                        >
                                          <div className="content__links">
                                            <img
                                              src={xel}
                                              className="content__logo report__visible__none9999"
                                              alt="Dashboard svg icon"
                                            />
                                            <p>
                                              {
                                                translations.topNav.dropDown
                                                  .excel
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/* ## Format 1 */}
                                {/* Format 2 */}
                                <div className="container__ul">
                                  <div className="container__li">
                                    <div className="cursor">
                                      <div className="content__links content__links__sub">
                                        <img
                                          src={format}
                                          className="content__logo"
                                          alt="format 2 svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.format2}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="content__ul content__ul--5 content__dropdown">
                                      <div className="content__li">
                                        {/* PDF */}
                                        <div
                                          className="content__li"
                                          onClick={() => {
                                            processReports(
                                              envConfig.api.reports
                                                .reconciliationFormat2,
                                              envConfig.reports
                                                .ppeReconciliation,
                                              envConfig.reports.format.PDF
                                            );
                                          }}
                                        >
                                          <div className="content__links">
                                            <img
                                              src={pdf}
                                              className="content__logo report__visible__none9999"
                                              alt="Dashboard svg icon"
                                            />
                                            <p>
                                              {translations.topNav.dropDown.pdf}
                                            </p>
                                          </div>
                                        </div>
                                        {/* EXCEL */}
                                        <div
                                          className="content__li"
                                          onClick={() => {
                                            processReports(
                                              envConfig.api.reports
                                                .reconciliationFormat2,
                                              envConfig.reports
                                                .ppeReconciliation,
                                              envConfig.reports.format.XLS
                                            );
                                          }}
                                        >
                                          <div className="content__links">
                                            <img
                                              src={xel}
                                              className="content__logo report__visible__none9999"
                                              alt="Dashboard svg icon"
                                            />
                                            <p>
                                              {
                                                translations.topNav.dropDown
                                                  .excel
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/* ## Format 2 */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ### PPE Reconcilliation */}

                        {/* Addition to PPE */}
                        {
                          // false &&
                          <div className="container__ul">
                            <div className="container__li">
                              <div className="cursor">
                                <div className="content__links content__links__sub">
                                  <img
                                    src={additionToPPE}
                                    className="content__logo"
                                    alt="addition to ppe svg icon"
                                  />
                                  <p>
                                    {translations.topNav.dropDown.additionPPE}
                                  </p>
                                </div>
                              </div>

                              <div className="content__ul content__ul--4 content__dropdown">
                                <div className="content__li">
                                  {/* DETAILED 1 */}
                                  <div className="container__ul">
                                    <div className="container__li">
                                      <div className="cursor">
                                        <div className="content__links content__links__sub">
                                          <img
                                            src={detailed}
                                            className="content__logo"
                                            alt="detailed svg icon"
                                          />
                                          <p>
                                            {
                                              translations.topNav.dropDown
                                                .detailed
                                            }
                                          </p>
                                        </div>
                                      </div>

                                      <div className="content__ul content__ul--5 content__dropdown">
                                        <div className="content__li">
                                          {/* PDF */}
                                          <div
                                            className="content__li"
                                            onClick={() => {
                                              processReports(
                                                envConfig.api.reports
                                                  .additionToPPE,
                                                envConfig.reports.additionToPPE,
                                                envConfig.reports.format.PDF,
                                                envConfig.reports.type.detailed
                                              );
                                            }}
                                          >
                                            <div className="content__links">
                                              <img
                                                src={pdf}
                                                className="content__logo report__visible__none9999"
                                                alt="Dashboard svg icon"
                                              />
                                              <p>
                                                {
                                                  translations.topNav.dropDown
                                                    .pdf
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          {/* EXCEL */}
                                          <div
                                            className="content__li"
                                            onClick={() => {
                                              processReports(
                                                envConfig.api.reports
                                                  .additionToPPE,
                                                envConfig.reports.additionToPPE,
                                                envConfig.reports.format.XLS,
                                                envConfig.reports.type.detailed
                                              );
                                            }}
                                          >
                                            <div className="content__links">
                                              <img
                                                src={xel}
                                                className="content__logo report__visible__none9999"
                                                alt="Dashboard svg icon"
                                              />
                                              <p>
                                                {
                                                  translations.topNav.dropDown
                                                    .excel
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  {/* ## DETAILED 1 */}
                                  {/* SUMMARY 2 */}
                                  <div className="container__ul">
                                    <div className="container__li">
                                      <div className="cursor">
                                        <div className="content__links content__links__sub">
                                          <img
                                            src={summary}
                                            className="content__logo"
                                            alt="summary svg icon"
                                          />
                                          <p>
                                            {
                                              translations.topNav.dropDown
                                                .summary
                                            }
                                          </p>
                                        </div>
                                      </div>

                                      <div className="content__ul content__ul--5 content__dropdown">
                                        <div className="content__li">
                                          {/* PDF */}
                                          <div
                                            className="content__li"
                                            onClick={() => {
                                              processReports(
                                                envConfig.api.reports
                                                  .additionToPPE,
                                                envConfig.reports.additionToPPE,
                                                envConfig.reports.format.PDF,
                                                envConfig.reports.type.summary
                                              );
                                            }}
                                          >
                                            <div className="content__links">
                                              <img
                                                src={pdf}
                                                className="content__logo report__visible__none9999"
                                                alt="Dashboard svg icon"
                                              />
                                              <p>
                                                {
                                                  translations.topNav.dropDown
                                                    .pdf
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          {/* EXCEL */}
                                          <div
                                            className="content__li"
                                            onClick={() => {
                                              processReports(
                                                envConfig.api.reports
                                                  .additionToPPE,
                                                envConfig.reports.additionToPPE,
                                                envConfig.reports.format.XLS,
                                                envConfig.reports.type.summary
                                              );
                                            }}
                                          >
                                            <div className="content__links">
                                              <img
                                                src={xel}
                                                className="content__logo report__visible__none9999"
                                                alt="Dashboard svg icon"
                                              />
                                              <p>
                                                {
                                                  translations.topNav.dropDown
                                                    .excel
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* ## SUMMARY 2 */}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {/* ### Addition to PPE */}
                      </div>
                    </div>
                  </div>
                </div>
                {(params.class_id || true) && (
                  <>
                    {/* DETAILED  */}
                    <div className="container__ul">
                      <div className="container__li">
                        <div className="cursor">
                          <div className="content__links content__links__sub">
                            <img
                              src={detailed}
                              className="content__logo"
                              alt="detailed svg icon"
                            />
                            <p>{translations.topNav.dropDown.detailed}</p>
                          </div>
                        </div>

                        <div className="content__ul content__ul--3 content__dropdown">
                          <div className="content__li">
                            {/* All Items */}
                            <div className="container__ul">
                              <div className="container__li">
                                <div className="cursor">
                                  <div className="content__links content__links__sub">
                                    <img
                                      src={allItems}
                                      className="content__logo"
                                      alt="all items svg icon"
                                    />
                                    <p>
                                      {translations.topNav.dropDown.allItems}
                                    </p>
                                  </div>
                                </div>

                                <div className="content__ul content__ul--6 content__dropdown">
                                  <div className="content__li">
                                    {/* PDF */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.allItems,
                                          envConfig.reports.allItems,
                                          envConfig.reports.format.PDF,
                                          envConfig.reports.type.detailed
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={pdf}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.pdf}
                                        </p>
                                      </div>
                                    </div>
                                    {/* EXCEL */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.allItems,
                                          envConfig.reports.allItems,
                                          envConfig.reports.format.XLS,
                                          envConfig.reports.type.detailed
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={xel}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.excel}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ### All Items */}
                            {/* Fully Working Item */}
                            <div className="container__ul">
                              <div className="container__li">
                                <div className="cursor">
                                  <div className="content__links content__links__sub">
                                    <img
                                      src={fullyWorkingItems}
                                      className="content__logo"
                                      alt="fully working items svg icon"
                                    />
                                    <p>
                                      {
                                        translations.topNav.dropDown
                                          .fullyWorking
                                      }
                                    </p>
                                  </div>
                                </div>

                                <div className="content__ul content__ul--6 content__dropdown">
                                  <div className="content__li">
                                    {/* PDF */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.workingItems,
                                          envConfig.reports.workingItems,
                                          envConfig.reports.format.PDF,
                                          envConfig.reports.type.detailed
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={pdf}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.pdf}
                                        </p>
                                      </div>
                                    </div>
                                    {/* EXCEL */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.workingItems,
                                          envConfig.reports.workingItems,
                                          envConfig.reports.format.XLS,
                                          envConfig.reports.type.detailed
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={xel}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.excel}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ### Fully Working Item */}
                            {/* Non Working Items */}
                            <div className="container__ul">
                              <div className="container__li">
                                <div className="cursor">
                                  <div className="content__links content__links__sub">
                                    <img
                                      src={nonWorkingItems}
                                      className="content__logo"
                                      alt="non working items svg icon"
                                    />
                                    <p>
                                      {translations.topNav.dropDown.nonWorking}
                                    </p>
                                  </div>
                                </div>

                                <div className="content__ul content__ul--6 content__dropdown">
                                  <div className="content__li">
                                    {/* PDF */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.nonWorkingItems,
                                          envConfig.reports.nonWorkingItems,
                                          envConfig.reports.format.PDF,
                                          envConfig.reports.type.detailed
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={pdf}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.pdf}
                                        </p>
                                      </div>
                                    </div>
                                    {/* EXCEL */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.nonWorkingItems,
                                          envConfig.reports.nonWorkingItems,
                                          envConfig.reports.format.XLS,
                                          envConfig.reports.type.detailed
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={xel}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.excel}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ### Non Working Items */}
                            {/* Fully Depreciated and Working */}
                            <div className="container__ul">
                              <div className="container__li">
                                <div className="cursor">
                                  <div className="content__links content__links__sub">
                                    <img
                                      src={fullyDepreciated}
                                      className="content__logo"
                                      alt="fully depreciated svg icon"
                                    />
                                    <p>
                                      {
                                        translations.topNav.dropDown
                                          .depreciatedWorking
                                      }
                                    </p>
                                  </div>
                                </div>

                                <div className="content__ul content__ul--6 content__dropdown">
                                  <div className="content__li">
                                    {/* PDF */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports
                                            .depreciatedItems,
                                          envConfig.reports.depreciatedItems,
                                          envConfig.reports.format.PDF,
                                          envConfig.reports.type.detailed
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={pdf}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.pdf}
                                        </p>
                                      </div>
                                    </div>
                                    {/* EXCEL */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports
                                            .depreciatedItems,
                                          envConfig.reports.depreciatedItems,
                                          envConfig.reports.format.XLS,
                                          envConfig.reports.type.detailed
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={xel}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.excel}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ### Fully Depreciated and Working */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  SUMMARY */}
                    <div className="container__ul">
                      <div className="container__li">
                        <div className="cursor">
                          <div className="content__links content__links__sub">
                            <img
                              src={summary}
                              className="content__logo"
                              alt="Dashboard svg icon"
                            />
                            <p>{translations.topNav.dropDown.summary}</p>
                          </div>
                        </div>

                        <div className="content__ul content__ul--3 content__dropdown">
                          <div className="content__li">
                            {/* All Items */}
                            <div className="container__ul">
                              <div className="container__li">
                                <div className="cursor">
                                  <div className="content__links content__links__sub">
                                    <img
                                      src={allItems}
                                      className="content__logo"
                                      alt="all items svg icon"
                                    />
                                    <p>
                                      {translations.topNav.dropDown.allItems}
                                    </p>
                                  </div>
                                </div>

                                <div className="content__ul content__ul--6 content__dropdown">
                                  <div className="content__li">
                                    {/* PDF */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.allItems,
                                          envConfig.reports.allItems,
                                          envConfig.reports.format.PDF,
                                          envConfig.reports.type.summary
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={pdf}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.pdf}
                                        </p>
                                      </div>
                                    </div>
                                    {/* EXCEL */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.allItems,
                                          envConfig.reports.allItems,
                                          envConfig.reports.format.XLS,
                                          envConfig.reports.type.summary
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={xel}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.excel}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ### All Items */}
                            {/* Fully Working Item */}
                            <div className="container__ul">
                              <div className="container__li">
                                <div className="cursor">
                                  <div className="content__links content__links__sub">
                                    <img
                                      src={fullyWorkingItems}
                                      className="content__logo"
                                      alt="fully working items svg icon"
                                    />
                                    <p>
                                      {
                                        translations.topNav.dropDown
                                          .fullyWorking
                                      }
                                    </p>
                                  </div>
                                </div>

                                <div className="content__ul content__ul--6 content__dropdown">
                                  <div className="content__li">
                                    {/* PDF */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.workingItems,
                                          envConfig.reports.workingItems,
                                          envConfig.reports.format.PDF,
                                          envConfig.reports.type.summary
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={pdf}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.pdf}
                                        </p>
                                      </div>
                                    </div>
                                    {/* EXCEL */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.workingItems,
                                          envConfig.reports.workingItems,
                                          envConfig.reports.format.XLS,
                                          envConfig.reports.type.summary
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={xel}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.excel}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ### Fully Working Item */}
                            {/* Non Working Items */}
                            <div className="container__ul">
                              <div className="container__li">
                                <div className="cursor">
                                  <div className="content__links content__links__sub">
                                    <img
                                      src={nonWorkingItems}
                                      className="content__logo"
                                      alt="non working items svg icon"
                                    />
                                    <p>
                                      {translations.topNav.dropDown.nonWorking}
                                    </p>
                                  </div>
                                </div>

                                <div className="content__ul content__ul--6 content__dropdown">
                                  <div className="content__li">
                                    {/* PDF */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.nonWorkingItems,
                                          envConfig.reports.nonWorkingItems,
                                          envConfig.reports.format.PDF,
                                          envConfig.reports.type.summary
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={pdf}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.pdf}
                                        </p>
                                      </div>
                                    </div>
                                    {/* EXCEL */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports.nonWorkingItems,
                                          envConfig.reports.nonWorkingItems,
                                          envConfig.reports.format.XLS,
                                          envConfig.reports.type.summary
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={xel}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.excel}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Fully Depreciated and Working */}
                            <div className="container__ul">
                              <div className="container__li">
                                <div className="cursor">
                                  <div className="content__links content__links__sub">
                                    <img
                                      src={fullyDepreciated}
                                      className="content__logo"
                                      alt="fully depreciated svg icon"
                                    />
                                    <p>
                                      {
                                        translations.topNav.dropDown
                                          .depreciatedWorking
                                      }
                                    </p>
                                  </div>
                                </div>

                                <div className="content__ul content__ul--6 content__dropdown">
                                  <div className="content__li">
                                    {/* PDF */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports
                                            .depreciatedItems,
                                          envConfig.reports.depreciatedItems,
                                          envConfig.reports.format.PDF,
                                          envConfig.reports.type.summary
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={pdf}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.pdf}
                                        </p>
                                      </div>
                                    </div>
                                    {/* EXCEL */}
                                    <div
                                      className="content__li"
                                      onClick={() => {
                                        processReports(
                                          envConfig.api.reports
                                            .depreciatedItems,
                                          envConfig.reports.depreciatedItems,
                                          envConfig.reports.format.XLS,
                                          envConfig.reports.type.summary
                                        );
                                      }}
                                    >
                                      <div className="content__links">
                                        <img
                                          src={xel}
                                          className="content__logo report__visible__none9999"
                                          alt="Dashboard svg icon"
                                        />
                                        <p>
                                          {translations.topNav.dropDown.excel}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Profile */}
          <div className="container__ul topnav__ul cursor bp__medium__none">
            <div className="container__li">
              <div
                className={`cursor topnav__dropdown ${
                  userData.using_key === true ? "topnav__borderRight" : ""
                }`}
              >
                <p className="cap bp__smallLarge__none">{userData.name}</p>
                <img
                  src={downArrow}
                  className="dropdown__icon"
                  alt="up arrow of profile icon"
                />
              </div>
              <div className="content__ul content__dropdown">
                <div className="content__li">
                  <div className="container__ul">
                    <div className="container__li">
                      <div className="cursor">
                        <div className="content__links content__links__sub">
                          <img
                            src={archive}
                            className="content__logo"
                            alt="Archive svg icon"
                          />
                          <p>Archive</p>
                        </div>
                      </div>
                      <div
                        className={`content__ul content__dropdown ${
                          !isReadOnly ? "content__ul--2" : "content__ul--acc"
                        }`}
                      >
                        <div className="content__li">
                          {userData.classes.map((classData, index) => {
                            let classTag;
                            switch (classData.default_class_id) {
                              case 1:
                                classTag = (
                                  <Land className={`sidenav__svgBlue`} />
                                );
                                break;
                              case 2:
                                classTag = (
                                  <Buildings className={`sidenav__svgBlue`} />
                                );
                                break;
                              case 3:
                                classTag = (
                                  <Computers className={`sidenav__svgBlue`} />
                                );
                                break;
                              case 4:
                                classTag = (
                                  <Office className={`sidenav__svgBlue`} />
                                );
                                break;
                              case 5:
                                classTag = (
                                  <Infrastructure
                                    className={`sidenav__svgBlue`}
                                  />
                                );
                                break;
                              case 6:
                                classTag = (
                                  <Motor className={`sidenav__svgBlue`} />
                                );
                                break;
                              case 7:
                                classTag = (
                                  <Plant className={`sidenav__svgBlue`} />
                                );
                                break;
                              case 8:
                                classTag = (
                                  <Heritage className={`sidenav__svgBlue`} />
                                );
                                break;
                              case 9:
                                classTag = (
                                  <Biological className={`sidenav__svgBlue`} />
                                );
                                break;
                              case 10:
                                classTag = (
                                  <Other className={`sidenav__svgBlue`} />
                                );
                                break;
                              default:
                                classTag = <></>;
                                break;
                            }
                            return (
                              <Link
                                key={index}
                                to={`/app/archive-assets/${classData.name}/${classData.id}`}
                                replace
                                className={`sidenav__liBlue sidenav__liBlue--2`}
                              >
                                {classTag}
                                <p className="c__black cps">{classData.name}</p>
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!isReadOnly && (
                    <Link to="/app/accountants" className="content__links">
                      <img
                        src={account}
                        className="content__logo"
                        alt="Accountant Management svg icon"
                      />
                      <p>{translations.topNav.heading.accountant}</p>
                    </Link>
                  )}
                  {!userData.organization.is_subsidiary && !isReadOnly && (
                    <Link to="/app/subsidiaries" className="content__links">
                      <img
                        src={account}
                        className="content__logo"
                        alt="Accountant Management svg icon"
                      />
                      <p>{translations.topNav.heading.subsidiary}</p>
                    </Link>
                  )}
                  <Link to="/app/account-profile" className="content__links">
                    <img
                      src={profile}
                      className="content__logo"
                      alt="Profile svg icon"
                    />
                    <p>{translations.topNav.heading.profile}</p>
                  </Link>
                  <Link to="/app/company-profile" className="content__links">
                    <img
                      src={companyProfile}
                      className="content__logo"
                      alt="Profile svg icon"
                    />
                    <p>{translations.topNav.heading.company}</p>
                  </Link>
                  <Link to="/app/contact" className="content__links">
                    <img
                      src={phone}
                      className="content__logo"
                      alt="Contact Us svg icon"
                    />
                    <p>{translations.topNav.heading.contact}</p>
                  </Link>
                  <div
                    onClick={() => {
                      window
                        .open(
                          envConfig.api.baseURL + envConfig.api.helpURL,
                          "_blank"
                        )
                        .focus();
                    }}
                    className="content__links"
                  >
                    <img
                      src={help}
                      className="content__logo"
                      alt="Help svg icon"
                    />
                    <p>Help</p>
                  </div>
                  <div
                    onClick={() => {
                      window
                        .open(
                          envConfig.api.baseURL + envConfig.api.termsURL,
                          "_blank"
                        )
                        .focus();
                    }}
                    className="content__links"
                  >
                    <img
                      src={terms}
                      className="content__logo"
                      alt="Help svg icon"
                    />
                    <p>Terms and Conditions</p>
                  </div>
                  <div
                    className="content__links"
                    onClick={() => {
                      resetUser();
                    }}
                  >
                    <img
                      src={logooutNav}
                      className="content__logo"
                      alt="message svg icon"
                    />
                    <p>Logout</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {userData.using_key && (
            <div
              className="tooltip mr-s-sm"
              onClick={() => {
                backToAdmin(resetUser, dispatch, navigate);
              }}
            >
              <span className="tooltiptext cap">Back to Admin</span>
              <img
                className="listing__svg cursor"
                src={logoutPower}
                alt="logout or back to admin"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslations(state),
    userData: getUserData(state),
  }),
  { resetUser, setLoading }
)(TopNav);
