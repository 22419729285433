import { connect } from "react-redux";
import { logoMobile } from "../config/assets";
import { resetUser } from "../redux/features/user";
import { getTranslations, getUserData } from "../redux/selector";

const MobileSetupTopBar = ({ userData, resetUser, translations }) => {
  return (
    <div className="record__back subnav subnav__mobile">
      <div className="subnav__logo-link cursor">
        <img src={logoMobile} alt="back arrow with logo and name" />
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    userData: getUserData(state),
  }),
  { resetUser }
)(MobileSetupTopBar);
