import "./App.scss";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routing from "./routes";
import { BrowserRouter } from "react-router-dom";
import models from "./config/model";
import validators from "./config/validators";

const App = () => {
  if (
    window.location.pathname.match(validators.page) &&
    models.redirect_url === ""
  )
    models.redirect_url = window.location.pathname;
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
