import { createSlice } from "@reduxjs/toolkit";
import envConfig from "../../config/constants";
import models from "../../config/model";
import {
  accessSubsidiary,
  createAccountant,
  createSubsidiary,
  forgotUser,
  forgotVerifyUser,
  getAccountants,
  getSubsidiaries,
  loginAccessUser,
  loginUser,
  registerUser,
  registerVerifyUser,
  selfUser,
  updateAccountant,
  updateSelfUser,
  updateSubsidiary,
} from "../api";

const userSlice = createSlice({
  name: "user",
  initialState: {
    authKey: null,
    userData: null,
    registerData: {
      name: "",
      email: "",
      phone: "",
      password: "",
      reporting_framework: "",
    },
    forgotData: { email: "" },
    accountants: [],
    subsidiaries: [],
  },
  reducers: {
    resetUser: (state) => {
      models.redirect_url = "";
      localStorage.clear();
      state.authKey = null;
      state.userData = null;
      state.registerData = {
        name: "",
        email: "",
        phone: "",
        password: "",
        reporting_framework: "",
      };
      state.forgotData = { email: "" };
    },
    setUser: (state, data) => {
      state.authKey = data.payload.auth_key;
      state.registerData = {
        name: "",
        email: "",
        phone: "",
        password: "",
        reporting_framework: "",
      };
      state.forgotData = { email: "" };
    },
    setRegisterUser: (state, data) => {
      state.registerData = data.payload;
    },
    setForgotUser: (state, data) => {
      state.forgotData = data.payload;
    },
    setMessageUser: (state, data) => {
      state.notificationMessage = {
        message: data.payload.message,
        type: data.payload.type ?? envConfig.notificationTypes.warning,
      };
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          state.authKey = action.payload.data.auth_key;
        } else
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.danger,
          };
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAccessUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          state.authKey = action.payload.data.auth_key;
        } else
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.danger,
          };
      })
      .addCase(loginAccessUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(selfUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) state.userData = action.payload.data;
        else {
          userSlice.caseReducers.resetUser(state);
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.danger,
          };
        }
      })
      .addCase(selfUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.success,
          };
        else
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.danger,
          };
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerVerifyUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.success,
          };
        else
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.danger,
          };
      })
      .addCase(registerVerifyUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.success,
          };
        else
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.danger,
          };
      })
      .addCase(forgotUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotVerifyUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.success,
          };
        else
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.danger,
          };
      })
      .addCase(forgotVerifyUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSelfUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.success,
          };
        else
          state.notificationMessage = {
            message: action.payload.message,
            type: envConfig.notificationTypes.danger,
          };
      })
      .addCase(updateSelfUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAccountant.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createAccountant.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccountants.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.accountants = action.payload.data;
        else state.accountants = [];
      })
      .addCase(getAccountants.pending, (state) => {
        state.loading = true;
        state.accountants = [];
      })
      .addCase(updateAccountant.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateAccountant.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSubsidiary.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSubsidiary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubsidiaries.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200)
          state.subsidiaries = action.payload.data;
        else state.subsidiaries = [];
      })
      .addCase(getSubsidiaries.pending, (state) => {
        state.loading = true;
        state.accountants = [];
      })
      .addCase(updateSubsidiary.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateSubsidiary.pending, (state) => {
        state.loading = true;
      })
      .addCase(accessSubsidiary.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(accessSubsidiary.pending, (state) => {
        state.loading = true;
      });
  },
});

export const {
  resetUser,
  setUser,
  setRegisterUser,
  setForgotUser,
  setMessageUser,
} = userSlice.actions;

export default userSlice.reducer;
