import { connect, useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import ScreenLoader from "../components/screenLoader";
import envConfig from "../config/constants";
import Login from "../pages/Auth/Login";
import Dashboard from "../pages/Dashboard";
import {
  getAuthKey,
  getLoading,
  getNotificationMessage,
} from "../redux/selector";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { setRegisterUser, setUser } from "../redux/features/user";
import { selfUser } from "../redux/api";
import Notification from "../components/notification";
import Register from "../pages/Auth/Register";
import RegisterFramework from "../pages/Auth/RegisterFramework";
import RegisterVerify from "../pages/Auth/RegisterVerify";
import Info from "../pages/Auth/Info";
import Forgot from "../pages/Auth/Forgot";
import ForgotVerify from "../pages/Auth/ForgotVerify";
import SetupBasic from "../pages/SetupBasic";
import SetupDepreciation from "../pages/SetupDepreciation";
import SetupClass from "../pages/SetupClass";
import SetupColumn from "../pages/SetupColumn";
import DeferredTax from "../pages/DeferredTax";
import Assets from "../pages/Assets";
import AssetDetail from "../pages/AssetDetail";
import AssetNew from "../pages/AssetNew";
import DeferredTaxReport from "../pages/DeferredTaxReport";
import InvestmentModel from "../pages/InvestmentModel";
import InvestmentModelReport from "../pages/InvestmentModelReport";
import EditColumns from "../pages/EditColumns";
import ArchiveAssets from "../pages/ArchiveAssets";
import ArchiveAssetDetail from "../pages/ArchiveAssetDetail";
import Accountants from "../pages/Accountants";
import AccountantNew from "../pages/AccountantNew";
import AccountantEdit from "../pages/AccountantEdit";
import Subsidiaries from "../pages/Subsidiaries";
import SubsidiaryNew from "../pages/SubsidiaryNew";
import SubsidiaryEdit from "../pages/SubsidiaryEdit";
import Account from "../pages/Account";
import CompanyProfile from "../pages/CompanyProfile";
import EditClasses from "../pages/EditClasses";
import CompanyProfileEdit from "../pages/CompanyProfileEdit";
import Contact from "../pages/Contact";
import { useEffect } from "react";

const Routing = (props) => {
  const dispatch = useDispatch();
  const authKey = props.authKey;
  const setUser = props.setUser;
  const setRegisterUser = props.setRegisterUser;
  useEffect(() => {
    let registerData = null;
    try {
      registerData = JSON.parse(
        atob(localStorage.getItem(envConfig.storageKeys.registerKey))
      );
      if (registerData != null) setRegisterUser(registerData);
    } catch (e) {
      if (envConfig.isDev) console.log(e);
    }
    try {
      const userData = JSON.parse(
        atob(localStorage.getItem(envConfig.storageKeys.authKey))
      );
      if (authKey == null && userData.auth_key != null) {
        setUser(userData);
        dispatch(selfUser({ authKey: userData.auth_key }));
      }
    } catch (e) {
      if (envConfig.isDev) console.log(e);
      const subsidiaryData = localStorage.getItem(
        envConfig.storageKeys.subsidiaryAuthKey
      );
      localStorage.clear();
      if (subsidiaryData)
        localStorage.setItem(
          envConfig.storageKeys.subsidiaryAuthKey,
          subsidiaryData
        );
      if (registerData != null)
        localStorage.setItem(
          envConfig.storageKeys.registerKey,
          btoa(JSON.stringify(registerData))
        );
    }
  }, [authKey, dispatch, setRegisterUser, setUser]);
  return (
    <>
      <Notification message={props.notificationMessage} />
      <Routes>
        <Route
          path={"/auth/register"}
          element={<PublicRoute component={Register} />}
        />
        <Route
          path={"/auth/register-framework"}
          element={<PublicRoute component={RegisterFramework} />}
        />
        <Route
          path={"/auth/register-verify"}
          element={<PublicRoute component={RegisterVerify} />}
        />
        <Route path={"/auth/info"} element={<PublicRoute component={Info} />} />
        <Route path={"/app/grant-access/:access_key"} element={<Info />} />
        <Route
          path={"/auth/forgot"}
          element={<PublicRoute component={Forgot} />}
        />
        <Route
          path={"/auth/forgot-verify"}
          element={<PublicRoute component={ForgotVerify} />}
        />
        <Route
          path={"/auth/login"}
          element={<PublicRoute component={Login} />}
        />
        <Route
          path={"/app/setup"}
          element={<PrivateRoute component={SetupBasic} type={"setup"} />}
        />
        <Route
          path={"/app/setup-depreciation"}
          element={
            <PrivateRoute
              component={SetupDepreciation}
              type={"setup-depreciation"}
            />
          }
        />
        <Route
          path={"/app/setup-class"}
          element={<PrivateRoute component={SetupClass} type={"setup-class"} />}
        />
        <Route
          path={"/app/setup-column"}
          element={
            <PrivateRoute component={SetupColumn} type={"setup-column"} />
          }
        />
        <Route path={"/app/deferred-tax"}>
          <Route path="" element={<PrivateRoute component={DeferredTax} />} />
          <Route
            path={":class_name/:class_id"}
            element={<PrivateRoute component={DeferredTax} />}
          />
          <Route
            path={":class_name/:class_id/:asset_id"}
            element={<PrivateRoute component={DeferredTax} />}
          />
        </Route>
        <Route
          path={"/app/deferred-tax-report"}
          element={<PrivateRoute component={DeferredTaxReport} />}
        />
        <Route path={"/app/investment-model"}>
          <Route
            path=""
            element={<PrivateRoute component={InvestmentModel} />}
          />
          <Route
            path={":class_name/:class_id"}
            element={<PrivateRoute component={InvestmentModel} />}
          />
          <Route
            path={":class_name/:class_id/:asset_id"}
            element={<PrivateRoute component={InvestmentModel} />}
          />
        </Route>
        <Route
          path={"/app/investment-model-report"}
          element={<PrivateRoute component={InvestmentModelReport} />}
        />
        <Route
          path="/app/assets/:class_name/:class_id"
          element={<PrivateRoute component={Assets} />}
        />
        <Route
          path={"/app/asset-add/:class_name/:class_id"}
          element={<PrivateRoute component={AssetNew} />}
        />
        <Route
          path={"/app/asset-detail/:class_name/:class_id/:asset_id"}
          element={<PrivateRoute component={AssetDetail} />}
        />
        <Route
          path="/app/edit-columns/:class_name/:class_id"
          element={<PrivateRoute component={EditColumns} />}
        />
        <Route
          path="/app/archive-assets/:class_name/:class_id"
          element={<PrivateRoute component={ArchiveAssets} />}
        />
        <Route
          path={"/app/archive-asset-detail/:class_name/:class_id/:asset_id"}
          element={<PrivateRoute component={ArchiveAssetDetail} />}
        />
        <Route
          path={"/app/accountants"}
          element={<PrivateRoute component={Accountants} />}
        />
        <Route
          path={"/app/accountant-add"}
          element={<PrivateRoute component={AccountantNew} />}
        />
        <Route
          path={"/app/accountant-edit"}
          element={<PrivateRoute component={AccountantEdit} />}
        />
        <Route
          path={"/app/subsidiaries"}
          element={<PrivateRoute component={Subsidiaries} />}
        />
        <Route
          path={"/app/subsidiary-add"}
          element={<PrivateRoute component={SubsidiaryNew} />}
        />
        <Route
          path={"/app/subsidiary-edit"}
          element={<PrivateRoute component={SubsidiaryEdit} />}
        />
        <Route
          path={"/app/account-profile"}
          element={<PrivateRoute component={Account} />}
        />
        <Route
          path={"/app/company-profile"}
          element={<PrivateRoute component={CompanyProfile} />}
        />
        <Route
          path={"/app/company-profile-edit"}
          element={<PrivateRoute component={CompanyProfileEdit} />}
        />
        <Route
          path={"/app/edit-classes"}
          element={<PrivateRoute component={EditClasses} />}
        />
        <Route
          path={"/app/contact"}
          element={<PrivateRoute component={Contact} />}
        />
        <Route
          path={"/app/dashboard"}
          element={<PrivateRoute component={Dashboard} />}
        />

        <Route path={"/app/*"} element={<Navigate to={"/app/dashboard"} />} />
        <Route path="/*" element={<Navigate to={"/auth/login"} />} />
      </Routes>
      {props.loading && <ScreenLoader />}
    </>
  );
};

export default connect(
  (state) => ({
    loading: getLoading(state),
    notificationMessage: getNotificationMessage(state),
    authKey: getAuthKey(state),
  }),
  { setUser, setRegisterUser }
)(Routing);
