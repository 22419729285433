import { connect } from "react-redux";
import { setRegisterUser } from "../../../redux/features/user";
import { getRegisterData, getTranslations } from "../../../redux/selector";
import { Button, Form, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  loginMain,
  logo,
  viewIcon,
  hideIcon,
  arrRight,
} from "../../../config/assets";
import { useState } from "react";
import validators from "../../../config/validators";
import envConfig from "../../../config/constants";

const Register = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [nameValid, setNameValid] = useState(false);
  const [nameMessage, setNameMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneMessage, setPhoneMessage] = useState("");
  const [password, setPassword] = useState("");
  const [isText, setIsText] = useState(false);
  const [unchanged, setUnchanged] = useState(true);

  const verifyInput = (type, value) => {
    setUnchanged(false);
    if (type !== "name") value = value.trim();
    switch (type) {
      case "name":
        setName(value);
        setNameValid(value.match(validators.name));
        setNameMessage(
          value === ""
            ? ""
            : value.match(validators.name)
            ? props.translations.registerPage.validation.nameVerify
            : props.translations.registerPage.validation.nameInvalid
        );
        break;
      case "email":
        setEmail(value);
        setEmailValid(value.match(validators.email));
        setEmailMessage(
          value === ""
            ? ""
            : value.match(validators.email)
            ? props.translations.registerPage.validation.emailVerify
            : props.translations.registerPage.validation.emailInvalid
        );
        break;
      case "phone":
        setPhone(value);
        setPhoneValid(value.match(validators.phone));
        setPhoneMessage(
          value === ""
            ? ""
            : value.match(validators.phone)
            ? props.translations.registerPage.validation.phoneVerify
            : props.translations.registerPage.validation.phoneInvalid
        );
        break;
      case "password":
        setPassword(value);
        break;
      case "submit":
        if (nameValid && phoneValid && emailValid) {
          localStorage.setItem(
            envConfig.storageKeys.registerKey,
            btoa(
              JSON.stringify({
                name: name.trim(),
                email: email.trim(),
                phone: phone.trim(),
                password: "",
                reporting_framework: "",
              })
            )
          );
          props.setRegisterUser({
            name: name.trim(),
            email: email.trim(),
            phone: phone.trim(),
            password: password.trim(),
            reporting_framework: "",
          });
          navigate("/auth/register-framework");
        }
        break;
      default:
        break;
    }
  };

  if (
    unchanged &&
    name === "" &&
    props.userData.name &&
    props.userData.name !== ""
  )
    verifyInput("name", props.userData.name);
  if (
    unchanged &&
    email === "" &&
    props.userData.email &&
    props.userData.email !== ""
  )
    verifyInput("email", props.userData.email);
  if (
    unchanged &&
    phone === "" &&
    props.userData.phone &&
    props.userData.phone !== ""
  )
    verifyInput("phone", props.userData.phone);

  return (
    <>
      <div className="register__story">
        <img className="register__img" src={loginMain} alt="" />
        <div className="register__text">
          <p className="register__text--size">
            {props.translations.registerPage.advertise.message}
          </p>
          <img
            className="register__img--2"
            src={logo}
            alt={`${props.translations.registerPage.alt.login}`}
          />
        </div>
      </div>
      <div className="register__content">
        <Form
          className="register__box"
          onSubmit={(e) => {
            e.preventDefault();
            verifyInput("submit", "");
          }}
        >
          <h2 className="heading-1 mb-sm">
            {props.translations.registerPage.title.h2}
          </h2>
          <p className="mb-sm">{props.translations.registerPage.title.para}</p>
          <div className="mb-sm mt-s-sm">
            <label htmlFor="name" className="form__label " aria-hidden="true">
              {props.translations.registerPage.form.name.label}
            </label>
            <Input
              className="form__input register__w32"
              type={`${props.translations.registerPage.form.name.type}`}
              name={`${props.translations.registerPage.form.name.name}`}
              autoFocus
              minLength={`${props.translations.registerPage.form.name.minLength}`}
              maxLength={`${props.translations.registerPage.form.name.maxLength}`}
              aria-describedby={`${props.translations.registerPage.form.name.placeholder}`}
              placeholder={`${props.translations.registerPage.form.name.placeholder}`}
              value={name}
              onChange={(e) => verifyInput("name", e.target.value)}
              required
            />
            <span
              className={`mt-03-sm register__err form__label--${
                nameValid ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {nameMessage}
            </span>
          </div>
          <div className="mb-sm">
            <label htmlFor="phone" className="form__label " aria-hidden="true">
              {props.translations.registerPage.form.phone.label}
            </label>
            <Input
              className="form__input register__w32"
              name={`${props.translations.registerPage.form.phone.name}`}
              minLength={`${props.translations.registerPage.form.phone.minLength}`}
              maxLength={`${props.translations.registerPage.form.phone.maxLength}`}
              aria-describedby={`${props.translations.registerPage.form.phone.placeholder}`}
              placeholder={`${props.translations.registerPage.form.phone.placeholder}`}
              value={phone}
              onChange={(e) => verifyInput("phone", e.target.value)}
              required
            />
            <span
              className={`mt-03-sm register__err form__label--${
                phoneValid ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {phoneMessage}
            </span>
          </div>
          <div className="mb-sm">
            <label htmlFor="email" className="form__label " aria-hidden="true">
              {props.translations.registerPage.form.email.label}
            </label>
            <Input
              className="form__input register__w32"
              type={`${props.translations.registerPage.form.email.name}`}
              name={`${props.translations.registerPage.form.email.name}`}
              minLength={`${props.translations.registerPage.form.email.minLength}`}
              maxLength={`${props.translations.registerPage.form.email.maxLength}`}
              aria-describedby={`${props.translations.registerPage.form.email.placeholder}`}
              placeholder={`${props.translations.registerPage.form.email.placeholder}`}
              value={email}
              onChange={(e) => verifyInput("email", e.target.value)}
              required
            />
            <span
              className={`mt-03-sm register__err form__label--${
                emailValid ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {emailMessage}
            </span>
          </div>

          <div className="mb-md">
            <label
              htmlFor="password"
              className="form__label "
              aria-hidden="true"
            >
              {props.translations.registerPage.form.password.label}
            </label>
            <div className="inputs__icons">
              <Input
                className="form__input register__w32"
                type={isText ? "text" : "password"}
                name={`${props.translations.registerPage.form.password.name}`}
                minLength={`${props.translations.registerPage.form.password.minLength}`}
                maxLength={`${props.translations.registerPage.form.password.maxLength}`}
                aria-describedby={`${props.translations.registerPage.form.password.placeholder}`}
                placeholder={`${props.translations.registerPage.form.password.placeholder}`}
                value={password}
                onChange={(e) => verifyInput("password", e.target.value)}
                required
              />
              <span
                className="inputs__svg cursor"
                onClick={() => {
                  setIsText(!isText);
                }}
              >
                {isText ? (
                  <img
                    src={hideIcon}
                    alt={`${props.translations.registerPage.alt.hide}`}
                  />
                ) : (
                  <img
                    src={viewIcon}
                    alt={`${props.translations.registerPage.alt.view}`}
                  />
                )}
              </span>
            </div>
          </div>

          <Button className="btn btn-svg register__btn" type="submit">
            {props.translations.registerPage.button.submit}
            <img src={arrRight} alt="" />
          </Button>
          <p className="mt-sm">
            <Link to="/auth/login" className="register__link">
              {props.translations.registerPage.redirect.link}
            </Link>
          </p>
          <p className="mt-sm">
            <Link
              onClick={() => {
                window
                  .open(
                    envConfig.api.baseURL + envConfig.api.termsURL,
                    "_blank"
                  )
                  .focus();
              }}
              className="register__link small_font"
            >
              By using <b>Virgilproper</b>, you agree to our{" "}
              <u>Terms and Conditions</u>
            </Link>
          </p>
        </Form>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    userData: getRegisterData(state),
  }),
  { setRegisterUser }
)(Register);
