import { connect, useDispatch } from "react-redux";
import { setMessageUser, setRegisterUser } from "../../../redux/features/user";
import { getRegisterData, getTranslations } from "../../../redux/selector";
import { Button, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  loginMain,
  logo,
  arrRight,
  financials,
  tick,
} from "../../../config/assets";
import { useEffect, useState } from "react";
import envConfig from "../../../config/constants";
import { registerUser } from "../../../redux/api";

const RegisterFramework = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [framework, setFramework] = useState("");

  useEffect(() => {
    if (
      props.userData.name === "" ||
      props.userData.phone === "" ||
      props.userData.email === "" ||
      props.userData.password === ""
    )
      navigate("/auth/register");
  }, [navigate, props.userData]);

  return (
    <>
      <div className="financial__story">
        <img
          className="financial__img"
          src={loginMain}
          alt={`${props.translations.registerFrameworkPage.alt.framework}`}
        />
        <div className="financial__text">
          <p className="financial__text--size">
            {props.translations.registerFrameworkPage.advertise.message}
          </p>
          <img
            className="financial__img--2"
            src={logo}
            alt={`${props.translations.registerFrameworkPage.alt.logo}`}
          />
        </div>
      </div>
      <div className="financial__content">
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            if (framework === "")
              props.setMessageUser({
                message:
                  props.translations.registerFrameworkPage.validation.framework,
              });
            else {
              const response = await dispatch(
                registerUser(
                  JSON.stringify({
                    name: props.userData.name,
                    email: props.userData.email,
                    phone: props.userData.phone,
                    password: props.userData.password,
                    reporting_framework: framework,
                  })
                )
              );
              if (response.payload.status === 200) {
                props.setRegisterUser({
                  name: props.userData.name,
                  email: props.userData.email,
                  phone: props.userData.phone,
                  password: props.userData.password,
                  reporting_framework: framework,
                });
                navigate("/auth/register-verify");
              }
            }
          }}
        >
          <div className="financial__box">
            <h2 className="heading-1 mb-s-sm">
              {props.translations.registerFrameworkPage.title.h2}
            </h2>
            <div className="mb-sm">
              <p>{props.translations.registerFrameworkPage.title.para}</p>
              <Link to={-1} className="font-12 c__white border__bottom">
                {props.translations.registerFrameworkPage.redirect.link}
              </Link>
            </div>
            {envConfig.reportingItems.map((item, index) => {
              return (
                <label
                  className="checked__label"
                  htmlFor={item.id}
                  key={item.id}
                  onClick={() => {
                    setFramework(item.name);
                  }}
                >
                  <div className="fin__links mt-sm" key={index}>
                    <div className="fin__tics">
                      <img
                        className={` fin__tic${
                          item.name === framework ? "--display" : ""
                        }`}
                        src={tick}
                        alt={`${props.translations.registerFrameworkPage.alt.selectTic}`}
                      />
                    </div>
                    <img
                      className="fin__svgs"
                      src={financials[index]}
                      alt={`Financial framework ${item.name} svg icon`}
                    />
                    <span>{item.label}</span>
                  </div>
                </label>
              );
            })}
            <Button className="btn btn-svg financial__btn mt-sm" type="submit">
              {props.translations.registerFrameworkPage.button.submit}
              <img src={arrRight} alt="" />
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    userData: getRegisterData(state),
  }),
  { setMessageUser, setRegisterUser }
)(RegisterFramework);
