import { Column, Line, measureTextWidth, Pie } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import {
  accountDashboard,
  nonWorkingAsset,
  workingAsset,
} from "../../config/assets";
import envConfig from "../../config/constants";
import models from "../../config/model";
import { textAmountFormat } from "../../config/utility";
import { fetchDashboard } from "../../redux/api";
import {
  getAuthKey,
  getDashboardData,
  getUserData,
} from "../../redux/selector";

const checkRedirect = (navigate) => {
  if (models.redirect_url !== "") {
    const redirectUrl = models.redirect_url;
    models.redirect_url = "";
    return navigate(redirectUrl);
  }
};

const Dashboard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDashboard({ authKey: props.authKey }));
    checkRedirect(navigate);
  }, [dispatch, navigate, props.authKey]);

  const classTrend = {
    data: props.dashboardData.classTrends,
    xField: "month",
    yField: "assetCount",
    seriesField: "class",
    legend: {
      position: "right",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }

  const config = {
    appendPadding: 10,
    data: props.dashboardData.assetAllocationData.map(
      ({ class: classData, ...rest }) => ({
        ...rest,
        class: classData.toUpperCase(),
      })
    ),
    angleField: "assets",
    colorField: "class",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v}`,
      },
    },
    legend: {
      position: "right",
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "{value}",
    },
    statistic: {
      title: {
        offsetY: 4,
        style: {
          fontSize: "32px",
          fontWeight: "bold",
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `${datum[config.angleField]}`
            : `${data.reduce((r, d) => r + d[config.angleField], 0)}`; //value
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
      content: {
        offsetY: -4,
        style: {
          fontSize: "12px",
          marginTop: "20px",
        },
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum
            ? datum.class
            : "TOTAL " + config.angleField.toUpperCase();
          return renderStatistic(d, text, {
            fontSize: 28,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  const [classAnalysisClassSelection, setClassAnalysisClassSelection] =
    useState(-1);

  const [configClassAnalysis, setConfigClassAnalysis] = useState(
    Object.assign({}, config)
  );
  const [configBarClassAnalysis, setConfigBarClassAnalysis] = useState({
    data: [],
    xField: "field",
    yField: "value",
    seriesField: "field",
    legend: {
      position: "top-left",
    },
  });
  const [configLineClassAnalysis, setConfigLineClassAnalysis] = useState({
    data: [],
    xField: "year",
    yField: "value",
    seriesField: "field",
    legend: {
      position: "right",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  });

  const selectClassAnalysisClass = (default_class_id) => {
    setClassAnalysisClassSelection(default_class_id);
    setConfigClassAnalysis({
      ...configClassAnalysis,
      data: props.dashboardData.classAnalysis[default_class_id]
        ? [
            {
              field: envConfig.graphFilter.ACC_DEPRECIATION,
              value:
                props.dashboardData.classAnalysis[default_class_id]
                  .accumulatedDepreciation,
            },
            {
              field: envConfig.graphFilter.CARRYING_AMOUNT,
              value:
                props.dashboardData.classAnalysis[default_class_id]
                  .carryingAmount,
            },
            {
              field: envConfig.graphFilter.COST,
              value: props.dashboardData.classAnalysis[default_class_id].cost,
            },
            {
              field: envConfig.graphFilter.IMPAIRMENT,
              value:
                props.dashboardData.classAnalysis[default_class_id].impairment,
            },
          ]
        : [],
      angleField: "value",
      colorField: "field",
      statistic: {
        title: {
          offsetY: 4,
          style: {
            fontSize: "32px",
            fontWeight: "bold",
          },
          customHtml: (container, view, datum, data) => {
            const { width } = container.getBoundingClientRect();
            const text = datum
              ? `${datum["value"]}`
              : `${data.reduce((r, d) => r + d["value"], 0)}`; //value
            return renderStatistic(width, textAmountFormat(text), {
              fontSize: 32,
            });
          },
        },
        content: {
          offsetY: -4,
          style: {
            fontSize: "12px",
            marginTop: "20px",
          },
          customHtml: (container, view, datum) => {
            const { width, height } = container.getBoundingClientRect();
            const d = Math.sqrt(
              Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
            );
            const text = datum ? datum["field"] : "TOTAL VALUE";
            return renderStatistic(d, text, {
              fontSize: 28,
            });
          },
        },
      },
      meta: {
        value: {
          formatter: (v) => textAmountFormat(v),
        },
      },
      label: {
        type: "inner",
        autoRotate: false,
        formatter: ({ value }) => textAmountFormat(value),
      },
    });
    setConfigBarClassAnalysis({
      ...configBarClassAnalysis,
      data: props.dashboardData.classAnalysis[default_class_id]
        ? [
            {
              field: envConfig.graphFilter.ACC_DEPRECIATION,
              value:
                props.dashboardData.classAnalysis[default_class_id]
                  .accumulatedDepreciation,
            },
            {
              field: envConfig.graphFilter.CARRYING_AMOUNT,
              value:
                props.dashboardData.classAnalysis[default_class_id]
                  .carryingAmount,
            },
            {
              field: envConfig.graphFilter.COST,
              value: props.dashboardData.classAnalysis[default_class_id].cost,
            },
            {
              field: envConfig.graphFilter.IMPAIRMENT,
              value:
                props.dashboardData.classAnalysis[default_class_id].impairment,
            },
          ]
        : [],
      meta: {
        value: {
          formatter: (v) => textAmountFormat(v),
        },
      },
      label: {
        formatter: ({ value }) => textAmountFormat(value),
      },
    });
    setConfigLineClassAnalysis({
      ...configLineClassAnalysis,
      data: props.dashboardData.classAnalysis[default_class_id]
        ? props.dashboardData.classAnalysis[default_class_id].yearData
            .reduce(
              (
                acc,
                {
                  year,
                  accumulatedDepreciation,
                  carryingAmount,
                  cost,
                  impairment,
                }
              ) => {
                acc.push({
                  year,
                  value: accumulatedDepreciation,
                  field: envConfig.graphFilter.ACC_DEPRECIATION,
                });
                acc.push({
                  year,
                  value: carryingAmount,
                  field: envConfig.graphFilter.CARRYING_AMOUNT,
                });
                acc.push({
                  year,
                  value: cost,
                  field: envConfig.graphFilter.COST,
                });
                acc.push({
                  year,
                  value: impairment,
                  field: envConfig.graphFilter.IMPAIRMENT,
                });
                return acc;
              },
              []
            )
            .reverse()
        : [],
      meta: {
        value: {
          formatter: (v) => textAmountFormat(v),
        },
      },
      label: {
        formatter: ({ value }) => textAmountFormat(value),
      },
    });
  };

  const [
    comparativeClassAnalysisClassSelection,
    setComparativeClassAnalysisClassSelection,
  ] = useState([]);

  const [
    comparativeClassAnalysisFieldSelection,
    setComparativeClassAnalysisFieldSelection,
  ] = useState(Object.values(envConfig.graphFilter)[0]);

  const [configComparativeClassAnalysis, setConfigComparativeClassAnalysis] =
    useState(Object.assign({}, config));

  const [
    configComparativeBarClassAnalysis,
    setConfigComparativeBarClassAnalysis,
  ] = useState({
    data: [],
    xField: "field",
    yField: "value",
    seriesField: "field",
    legend: {
      position: "top-left",
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  });
  const [
    configComparativeLineClassAnalysis,
    setConfigComparativeLineClassAnalysis,
  ] = useState({
    data: [],
    xField: "year",
    yField: "value",
    seriesField: "field",
    legend: {
      position: "right",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  });

  const selectComparativeClassAnalysisClass = (
    classSelection,
    fieldSelection
  ) => {
    setConfigComparativeClassAnalysis({
      ...configComparativeClassAnalysis,
      data: props.dashboardData.classAnalysis
        ? classSelection.map((classId) => {
            const field = props.dashboardData.classAnalysis[classId]
              ? props.dashboardData.classAnalysis[classId].className
              : "";
            let value = 0;
            switch (fieldSelection) {
              case envConfig.graphFilter.ACC_DEPRECIATION:
                value = props.dashboardData.classAnalysis[classId]
                  ? props.dashboardData.classAnalysis[classId]
                      .accumulatedDepreciation
                  : 0;
                break;
              case envConfig.graphFilter.CARRYING_AMOUNT:
                value = props.dashboardData.classAnalysis[classId]
                  ? props.dashboardData.classAnalysis[classId].carryingAmount
                  : 0;
                break;
              case envConfig.graphFilter.COST:
                value = props.dashboardData.classAnalysis[classId]
                  ? props.dashboardData.classAnalysis[classId].cost
                  : 0;
                break;
              case envConfig.graphFilter.IMPAIRMENT:
                value = props.dashboardData.classAnalysis[classId]
                  ? props.dashboardData.classAnalysis[classId].impairment
                  : 0;
                break;
              default:
                value = 0;
                break;
            }
            return { field: field.toUpperCase(), value };
          })
        : [],
      angleField: "value",
      colorField: "field",
      statistic: {
        title: {
          offsetY: 4,
          style: {
            fontSize: "32px",
            fontWeight: "bold",
          },
          customHtml: (container, view, datum, data) => {
            const { width } = container.getBoundingClientRect();
            const text = datum
              ? `${datum["value"]}`
              : `${data.reduce((r, d) => r + d["value"], 0)}`; //value
            return renderStatistic(width, textAmountFormat(text), {
              fontSize: 32,
            });
          },
        },
        content: {
          offsetY: -4,
          style: {
            fontSize: "12px",
            marginTop: "20px",
          },
          customHtml: (container, view, datum) => {
            const { width, height } = container.getBoundingClientRect();
            const d = Math.sqrt(
              Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
            );
            const text = datum ? datum["field"] : "TOTAL VALUE";
            return renderStatistic(d, text, {
              fontSize: 28,
            });
          },
        },
      },
      meta: {
        value: {
          formatter: (v) => textAmountFormat(v),
        },
      },
      label: {
        type: "inner",
        autoRotate: false,
        formatter: ({ value }) => textAmountFormat(value),
      },
    });
    setConfigComparativeBarClassAnalysis({
      ...configComparativeBarClassAnalysis,
      data: props.dashboardData.classAnalysis
        ? classSelection.map((classId) => {
            const field = props.dashboardData.classAnalysis[classId]
              ? props.dashboardData.classAnalysis[classId].className
              : "";
            let value = 0;
            switch (fieldSelection) {
              case envConfig.graphFilter.ACC_DEPRECIATION:
                value = props.dashboardData.classAnalysis[classId]
                  ? props.dashboardData.classAnalysis[classId]
                      .accumulatedDepreciation
                  : 0;
                break;
              case envConfig.graphFilter.CARRYING_AMOUNT:
                value = props.dashboardData.classAnalysis[classId]
                  ? props.dashboardData.classAnalysis[classId].carryingAmount
                  : 0;
                break;
              case envConfig.graphFilter.COST:
                value = props.dashboardData.classAnalysis[classId]
                  ? props.dashboardData.classAnalysis[classId].cost
                  : 0;
                break;
              case envConfig.graphFilter.IMPAIRMENT:
                value = props.dashboardData.classAnalysis[classId]
                  ? props.dashboardData.classAnalysis[classId].impairment
                  : 0;
                break;
              default:
                value = 0;
                break;
            }
            return { field: field.toUpperCase(), value };
          })
        : [],
      meta: {
        value: {
          formatter: (v) => textAmountFormat(v),
        },
      },
      label: {
        formatter: ({ value }) => textAmountFormat(value),
      },
    });
    setConfigComparativeLineClassAnalysis({
      ...configComparativeLineClassAnalysis,
      data: props.dashboardData.classAnalysis
        ? classSelection
            .reduce((accData, classId) => {
              const yearData = props.dashboardData.classAnalysis[classId]
                ? props.dashboardData.classAnalysis[classId].yearData
                : [];
              accData = accData.concat(
                yearData.reduce(
                  (
                    acc,
                    {
                      year,
                      accumulatedDepreciation,
                      carryingAmount,
                      cost,
                      impairment,
                    }
                  ) => {
                    const field = props.dashboardData.classAnalysis[classId]
                      ? props.dashboardData.classAnalysis[classId].className
                      : "";
                    let value = 0;
                    switch (fieldSelection) {
                      case envConfig.graphFilter.ACC_DEPRECIATION:
                        value = accumulatedDepreciation;
                        break;
                      case envConfig.graphFilter.CARRYING_AMOUNT:
                        value = carryingAmount;
                        break;
                      case envConfig.graphFilter.COST:
                        value = cost;
                        break;
                      case envConfig.graphFilter.IMPAIRMENT:
                        value = impairment;
                        break;
                      default:
                        value = 0;
                        break;
                    }
                    acc.push({
                      year,
                      value,
                      field: field.toUpperCase(),
                    });
                    return acc;
                  },
                  []
                )
              );
              return accData;
            }, [])
            .reverse()
        : [],
      meta: {
        value: {
          formatter: (v) => textAmountFormat(v),
        },
      },
      label: {
        formatter: ({ value }) => textAmountFormat(value),
      },
    });
  };

  const fieldFilters = () => {
    return (
      <div className="dashboards__shorting">
        {Object.values(envConfig.graphFilter).map((filter, index) => {
          return (
            <span
              className={`dashboards__shorting__select ${
                comparativeClassAnalysisFieldSelection === filter
                  ? "dashboards__shorting__select__selected"
                  : ""
              }`}
              key={index}
              onClick={() => {
                setComparativeClassAnalysisFieldSelection(filter);
                selectComparativeClassAnalysisClass(
                  comparativeClassAnalysisClassSelection,
                  filter
                );
              }}
            >
              {filter}
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs />

        <div className="dashboard__cards">
          {/* card */}
          <div className="dashboard__card">
            <div className="dashboard__story dashboard__story--2">
              <div className="dashboard__circle dashboard__circle--2">
                <img src={accountDashboard} className="dashboard__svg" alt="" />
              </div>
            </div>
            <div className="dashboard__content">
              <p>Total Asset</p>
              <h2 className="heading-2 upper">
                {props.dashboardData.tilesData.totalAsset}
              </h2>
            </div>
          </div>
          <div className="dashboard__card">
            <div className="dashboard__story dashboard__story--3">
              <div className="dashboard__circle dashboard__circle--3">
                <img src={workingAsset} className="dashboard__svg" alt="" />
              </div>
            </div>
            <div className="dashboard__content">
              <p>Working Assets</p>
              <h2 className="heading-2 upper">
                {props.dashboardData.tilesData.totalWorkingAsset}
              </h2>
            </div>
          </div>
          <div className="dashboard__card">
            <div className="dashboard__story dashboard__story--4">
              <div className="dashboard__circle dashboard__circle--4">
                <img src={accountDashboard} className="dashboard__svg" alt="" />
              </div>
            </div>
            <div className="dashboard__content">
              <p>Non-Working Assets</p>
              <h2 className="heading-2 upper">
                {props.dashboardData.tilesData.totalNonWorkingAsset}
              </h2>
            </div>
          </div>
          <div className="dashboard__card">
            <div className="dashboard__story dashboard__story--1">
              <div className="dashboard__circle dashboard__circle--1">
                <img src={workingAsset} className="dashboard__svg" alt="" />
              </div>
            </div>
            <div className="dashboard__content">
              <p>Depreciated & Working Assets</p>
              <h2 className="heading-2">
                {props.dashboardData.tilesData.totalDepreciatedWorkingAsset}
              </h2>
            </div>
          </div>
          {!props.userData?.organization?.is_subsidiary && (
            <div className="dashboard__card">
              <div className="dashboard__story dashboard__story--3">
                <div className="dashboard__circle dashboard__circle--3">
                  <img
                    src={nonWorkingAsset}
                    className="dashboard__svg"
                    alt=""
                  />
                </div>
              </div>
              <div className="dashboard__content">
                <p>Subsidiaries</p>
                <h2 className="heading-2 upper">
                  {props.dashboardData.tilesData.totalSubsidiary}
                </h2>
              </div>
            </div>
          )}
        </div>

        <Tabs className="dashboard__tabs mt-md">
          <TabList className="dashboard__tab">
            <Tab className={`dashboard__item dashboard__item--active`}>
              <span>
                <span className="dashboard__item--name">Overall Analysis</span>
              </span>
            </Tab>
            <Tab className={`dashboard__item dashboard__item--active`}>
              <span>
                <span className="dashboard__item--name">Class Analysis</span>
              </span>
            </Tab>
            <Tab className={`dashboard__item dashboard__item--active`}>
              <span>
                <span className="dashboard__item--name">
                  Comparative Analysis
                </span>
              </span>
            </Tab>
          </TabList>
          <TabPanel>
            <div className="listing">
              <div className="listing__heade">
                <h3 className="heading-3 mb-md">Class Trend </h3>
                <div className="dashboard_graph--line">
                  <Line {...classTrend} />
                </div>
              </div>
            </div>
            <div className="dashboards">
              <div className="dashboards__cards">
                <div className="dashboards__card">
                  <h3 className="heading-3 weight-700">Recent Edits</h3>
                  {props.dashboardData.recentEditsData.map((edit, index) => {
                    return (
                      <div className="dashboards__underline" key={index}>
                        <div className="dashboards__dots">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className="dashboards__blueBg cap">
                            {edit.created_on}
                          </p>
                        </div>

                        <div className="dashboards__dots dashboards__end">
                          <div className="dashboards__dot dashboards__dot--blue"></div>
                          <p className=" cap">{edit.user_name}</p>
                        </div>
                        <p className="dashboards__subFull">
                          Asset Id {"  "}
                          {edit.asset_id}
                        </p>
                        <p className="dashboards__subFull dashboards__gray">
                          {edit.message}
                        </p>
                        <div className="dashboards__underline"></div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="dashboards__cards">
                <p className="weight-700 cap">Asset Allocation </p>
                <Pie {...config} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="mb-md mt-md">
              <p className="mb-sm">Select a class for analysis</p>
              {props.userData.classes.map(
                ({ default_class_id, name }, index) => {
                  return (
                    <label
                      onClick={() => {
                        selectClassAnalysisClass(default_class_id);
                      }}
                      className={`dashboard__popupClasses dashboard__popupClasses--${
                        classAnalysisClassSelection === default_class_id
                          ? "selected"
                          : "unselected"
                      }`}
                      key={index}
                    >
                      <span className={`cap`}>{name}</span>
                    </label>
                  );
                }
              )}
            </div>
            {classAnalysisClassSelection > -1 && (
              <>
                <div className="dashboards">
                  <div className="dashboards__cards">
                    <p className="weight-700 cap">Pie Chart</p>
                    <Pie {...configClassAnalysis} />
                  </div>
                  <div className="dashboards__cards">
                    <p className="weight-700 cap">Bar Chart</p>
                    <Column {...configBarClassAnalysis} />
                  </div>
                </div>

                <div className="listing">
                  <div className="listing__heade">
                    <h3 className="heading-3 mb-md">Line Graph </h3>
                    <div className="dashboard_graph--line">
                      <Line {...configLineClassAnalysis} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </TabPanel>
          <TabPanel>
            <div className="mb-md mt-md">
              <p className="mb-sm">Select two or more classes for analysis</p>
              {props.userData.classes.map(
                ({ default_class_id, name }, index) => {
                  return (
                    <label
                      onClick={() => {
                        let tempSelection = Object.assign(
                          [],
                          comparativeClassAnalysisClassSelection
                        );
                        if (tempSelection.indexOf(default_class_id) > -1) {
                          tempSelection.splice(
                            tempSelection.indexOf(default_class_id),
                            1
                          );
                        } else {
                          tempSelection = tempSelection.concat([
                            default_class_id,
                          ]);
                        }

                        setComparativeClassAnalysisClassSelection(
                          tempSelection
                        );
                        selectComparativeClassAnalysisClass(
                          tempSelection,
                          comparativeClassAnalysisFieldSelection
                        );
                      }}
                      className={`dashboard__popupClasses dashboard__popupClasses--${
                        comparativeClassAnalysisClassSelection.indexOf(
                          default_class_id
                        ) > -1
                          ? "selected"
                          : "unselected"
                      }`}
                      key={index}
                    >
                      <span className={`cap`}>{name}</span>
                    </label>
                  );
                }
              )}
            </div>
            {comparativeClassAnalysisClassSelection.length > 0 && (
              <>
                <div className="dashboards">
                  <div className="dashboards__cards">
                    <div className="dashboards__shortings">
                      <p className="weight-700 cap">Pie Chart</p>
                      <div className="dashboards__shorting">
                        {fieldFilters()}
                      </div>
                    </div>
                    <Pie {...configComparativeClassAnalysis} />
                  </div>
                  <div className="dashboards__cards">
                    <div className="dashboards__shortings">
                      <p className="weight-700 cap">Bar Chart</p>
                      <div className="dashboards__shorting">
                        {fieldFilters()}
                      </div>
                    </div>
                    <Column {...configComparativeBarClassAnalysis} />
                  </div>
                </div>
                <div className="listing">
                  <div className="listing__heade">
                    <div className="dashboards__shortings">
                      <h3 className="heading-3 mb-md">Line Graph </h3>
                      <div className="dashboards__shorting">
                        {fieldFilters()}
                      </div>
                    </div>
                    <div className="dashboard_graph--line">
                      <Line {...configComparativeLineClassAnalysis} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default connect((state) => ({
  authKey: getAuthKey(state),
  userData: getUserData(state),
  dashboardData: getDashboardData(state),
}))(Dashboard);
