import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { getAuthKey } from "../redux/selector";

const PublicRoute = ({ component: Component, authKey }) => {
  return authKey ? <Navigate to="/app/home" /> : <Component />;
};

export default connect((state) => ({ authKey: getAuthKey(state) }))(
  PublicRoute
);
