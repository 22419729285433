import { connect, useDispatch } from "react-redux";
import { Button, Form, Input } from "reactstrap";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import { getAuthKey, getUserData } from "../../redux/selector";
import { setMessageUser } from "../../redux/features/user";
import BreadCrumbs from "../../components/breadCrumbs";
import envConfig from "../../config/constants";
import { useState } from "react";
import { hideIcon, viewIcon } from "../../config/assets";
import validators from "../../config/validators";
import getTranslation from "../../translations";
import { selfUser, updateSelfUser } from "../../redux/api";

const Account = (props) => {
  const dispatch = useDispatch();

  const [name, setName] = useState(props.userData.name);
  const [nameValid, setNameValid] = useState(false);
  const [nameMessage, setNameMessage] = useState("");
  const [phone, setPhone] = useState(props.userData.phone);
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneMessage, setPhoneMessage] = useState("");
  const [password, setPassword] = useState("");
  const [isText, setIsText] = useState(false);

  const verifyInput = (type, value) => {
    switch (type) {
      case "name":
        setName(value);
        setNameValid(value.match(validators.name));
        setNameMessage(
          value === ""
            ? ""
            : value.match(validators.name)
            ? props.translations.registerPage.validation.nameVerify
            : props.translations.registerPage.validation.nameInvalid
        );
        break;
      case "phone":
        setPhone(value);
        setPhoneValid(value.match(validators.phone));
        setPhoneMessage(
          value === ""
            ? ""
            : value.match(validators.phone)
            ? props.translations.registerPage.validation.phoneVerify
            : props.translations.registerPage.validation.phoneInvalid
        );
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs crumbs={["Account Profile"]} />
        <div className="listing">
          <div className="companyProfile__header">
            <h3 className="heading-3">Account Profile</h3>
          </div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              const response = await dispatch(
                updateSelfUser({
                  authKey: props.authKey,
                  body: JSON.stringify({
                    name,
                    phone,
                    password: password === "" ? undefined : password,
                  }),
                })
              );
              if (response.payload.status === 200) {
                dispatch(selfUser({ authKey: props.authKey }));
                props.setMessageUser({
                  message: response.payload.message,
                  type: envConfig.notificationTypes.success,
                });
              } else {
                props.setMessageUser({
                  message: response.payload.message,
                  type: envConfig.notificationTypes.danger,
                });
              }
            }}
          >
            <div className="companyProfile__labels">
              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="name"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Name
                  </label>
                  <Input
                    className="form__input "
                    type="text"
                    placeholder="Enter Name"
                    minLength="3"
                    maxLength="32"
                    value={name}
                    onChange={(e) => {
                      verifyInput("name", e.target.value);
                    }}
                    required
                  />
                  <span
                    className={`login__err form__label--${
                      nameValid ? "dark" : "error"
                    }`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {nameMessage}
                  </span>
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="email"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Email
                  </label>
                  <Input
                    className="form__input "
                    type="email"
                    value={props.userData.email}
                    readOnly
                  />
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="password"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Password
                  </label>
                  <Input
                    className="form__input "
                    type={isText ? "text" : "password"}
                    placeholder="Enter Password"
                    minLength="6"
                    maxLength="16"
                    value={password}
                    onChange={(e) => {
                      verifyInput("password", e.target.value);
                    }}
                  />
                  <span
                    className="companyProfile__label__icons cursor"
                    onClick={() => {
                      setIsText(!isText);
                    }}
                  >
                    {isText ? (
                      <img src={hideIcon} alt="" />
                    ) : (
                      <img src={viewIcon} alt="" />
                    )}
                  </span>
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="phone"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Phone Number
                  </label>
                  <Input
                    className="form__input "
                    type="tel"
                    placeholder="Enter Phone"
                    minLength="7"
                    maxLength="16"
                    value={phone}
                    onChange={(e) => {
                      verifyInput("phone", e.target.value);
                    }}
                    required
                  />
                  <span
                    className={`login__err form__label--${
                      phoneValid ? "dark" : "error"
                    }`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {phoneMessage}
                  </span>
                </div>
              </div>
            </div>
            <div className="popup__btns editProfile__btns mt-md">
              <Button className="btn popup__btn btn__blue cap" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslation(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(Account);
