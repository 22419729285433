export const getAuthKey = (store) => store.user.authKey;
export const getUserData = (store) => store.user.userData;
export const getRegisterData = (store) => store.user.registerData;
export const getForgotData = (store) => store.user.forgotData;
export const getAccountantsData = (store) => store.user.accountants;
export const getSubsidiariesData = (store) => store.user.subsidiaries;

export const getCountriesData = (store) => store.countries;

export const getDefaultClassData = (store) => store.asset.defaultClass;
export const getDefaultColumnData = (store) => store.asset.defaultColumn;
export const getClassAssets = (store) => store.asset.classAssets;
export const getClassAssetInfo = (store) => store.asset.classAssetInfo;
export const getAssetDetailData = (store) => store.asset.assetDetails;
export const getDashboardData = (store) => store.asset.dashboardData;
export const getClassColumnData = (store) => store.asset.classColumn;
export const getClassArchiveAssets = (store) => store.asset.classArchiveAssets;

export const getLoading = (store) =>
  store.user.loading || store.countries.loading || store.asset.loading;
export const getNotificationMessage = (store) => store.user.notificationMessage;

export const getTranslations = (store) => store.language.translations;
