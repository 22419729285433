import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  gaap,
  phone,
  physicalAddress,
  postalAddress,
} from "../../config/assets";
import envConfig from "../../config/constants";
import TopNav from "../../components/topNav";
import SideNav from "../../components/sideNav";
import BreadCrumbs from "../../components/breadCrumbs";
import { getTranslations } from "../../redux/selector";

const Info = (props) => {
  const { state } = useLocation();
  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs crumbs={["Contact Us"]} />
      </div>

      <div className="contactUs__story">
        <img className="contactUs__img" src={gaap} alt="gaap" />
      </div>

      <div className="contactUs__content">
        <div className="contactUs__box">
          <h2 className="contactUs__title">
            {state && state.message
              ? state.message
              : props.translations.infoPage.lookingSomething}
          </h2>
          <div className="contactUs__phones">
            {envConfig.phoneNumbers.map((item, index) => {
              return (
                <div
                  className="contactUs__sub contactUs__sub-center"
                  key={index}
                >
                  <img src={phone} className="contactUs__svg" alt="phone" />
                  <p className="contactUs__text">{item}</p>
                </div>
              );
            })}
          </div>
          <div className="contactUs__phones">
            <div className="contactUs__sub">
              <img
                src={physicalAddress}
                className="contactUs__icons"
                alt="phone"
              />
              <div>
                <p className="contactUs__text c__9b">
                  {props.translations.infoPage.physicalAddress}
                </p>
                <p className="contactUs__text mt-s-sm">
                  {envConfig.physicalAddress}
                </p>
              </div>
            </div>
            <div className="contactUs__sub">
              <img
                src={postalAddress}
                className="contactUs__icons"
                alt="phone"
              />
              <div>
                <p className="contactUs__text c__9b">
                  {props.translations.infoPage.postalAddress}
                </p>
                <p className="contactUs__text mt-s-sm">
                  {envConfig.postalAddress}
                </p>
              </div>
            </div>
          </div>
          <div className="contactUs__btns">
            <Link
              to="/auth/login"
              className="btn btn__blue contactUs__btn mt-md"
            >
              {props.translations.infoPage.done}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({ translations: getTranslations(state) }),
  {}
)(Info);
