import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import envConfig from "../../config/constants";
import { calculateDeferredTax } from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import { getAuthKey, getTranslations, getUserData } from "../../redux/selector";

const DeferredTaxReport = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [report, setReport] = useState([]);

  const fetchReport = async () => {
    const { payload: deferredTaxReport } = await dispatch(
      calculateDeferredTax({
        authKey: props.authKey,
        classId: state.selectedClass.id,
        assetId: state.selectedAsset.id,
        body: JSON.stringify({
          corporation_tax: state.corporationTax,
          tax_list: state.yearlyTaxList,
        }),
      })
    );
    if (deferredTaxReport.status === 200) {
      setReport(deferredTaxReport.data);
    } else {
      props.setMessageUser({
        type: envConfig.notificationTypes.danger,
        message: deferredTaxReport.message,
      });
    }
  };

  useEffect(() => {
    if (
      !(
        state &&
        state.selectedClass &&
        state.selectedAsset &&
        state.corporationTax &&
        state.yearlyTaxList
      )
    ) {
      navigate("/app/dashboard");
      return;
    }
    fetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, state]);

  return (
    <>
      <TopNav />
      <SideNav />

      {state && report.length > 0 && (
        <div className="listings">
          <BreadCrumbs crumbs={["Deferred Tax", "Report"]} />
          <div className="listing">
            <div className="listing__breadcrum-2 mb-sm">
              <div>
                <h3 className="heading-3 cap mb-sm">Deferred Tax</h3>
                <span className="cap ">
                  Asset ID :
                  <span className="c__blue">{state?.selectedAsset.label}</span>
                </span>
                <span className="cap  ml-sm">
                  Class :{" "}
                  <span className="c__blue cap">
                    {state?.selectedClass.label}
                  </span>
                </span>
              </div>
              <span className="cap  ml-sm">
                <span
                  className="btn btn__blue"
                  onClick={() => {
                    navigate("/app/deferred-tax", {
                      state: {
                        selectedClass: state.selectedClass,
                        selectedAsset: state.selectedAsset,
                        corporationTax: state.corporationTax,
                        yearlyTaxList: state.yearlyTaxList,
                      },
                    });
                  }}
                >
                  Edit
                </span>
              </span>
            </div>
            <div
              className={`deferList__header deferList__header--${report.length} deferList__border`}
            >
              <div className="">Name</div>
              {report.map((_item, index) => {
                return (
                  <div className="deferList__right">
                    End of Year {index + 1}
                  </div>
                );
              })}
            </div>
            <div
              className={`deferList__header deferList__header--${report.length}`}
            >
              <div className="">Carrying amount</div>
              {report.map((item) => {
                return (
                  <div className="deferList__right">
                    {item.carrying_amount.indexOf("-") > -1 ? (
                      <span className="c-orange">{item.carrying_amount}</span>
                    ) : (
                      item.carrying_amount
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className={`deferList__header deferList__header--${report.length}`}
            >
              <div className="">Tax rate</div>
              {state?.yearlyTaxList.map((item) => {
                return <div className="deferList__right">{item}%</div>;
              })}
            </div>
            <div
              className={`deferList__header deferList__header--${report.length}`}
            >
              <div className="">Tax base</div>
              {report.map((item) => {
                return (
                  <div className="deferList__right">
                    {item.tax_carrying_amount.indexOf("-") > -1 ? (
                      <span className="c-orange">
                        {item.tax_carrying_amount}
                      </span>
                    ) : (
                      item.tax_carrying_amount
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className={`deferList__header deferList__header--${report.length}`}
            >
              <div className="">Taxable temporary differences</div>
              {report.map((item) => {
                return (
                  <div className="deferList__right">
                    {item.taxable_difference.indexOf("-") > -1 ? (
                      <span className="c-orange">
                        {item.taxable_difference}
                      </span>
                    ) : (
                      item.taxable_difference
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className={`deferList__header deferList__header--${report.length}`}
            >
              <div className="">Deferred tax @ {state?.corporationTax}%</div>
              {report.map((item) => {
                return (
                  <div className="deferList__right">
                    {item.deferred_tax.indexOf("-") > -1 ? (
                      <span className="c-orange">{item.deferred_tax}</span>
                    ) : (
                      item.deferred_tax
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="listing">
            <div className="listing__header mb-sm">
              <div>
                <h3 className="heading-3 cap mb-sm">Journal Adjustments</h3>
              </div>
            </div>
            {report.map((item, index) => {
              return (
                <>
                  <div
                    className={`deferList__TaxHeader deferList__border deferList__bg`}
                  >
                    <div className="">End of Year {index + 1}</div>
                  </div>
                  {item.is_asset ? (
                    <>
                      <div className="deferList__TaxHeader deferList__border">
                        <div className="">DR</div>
                        <div className="">Deferred tax (SOFP)</div>
                        <div className="deferList__right">
                          {item.deferred_tax}
                        </div>
                        <div className="deferList__right"></div>
                      </div>
                      <div className="deferList__TaxHeader deferList__border">
                        <div className="">CR</div>
                        <div className="">Deferred tax expense - (SOSD)</div>
                        <div className="deferList__right"></div>
                        <div className="deferList__right">
                          {item.deferred_tax}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="deferList__TaxHeader deferList__border">
                        <div className="">DR</div>
                        <div className="">Deferred tax expense - (SOSD)</div>
                        <div className="deferList__right">
                          {item.deferred_tax}
                        </div>
                        <div className="deferList__right"></div>
                      </div>
                      <div className="deferList__TaxHeader deferList__border">
                        <div className="">CR</div>
                        <div className="">Deferred tax (SOFP)</div>
                        <div className="deferList__right"></div>
                        <div className="deferList__right">
                          {item.deferred_tax}
                        </div>
                      </div>
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    translations: getTranslations(state),
    authKey: getAuthKey(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(DeferredTaxReport);
