import { useEffect } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { connect } from "react-redux";
import envConfig from "../config/constants";
import { getTranslations } from "../redux/selector";

const Notification = ({ message, translations }) => {
  useEffect(() => {
    const createNotification = (type, message) => {
      NotificationManager.removeAll();
      switch (type) {
        case envConfig.notificationTypes.info:
          return NotificationManager.info(
            message,
            translations.notification.infoTitle,
            envConfig.notificationTime
          );
        case envConfig.notificationTypes.success:
          return NotificationManager.success(
            message,
            translations.notification.successTitle,
            envConfig.notificationTime
          );
        case envConfig.notificationTypes.warning:
          return NotificationManager.warning(
            message,
            translations.notification.warningTitle,
            envConfig.notificationTime
          );
        case envConfig.notificationTypes.danger:
          return NotificationManager.error(
            message,
            translations.notification.errorTitle,
            envConfig.notificationTime
          );
        default:
          break;
      }
    };
    if (message) createNotification(message.type, message.message);
  }, [message, translations]);
  return <NotificationContainer />;
};

export default connect((state) => ({ translations: getTranslations(state) }))(
  Notification
);
