import { configureStore } from "@reduxjs/toolkit";
import user from "./features/user";
import language from "./features/language";
import countries from "./features/countries";
import asset from "./features/asset";

export default configureStore({
  reducer: {
    user,
    language,
    countries,
    asset,
  },
});
