import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Input } from "reactstrap";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import {
  getAuthKey,
  getClassArchiveAssets,
  getUserData,
} from "../../redux/selector";
import DataTable from "react-data-table-component";
import { eye, redCross } from "../../config/assets";
import { getArchiveAssets } from "../../redux/api";
import envConfig from "../../config/constants";
import { verifyClassNameAndId } from "../../config/utility";
import { setMessageUser } from "../../redux/features/user";
import BreadCrumbs from "../../components/breadCrumbs";
import { clearClassColumn } from "../../redux/features/asset";

const ArchiveAssets = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "600",
        fontSize: "15px",
        textTransform: "capitalize",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
    rows: {
      style: {
        minHeight: "3rem", // override the row height
      },
    },
  };

  useEffect(() => {
    if (
      !verifyClassNameAndId(
        props.userData.classes,
        params.class_name,
        params.class_id
      )
    )
      navigate("/app/dashboard");
    else
      dispatch(
        getArchiveAssets({ authKey: props.authKey, classId: params.class_id })
      );
  }, [
    dispatch,
    navigate,
    params.class_id,
    params.class_name,
    props.authKey,
    props.userData.classes,
  ]);

  const findByDefaultColumn = (row, defaultColumn) => {
    return row.reduce((columnValue, data) => {
      return data.default_column_id === defaultColumn ? data : columnValue;
    }, {});
  };

  let headers = [],
    data = [];
  props.classAssets.forEach((assetRow, index) => {
    if (index === 0) {
      headers = assetRow.reduce((array, asset) => {
        const selector = array.length;
        array.push({
          ...asset,
          cell:
            selector === assetRow.length - 1
              ? (row) => {
                  return (
                    <div className="tooltip">
                      <span className="tooltiptext">
                        {envConfig.assetStatusValue[row[selector].value]}
                      </span>
                      <img
                        className="cursor mr-sm"
                        src={redCross}
                        alt="edit description"
                      />
                    </div>
                  );
                }
              : null,
          selector: (row) => {
            return row[selector].value;
          },
          right: true,
          wrap: true,
        });
        return array;
      }, []);
    } else {
      data.push(
        assetRow.reduce((array, asset) => {
          array.push(asset);
          return array;
        }, [])
      );
    }
  });
  headers.length > 0 &&
    headers.push({
      right: true,
      name: "Actions",
      cell: (row) => {
        return (
          <div className="listing__actions_archive">
            <Link
              to={`/app/archive-asset-detail/${params.class_name}/${params.class_id}/${row[0].asset_id}`}
              replace
            >
              <div className="tooltip">
                <span className="tooltiptext cap">View</span>
                <img className="listing__svg" src={eye} alt="eye svg icon" />
              </div>
            </Link>
          </div>
        );
      },
    });

  if (searchText !== "") {
    data = data.filter((row) => {
      return (
        findByDefaultColumn(row, envConfig.defaultColumn.asset_id)
          .value.toLowerCase()
          .indexOf(searchText.trim().toLowerCase()) > -1
      );
    });
  }

  return (
    <>
      <TopNav />
      <SideNav classId={params.class_id} className={params.class_name} />

      <div className="listings">
        <BreadCrumbs crumbs={["Archived " + params.class_name]} />

        <div className="listing pb0">
          <div className="listing__header">
            <h3 className="heading-3 cap">Archived {params.class_name}</h3>
            <div className="listing__header--box">
              <Input
                className="form__search"
                type="search"
                name="search"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div
                onClick={() => {
                  props.clearClassColumn();
                  navigate(
                    "/app/edit-columns/" +
                      params.class_name +
                      "/" +
                      params.class_id
                  );
                }}
                className="btn btn__border btn__brd"
              >
                Edit Class
              </div>
              <Link
                to={
                  "/app/asset-add/" + params.class_name + "/" + params.class_id
                }
                className="btn btn__blue btn__brd"
              >
                + Add Asset
              </Link>
            </div>
          </div>

          <div className="listing__header__mobile">
            <h3 className="heading-3 cap">{params.class_name}</h3>
            <Input
              className="form__search"
              type="search"
              name="search"
              placeholder="Search..."
            />
            <Link to="" className="btn btn__border btn__brd">
              Edit Class
            </Link>
            <Link
              to={"/app/asset-add/" + params.class_name + "/" + params.class_id}
              className="btn btn__blue btn__brd"
            >
              + Add Asset
            </Link>
          </div>
          {/* tabs start */}
          <Tabs className="listing__tabs">
            <TabList className="listing__tab">
              <Tab className={`listing__item listing__item--active`}>
                <span>
                  <span className="listing__item--name">All Items</span>
                  <span className="listing__pills listing__pills--gray">
                    {props.classAssets.length > 0
                      ? props.classAssets.length - 1
                      : 0}
                  </span>
                </span>
              </Tab>
            </TabList>
            {/* All Data */}
            <TabPanel className="listing__bottom asset-listing">
              <DataTable
                columns={headers}
                data={data}
                highlightOnHover={true}
                pagination={true}
                persistTableHead={true}
                customStyles={customStyles}
                fixedHeader
                fixedHeaderScrollHeight="300px"
              />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    userData: getUserData(state),
    classAssets: getClassArchiveAssets(state),
  }),
  { setMessageUser, clearClassColumn }
)(ArchiveAssets);
