import loginMainSvg from "../assets/login/login_main.svg";
import logoSvg from "../assets/login/logo.svg";
import hideIconSvg from "../assets/login/eyeHide.svg";
import viewIconSvg from "../assets/login/eye.svg";
import arrRightSvg from "../assets/login/arrRight.svg";
import financialOneSvg from "../assets/select-financial/1.svg";
import financialTwoSvg from "../assets/select-financial/2.svg";
import financialThreeSvg from "../assets/select-financial/3.svg";
import tickSvg from "../assets/tic.svg";
import otpSvg from "../assets/otp/otpImg.svg";
import backSvg from "../assets/setups/back.svg";
import GaapSvg from "../assets/gaap/gaap.svg";
import PhoneSvg from "../assets/gaap/phone.svg";
import PhysicalAddressSvg from "../assets/gaap/physical_add.svg";
import PostalAddressSvg from "../assets/gaap/postal__add.svg";
import logoutSVG from "../assets/navbar/svgLogout.svg";
import logoMobileSVG from "../assets/navbar/arrLogoMob.svg";
import logoutPowerSVG from "../assets/navbar/logout_power.svg";
import uploadSVG from "../assets/setups/upload_logo.svg";
import crossSVG from "../assets/svg/cross.svg";
import iconSVG from "../assets/logo/logo.svg";
import { ReactComponent as LandSVG } from "../assets/classes/land.svg";
import { ReactComponent as BuildingsSVG } from "../assets/classes/buildings.svg";
import { ReactComponent as ComputersSVG } from "../assets/classes/computers.svg";
import { ReactComponent as OfficeSVG } from "../assets/classes/office.svg";
import { ReactComponent as InfrastructureSVG } from "../assets/classes/infrastructure.svg";
import { ReactComponent as MotorSVG } from "../assets/classes/motor.svg";
import { ReactComponent as PlantSVG } from "../assets/classes/plant.svg";
import { ReactComponent as HeritageSVG } from "../assets/classes/heritage.svg";
import { ReactComponent as BiologicalSVG } from "../assets/classes/biological.svg";
import { ReactComponent as OtherSVG } from "../assets/classes/other.svg";
import { ReactComponent as downSVG } from "../assets/records/down.svg";
import downArrowSVG from "../assets/navbar/down.svg";
import dashboardSVG from "../assets/sidenav/dashboardBlue.svg";
import menuSVG from "../assets/navbar/menu.svg";
import accountSVG from "../assets/navbar/account.svg";
import archiveSVG from "../assets/navbar/archive.svg";
import premiumSVG from "../assets/navbar/premium.svg";
import phoneNavSVG from "../assets/navbar/phone.svg";
import profileSVG from "../assets/navbar/profile.svg";
import companyProfileSVG from "../assets/navbar/companyProfile.svg";
import helpSvg from "../assets/sidenav/help.svg";
import termsSvg from "../assets/sidenav/terms.svg";
import logoArrSVG from "../assets/navbar/logo_arr.svg";
import logoOnlySVG from "../assets/navbar/logo_only.svg";
import logoutNavSvg from "../assets/navbar/logout.svg";
import fullReportSVG from "../assets/reports/full_report.svg";
import detailedSVG from "../assets/reports/detailed.svg";
import summarySVG from "../assets/reports/summary.svg";
import ppeReconcilliationSVG from "../assets/reports/ppe_reconcilliation.svg";
import additionToPPESVG from "../assets/reports/addition_to_ppe.svg";
import formatSVG from "../assets/reports/format.svg";
import xelSVG from "../assets/reports/xel.svg";
import pdfSVG from "../assets/reports/pdf.svg";
import allItemsSVG from "../assets/reports/all_items.svg";
import nonWorkingItemsSVG from "../assets/reports/non_working_items.svg";
import fullyDepreciatedSVG from "../assets/reports/fully_depreciated.svg";
import fullyWorkingItemsSVG from "../assets/reports/fully_working_items.svg";
import investmentModelSVG from "../assets/premium/investement_model.svg";
import deferTaxSVG from "../assets/premium/defer_tax.svg";
import downWhiteSVG from "../assets/navbar/downWhite.svg";
import { ReactComponent as dashboardReactSVG } from "../assets/sidenav/dashboardBlue.svg";
import workingAssetSVG from "../assets/dashboard/workingAsset.svg";
import nonWorkingAssetSVG from "../assets/dashboard/nonWorkAsset.svg";
import accountDashboardSVG from "../assets/dashboard/account.svg";
import activeSVG from "../assets/land/active.svg";
import depreciationSVG from "../assets/land/depreciation.svg";
import redCrossSVG from "../assets/land/red_cross.svg";
import bulkEditSVG from "../assets/land/bulkEdit.svg";
import deleteSVG from "../assets/land/delete.svg";
import shareSVG from "../assets/land/share.svg";
import eyeSVG from "../assets/land/eye.svg";
import warningSVG from "../assets/dashboard/warning.svg";
import emptyImpairmentSVG from "../assets/land/empty_impairment.svg";
import emptyHistorySVG from "../assets/land/disposed-history.svg";
import editSVG from "../assets/land/edit.svg";
import life1SVG from "../assets/useful_life/life-1.svg";
import life2SVG from "../assets/useful_life/life-2.svg";
import qrCodeScannerSVG from "../assets/land/qr_code_scanner.svg";
import qrCodeSVG from "../assets/land/qr_code.svg";
import peopleGrowingSVG from "../assets/navbar/investemnt-model.png";
import noDisposedSVG from "../assets/land/no-disposed.svg";
import activeDisposedSVG from "../assets/land/active_disposed.svg";
import acUnitSVG from "../assets/land/ac_unit.svg";
import inactiveFreezeSVG from "../assets/land/inactive_freeze.svg";
import tickBlackSVG from "../assets/tic_black.svg";
import reclassificationSVG from "../assets/land/find_replace.svg";

export const loginMain = loginMainSvg;
export const logo = logoSvg;
export const hideIcon = hideIconSvg;
export const viewIcon = viewIconSvg;
export const arrRight = arrRightSvg;
export const financials = [financialOneSvg, financialTwoSvg, financialThreeSvg];
export const tick = tickSvg;
export const otpImage = otpSvg;
export const backImage = backSvg;
export const gaap = GaapSvg;
export const phone = PhoneSvg;
export const physicalAddress = PhysicalAddressSvg;
export const postalAddress = PostalAddressSvg;
export const logout = logoutSVG;
export const logoMobile = logoMobileSVG;
export const logoutPower = logoutPowerSVG;
export const upload = uploadSVG;
export const cross = crossSVG;
export const icon = iconSVG;
export const Land = LandSVG;
export const Buildings = BuildingsSVG;
export const Computers = ComputersSVG;
export const Office = OfficeSVG;
export const Infrastructure = InfrastructureSVG;
export const Motor = MotorSVG;
export const Plant = PlantSVG;
export const Heritage = HeritageSVG;
export const Biological = BiologicalSVG;
export const Other = OtherSVG;
export const Down = downSVG;
export const downArrow = downArrowSVG;
export const dashboard = dashboardSVG;
export const menu = menuSVG;
export const account = accountSVG;
export const archive = archiveSVG;
export const premium = premiumSVG;
export const phoneNav = phoneNavSVG;
export const profile = profileSVG;
export const companyProfile = companyProfileSVG;
export const help = helpSvg;
export const logoArr = logoArrSVG;
export const logoOnly = logoOnlySVG;
export const logooutNav = logoutNavSvg;
export const fullReport = fullReportSVG;
export const detailed = detailedSVG;
export const summary = summarySVG;
export const ppeReconcilliation = ppeReconcilliationSVG;
export const additionToPPE = additionToPPESVG;
export const format = formatSVG;
export const xel = xelSVG;
export const pdf = pdfSVG;
export const allItems = allItemsSVG;
export const nonWorkingItems = nonWorkingItemsSVG;
export const fullyDepreciated = fullyDepreciatedSVG;
export const fullyWorkingItems = fullyWorkingItemsSVG;
export const investmentModel = investmentModelSVG;
export const deferTax = deferTaxSVG;
export const downWhite = downWhiteSVG;
export const DashboardReact = dashboardReactSVG;
export const workingAsset = workingAssetSVG;
export const nonWorkingAsset = nonWorkingAssetSVG;
export const accountDashboard = accountDashboardSVG;
export const active = activeSVG;
export const depreciation = depreciationSVG;
export const redCross = redCrossSVG;
export const bulkEdit = bulkEditSVG;
export const deleteAsset = deleteSVG;
export const share = shareSVG;
export const eye = eyeSVG;
export const warning = warningSVG;
export const emptyImpairment = emptyImpairmentSVG;
export const emptyHistory = emptyHistorySVG;
export const edit = editSVG;
export const life1 = life1SVG;
export const life2 = life2SVG;
export const qrCodeScanner = qrCodeScannerSVG;
export const qrCode = qrCodeSVG;
export const peopleGrowing = peopleGrowingSVG;
export const noDisposed = noDisposedSVG;
export const activeDisposed = activeDisposedSVG;
export const acUnit = acUnitSVG;
export const inactiveFreeze = inactiveFreezeSVG;
export const tickBlack = tickBlackSVG;
export const reclassification = reclassificationSVG;
export const terms = termsSvg;
