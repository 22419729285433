const translations = {
  appInfo: {
    appName: "Virgilproper",
    tagLine: "Property, Plant & Equipment Management",
  },
  notification: {
    infoTitle: "Info",
    errorTitle: "Oh! Snap",
    successTitle: "Success",
    warningTitle: "Warning",
  },
  loginPage: {
    validation: {
      emailVerify: "Your email looks good!",
      emailInvalid: "Please enter a valid email!",
    },
    advertise: {
      message: "Property, Plant & Equipment Management",
    },
    title: {
      h2: "Log in",
      para: "Welcome back to Virgilproper",
      subParaLink: "New here? Sign up here",
    },
    form: {
      err: {
        email: "email",
        password: "password",
      },
      email: {
        label: "Email",
        name: "email",
        type: "text",
        placeholder: "Enter Email",
        minLength: 5,
        maxLength: 64,
      },
      password: {
        label: "Password",
        forgotLink: "Forgot ?",
        name: "password",
        type: "text",
        typePassword: "password",
        placeholder: "Enter Password",
        minLength: 6,
        maxLength: 16,
        alt: {
          view: "view icon svg",
          hide: "hide icon svg",
        },
      },
    },
    button: {
      submit: "Log in",
    },
  },
  registerPage: {
    validation: {
      nameVerify: "Your name looks good!",
      nameInvalid: "Please enter a valid name!",
      emailVerify: "Your email looks good!",
      emailInvalid: "Please enter a valid email!",
      phoneVerify: "Your phone looks good!",
      phoneInvalid: "Please enter a valid phone!",
    },
    advertise: {
      message: "Property, Plant & Equipment Management",
    },
    title: {
      h2: "Sign up",
      para: "Welcome to Virgilproper",
    },
    form: {
      err: {
        name: "name",
        email: "email",
        password: "password",
      },
      name: {
        label: "Name",
        name: "name",
        type: "text",
        placeholder: "Enter Name",
        minLength: 3,
        maxLength: 32,
      },
      phone: {
        label: "Phone",
        name: "phone",
        type: "text",
        placeholder: "Enter Phone",
        minLength: 7,
        maxLength: 16,
      },
      email: {
        label: "Email",
        name: "email",
        type: "text",
        placeholder: "Enter Email",
        minLength: 5,
        maxLength: 64,
      },
      password: {
        label: "Password",
        forgotLink: "Forgot ?",
        name: "password",
        type: "text",
        typePassword: "password",
        placeholder: "Enter Password",
        minLength: 6,
        maxLength: 16,
      },
    },
    alt: {
      login: "login page logo with name",
      view: "view icon svg",
      hide: "hide icon svg",
    },
    button: {
      submit: "Next",
    },
    redirect: { link: "Already have an account ? Sign in here" },
  },
  registerFrameworkPage: {
    validation: {
      framework: "Please select a financial framework",
    },
    advertise: {
      message: "Property, Plant & Equipment Management",
    },
    title: {
      h2: "Select Your Financial Framework",
      para: "Which Financial Reporting framework is your organisation/entity using?",
    },
    alt: {
      framework: "financial framework main image",
      logo: "virgilproper logo",
      selectTic: "checked or right or selected tic svg icon",
    },
    button: {
      submit: "Next",
    },
    redirect: { link: "change E-mail" },
  },
  registerOTPPage: {
    validation: {
      emailVerify: "Your email looks good!",
      emailInvailid: "Please enter a valid email!",
      otpInvalid: "OTP is invalid",
    },
    advertise: {
      message: "Property, Plant & Equipment Management",
    },
    title: {
      h2: "Verify Your Account",
      para: "An OTP has been sent to your",
    },
    form: {
      err: {
        email: "email",
        password: "password",
      },
      otp: {
        label: "Enter OTP here",
        resend: "Resend",
        name: "otp",
        type: "text",
        typePassword: "password",
        placeholder: "Enter OTP",
        minLength: 4,
        maxLength: 4,
      },
    },
    alt: {
      otp: " otp page svg or image logo with name",
      logo: "virgilproper logo",
      view: "view icon svg",
      hide: "hide icon svg",
    },
    button: {
      submit: "Verify",
    },
    redirect: { link: "change E-mail" },
  },
  infoPage: {
    lookingSomething:
      "I think your looking for something which doesn't exists, still you can reach us over below details.",
    awaitAdministrator:
      "Your Request has been Sent to Administrator Kindly contact us at",
    awaitAccess: "Please wait, we are taking you to the requested account",
    physicalAddress: "Physical Address",
    postalAddress: "Postal Address",
    done: "Got it",
  },
  forgotPage: {
    validation: {
      emailVerify: "Your email looks good!",
      emailInvalid: "Please enter a valid email!",
    },
    advertise: {
      message: "Property, Plant & Equipment Management",
    },
    heading: {
      h2: "Enter Email",
      para: "Enter your registered email",
    },
    form: {
      label: {
        email: "Email",
      },
      input: {
        minLength: 5,
        maxLength: 50,
      },
    },
    button: {
      submit: "Send Email",
    },
    link: {
      login: "Already have an account ? Login here",
    },
  },
  forgotVerifyPage: {
    validation: {
      otpInvalid: "OTP is invalid",
      matchBothPasswordInvalid: "Oh! Both password are not matched",
    },
    advertise: {
      message: "Property, Plant & Equipment Management",
    },
    title: {
      h2: "Enter New Password",
      para: "An OTP has been sent to your email",
      subParaLink: "change E-mail",
    },
    form: {
      err: {
        password: "password",
        resPassword: "resPassword",
      },
      enterOTP: {
        label: "Enter OTP here",
        resend: "Resend",
        name: "otp",
        type: "text",
        typePassword: "password",
        placeholder: "Enter OTP",
        minLength: 4,
        maxLength: 4,
        alt: {
          view: "view icon svg",
          hide: "hide icon svg",
        },
      },
      newPassword: {
        name: "password",
        type: "text",
        typePassword: "password",
        label: "New Password",
        placeholder: "Enter Password",
        minLength: 6,
        maxLength: 16,
        alt: {
          view: "view icon svg",
          hide: "hide icon svg",
        },
      },
      reEnterPassword: {
        name: "resPassword",
        type: "text",
        typePassword: "password",
        label: "Re-enter Password",
        placeholder: "Re-enter Password",
        minLength: 6,
        maxLength: 16,
        alt: {
          view: "view icon svg",
          hide: "hide icon svg",
        },
      },
    },
    button: {
      submit: "Continue",
    },
  },
  setupPage: {
    validation: {
      minLengthSms: "Oops! Min length is 3",
      fileSizeInvalid: "File size less than 2MB",
      fileUpload: "File selected",

      emailVerify: "Your email looks good!",
      emailInvalid: "Please enter a valid email!",
      passwordStronger: "Your password looks stronger!",
      passwordBetter: "Your password looks better!",
      passwordGood: "Your password looks good!",
      phoneInvalid: "OOPs! Only numeric values or digits allowed",
    },
    advertise: {
      message: "Property, Plant & Equipment Management",
    },
    title: {
      h2: "Setup",
      para: "Fill in the information about your organisation",
      backAdmin: "Back to Admin",
    },
    logoName: {
      name: "Virgilproper",
    },
    form: {
      err: {
        name: "name",
        email: "email",
        password: "password",
      },
      organisation: {
        label: "Name of organisation/entity *",
        name: "name",
        type: "text",
        placeholder: "Enter Name",
        minLength: 3,
        maxLength: 64,
      },
      financialYear: {
        label: "Financial Year Start *",
        name: "date",
        placeholder: "Start Date",
      },
      financialYearEnd: {
        label: "Financial Year End *",
        name: "date",
        placeholder: "End Date",
      },
      physicalAddr: {
        organisation: {
          label: "Physical address of organisation/entity *",
        },
        building: {
          name: "physical_area",
          type: "text",
          placeholder: "Building",
          minLength: 3,
          maxLength: 64,
        },
        locality: {
          name: "physical_locality",
          type: "text",
          placeholder: "Locality",
          minLength: 3,
          maxLength: 64,
        },
        country: {
          name: "physical_country",
          type: "text",
          placeholder: "Country *",
          minLength: 3,
          maxLength: 32,
        },
        currency: {
          name: "currency*",
          option: "Currency",
          id: "type",
        },
        state: {
          name: "physical_state",
          type: "text",
          placeholder: "State / Province",
          minLength: 3,
          maxLength: 32,
        },
        zip: {
          name: "physical_zip",
          type: "text",
          placeholder: "Zipcode (optional)",
          minLength: 4,
          maxLength: 10,
        },
      },
      postalAddr: {
        organisation: {
          label: "Postal address of organisation/entity *",
        },
        building: {
          name: "postal_area",
          type: "text",
          placeholder: "Building",
          minLength: 3,
          maxLength: 64,
        },
        locality: {
          name: "postal_locality",
          type: "text",
          placeholder: "Locality",
          minLength: 3,
          maxLength: 64,
        },
        country: {
          name: "postal_country",
          type: "text",
          placeholder: "Country",
          minLength: 3,
          maxLength: 32,
        },
        currency: {
          name: "currency",
          option: "Currency",
          id: "type",
        },
        state: {
          name: "postal_state",
          type: "text",
          placeholder: "State / Province",
          minLength: 3,
          maxLength: 32,
        },
        zip: {
          name: "postal_zip",
          type: "text",
          placeholder: "Zipcode (optional)",
          minLength: 4,
          maxLength: 10,
        },
      },
      checkBtn: {
        label: "Use same address for Postal Adress as well.",
        name: "sameAddr",
        type: "checkbox",
        id: "sameAddr",
      },
    },
    alt: {
      logo: "back arrow with logo and name",
      logout: "logout svg icon and name",
      userProfile: "upload user profile",
    },
    button: {
      submit: "Continue",
    },
  },
  setupDepreciationPage: {
    validation: {
      depreciation: "Please select a depreciation type",
    },
    title: {
      h2: "Select Your Depreciation Type",
      para: "Which Financial Depreciaton Type is your organisation/entity using?",
    },
    logoName: {
      name: "Virgilproper",
    },
    form: {
      straightLine: {
        label: "Straight Line Depreciaton",
        id: "STRAIGHT_LINE",
        name: "text",
        type: "checkbox",
        value: "STRAIGHT_LINE",
      },
      reducingBalance: {
        label: "Reducing Balance Depreciation",
        id: "REDUCING_BALANCE",
        name: "text",
        type: "checkbox",
        value: "REDUCING_BALANCE",
      },
    },
    alt: {
      logo: "back arrow with logo and name",
      logout: "logout svg icon and name",
      selectedCard: "selected or tic or right card selected",
    },
    button: {
      submit: "Continue",
    },
  },
  setupClass: {
    validation: {
      class: "Please select atleast one asset class",
    },
    title: {
      h2: "Select Classes Your Organisation/Entity Uses",
      para: `Which type of assets will be shown in your organisation's account?`,
    },
    alt: {
      logo: "back arrow with logo and name",
      logout: "logout svg icon and name",
      selectedCard: "selected or tic or right card selected",
    },
    button: {
      submit: "Select Class(es)",
    },
  },
  setupColumn: {
    validate: {
      column: ` classes have been updated with selected columns`,
    },
    title: {
      h2: "Select Records/Columns for Your Organisation/Entity Uses",
      para: `Of these all, what information your account will have?`,
      leftRecord: "Select Record/Column",
      rightRecord: "Rearrange Record/Column",
    },
    popup: {
      heading: "Consumed Life",
      para: "Is assigned to these classes :",
    },
    popupAddRecord: {
      heading: "Add Record/Column",
      form: {
        columnName: {
          label: "Column Name",
          id: "name",
          name: "name",
          type: "text",
          placeholder: "Name",
          minLength: 3,
          maxLength: 32,
        },
        recordType: {
          label: "Record Type",
          option: "Select",
          id: "type",
          name: "type",
        },
        search: {
          name: "search",
          type: "search",
        },
      },
    },
    alt: {
      logo: "back arrow with logo and name",
      logout: "logout svg icon and name",
      selectedCard: "selected or tic or right card selected",
      removePopup: "cross or remove or cut svg icon",
    },
    button: {
      submit: "Select Class(es)",
      cancel: "Cancel",
      add: "Add",
      apply: "Apply",
    },
  },
  topNav: {
    heading: {
      dashboard: "Dashboard",
      archive: "Archive",
      accountant: "Accountant Management",
      subsidiary: "Subsidiary Management",
      profile: "Account Profile",
      company: "Company Profile",
      contact: "Contact Us",
      help: "Help",
      logout: "Logout",
      generateReport: "Generate Report",
      premium: "Premium",
      deferTax: "Deferred Tax",
    },
    dropDown: {
      fullReport: "Full Report",
      detailed: "Detailed",
      summary: "Summary",
      ppe: "PPE",
      ppeReconciliation: "PPE Reconciliation",
      additionPPE: "Addition to PPE",
      pdf: "Export to PDF",
      excel: "Export to Excel",
      format1: "Format 1",
      format2: "Format 2",
      allItems: "All Items",
      fullyWorking: "Fully Working Items",
      nonWorking: "Non-working Items",
      depreciatedWorking: "Fully Depreciated and Working",
    },
  },
  sideNav: {
    heading: {
      dashboard: "Dashboard",
    },
  },
  deferredTax: {
    validation: {
      select_here: "OOPs! Please Select here",
      enter_here: "OOPs! Please Enter here",
      digit_invailid: "Only Digit or Number allowed",
      not_more_hund: "Value not more than 100*",
    },
    title: {
      para: "Investment Model",
      deferredTax: "Deferred Tax",
    },
    form: {
      allocation_asset: {
        label: "Select Allocation Of Asset",
        name: "allocation_asset",
        type: "radio",
        id: "allocation_asset",
      },
      class: {
        label: "Select the Class Asset Belongs too",
      },
      asset: {
        label: "Select Asset",
      },
      corporation_tax: {
        label: "Input Corporation Tax Rate",
        id: "corporation_tax",
        name: "corporation_tax",
        type: "text",
        placeholder: "Corporation Tax",
        min_length: 1,
        max_length: 3,
      },
    },
    button: {
      submit: "Generate",
      cancel: "Cancel",
    },
  },
  assetNew: {
    home: "Home",
    title: "Add Asset",
    form: {
      description: "Asset Description",
      descriptionPlaceholder: "Description",
      purchaseDate: "Recognition Date",
      purchaseDatePlaceholder: "Select Date",
      type: "Type",
      typePlaceholder: "Select Type of Purchase",
      typeLand: "Type of Land",
      typeLandPlaceholder: "Select Type of Land",
      size: "Size (in Sq. Mtr.)",
      sizePlaceholder: "Size",
      sizePrice: "Price Per Sq. Mtr.",
      sizePricePlaceholder: "Price",
      cost: "Cost",
      costPlaceholder: "Cost",
      depreciation: "Depreciation Percentage",
      depreciationPlaceholder: "Depreciation %",
      usefulLife: "Useful Life",
      usefulLifePlaceholder: "Useful Life",
      reducingBalance: "Residual Value",
      reducingBalancePlaceholder: "Residual Value",
    },
  },
  assetDetail: {
    home: "Home",
    title: "Asset Details",
    form: {
      description: "Asset Description",
      descriptionPlaceholder: "Description",
      purchaseDate: "Recognition Date",
      purchaseDatePlaceholder: "Select Date",
      type: "Type",
      typePlaceholder: "Select Type of Purchase",
      typeLand: "Type of Land",
      typeLandPlaceholder: "Select Type of Land",
      size: "Size (in Sq. Mtr.)",
      sizePlaceholder: "Size",
      sizePrice: "Price Per Sq. Mtr.",
      sizePricePlaceholder: "Price",
      cost: "Cost",
      costPlaceholder: "Cost",
      depreciation: "Depreciation Percentage",
      depreciationPlaceholder: "Depreciation %",
      usefulLife: "Useful Life",
      usefulLifePlaceholder: "Useful Life",
      reducingBalance: "Residual Value",
      reducingBalancePlaceholder: "Residual Value",
    },
  },
  investmentModel: {
    validation: {
      select_here: "OOPs! Please Select here",
      enter_here: "OOPs! Please Enter here",
      digit_invailid: "OOPs! Only numeric values or digits allowed",
      not_more_twe: "Value should not be more than 12*",
      not_more_hund: "Value should not be more than 100*",
      not_more_discount_or_hund: "Value should not more than 100*",
      not_more_two_hund: "Value should not more than 200*",
    },
    title: {
      para: "Investment Model",
    },
    form: {
      allocation_asset: {
        label: "Select Allocation Of Asset",
        name: "allocation_asset",
        type: "radio",
        id: "allocation_asset",
      },
      class: {
        label: "Select the Class Asset Belongs too",
      },
      asset: {
        label: "Select Asset",
      },
      present_value: {
        label: "Present Value",
        id: "present_value",
        name: "present_value",
        type: "text",
        placeholder: "Enter Present Value",
        min_length: 1,
        max_length: 12,
      },
      discount_rate: {
        label: "Discount Rate",
        id: "interest",
        name: "interest",
        type: "text",
        placeholder: "Enter Discount Rate",
        min_length: 1,
        max_length: 5,
      },
      inflation_rate: {
        label: "Inflation Rate",
        id: "inflation",
        name: "inflation",
        type: "text",
        placeholder: "Enter Inflation Rate",
        min_length: 1,
        max_length: 5,
      },
      payments: {
        label: "Number of Payments (per year)",
        id: "number_of_payments",
        name: "number_of_payments",
        type: "text",
        placeholder: "Enter Number of Payments",
        min_length: 1,
        max_length: 5,
      },
      years: {
        label: "Number of Years (reference remaining life)",
        id: "number_of_years",
        name: "number_of_years",
        type: "text",
        placeholder: "Enter Number of Year",
        min_length: 1,
        max_length: 6,
      },
    },
    button: {
      submit: "Generate",
      cancel: "Cancel",
    },
  },
};

export default translations;
