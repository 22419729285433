import { connect, useDispatch } from "react-redux";
import { getTranslations } from "../../../redux/selector";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  backImage,
  gaap,
  phone,
  physicalAddress,
  postalAddress,
} from "../../../config/assets";
import envConfig from "../../../config/constants";
import { loginAccessUser } from "../../../redux/api";
import { useEffect } from "react";

const Info = (props) => {
  const { state } = useLocation();
  const { access_key } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (access_key) {
      navigate("/app/dashboard");
      dispatch(
        loginAccessUser({
          body: JSON.stringify({
            access_key,
          }),
          isAdmin: true,
        })
      );
    }
  }, [access_key, dispatch, navigate]);

  return (
    <>
      {!access_key && (
        <div className="record__back">
          <Link to="/auth/login">
            <img src={backImage} alt="back logo" />
          </Link>
        </div>
      )}

      <div className="gaap__story">
        <img className="gaap__img" src={gaap} alt="gaap" />
      </div>

      <div className="gaap__content">
        <div className="gaap__box">
          <h2 className="gaap__title">
            {access_key
              ? props.translations.infoPage.awaitAccess
              : state && state.message
              ? state.message
              : props.translations.infoPage.lookingSomething}
          </h2>
          <div className="gaap__phones">
            {envConfig.phoneNumbers.map((item, index) => {
              return (
                <div className="gaap__sub gaap__sub-center" key={index}>
                  <img src={phone} className="gaap__svg" alt="phone" />
                  <p className="gaap__text">{item}</p>
                </div>
              );
            })}
          </div>
          <div className="gaap__phones">
            <div className="gaap__sub">
              <img src={physicalAddress} className="gaap__icons" alt="phone" />
              <div>
                <p className="gaap__text c__9b">
                  {props.translations.infoPage.physicalAddress}
                </p>
                <p className="gaap__text mt-s-sm">
                  {envConfig.physicalAddress}
                </p>
              </div>
            </div>
            <div className="gaap__sub">
              <img src={postalAddress} className="gaap__icons" alt="phone" />
              <div>
                <p className="gaap__text c__9b">
                  {props.translations.infoPage.postalAddress}
                </p>
                <p className="gaap__text mt-s-sm">{envConfig.postalAddress}</p>
              </div>
            </div>
          </div>
          {!access_key && (
            <div className="gaap__btns">
              <Link to="/auth/login" className="btn btn__blue gaap__btn mt-md">
                {props.translations.infoPage.done}
              </Link>
            </div>
          )}
          <p className="mt-sm">
            <Link
              onClick={() => {
                window
                  .open(
                    envConfig.api.baseURL + envConfig.api.termsURL,
                    "_blank"
                  )
                  .focus();
              }}
              className=" small_font"
            >
              By using <b>Virgilproper</b>, you agree to our{" "}
              <u>Terms and Conditions</u>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({ translations: getTranslations(state) }),
  {}
)(Info);
