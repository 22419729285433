import { connect, useDispatch } from "react-redux";
import { setUser } from "../../../redux/features/user";
import { getTranslations } from "../../../redux/selector";
import { Button, Form, Input } from "reactstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  loginMain,
  logo,
  viewIcon,
  hideIcon,
  arrRight,
} from "../../../config/assets";
import validators from "../../../config/validators";
import { loginUser } from "../../../redux/api";
import envConfig from "../../../config/constants";

const Login = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [message, setMessage] = useState("");
  const [isText, setIsText] = useState(false);
  const [password, setPassword] = useState("");

  const verifyInput = (type, value) => {
    value = value.trim();
    switch (type) {
      case "email":
        setEmail(value);
        setEmailValid(value.match(validators.email));
        setMessage(
          value === ""
            ? ""
            : value.match(validators.email)
            ? props.translations.loginPage.validation.emailVerify
            : props.translations.loginPage.validation.emailInvalid
        );
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="login__story">
        <img className="login__img" src={loginMain} alt="login" />
        <div className="login__text">
          <p className="login__text--size">
            Property, Plant & Equipment Management
          </p>
          <img
            className="login__img--2"
            src={logo}
            alt="login page logo with name"
          />
        </div>
      </div>
      <div className="login__content">
        <Form
          className="login__box"
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(loginUser(JSON.stringify({ email, password })));
          }}
        >
          <h2 className="heading-1 mb-s-sm">
            {props.translations.loginPage.title.h2}
          </h2>
          <p className="mb-sm">{props.translations.loginPage.title.para}</p>

          <div className="mb-md mt-sm">
            <label htmlFor="email" className="form__label" aria-hidden="true">
              {props.translations.loginPage.form.email.label}
            </label>
            <Input
              className="form__input login__w32"
              type={`${props.translations.loginPage.form.email.type}`}
              name={`${props.translations.loginPage.form.email.name}`}
              autoFocus
              minLength={`${props.translations.loginPage.form.email.minLength}`}
              maxLength={`${props.translations.loginPage.form.email.maxLength}`}
              aria-describedby={`${props.translations.loginPage.form.email.placeholder}`}
              placeholder={`${props.translations.loginPage.form.email.placeholder}`}
              value={email}
              onChange={(e) => verifyInput("email", e.target.value)}
              required
            />
            <span
              className={`login__err form__label--${
                emailValid ? "white" : "error"
              }`}
              role="alert"
              aria-live="assertive"
            >
              {message}
            </span>
          </div>

          <div className="mb-md">
            <div className="otp__resends">
              <label
                htmlFor="password"
                className="form__label"
                aria-hidden="true"
              >
                {props.translations.loginPage.form.password.label}
              </label>
              <Link to="/auth/forgot" className="otp__resend c__white">
                {props.translations.loginPage.form.password.forgotLink}
              </Link>
            </div>

            <div className="inputs__icons">
              <Input
                className="form__input login__w32"
                type={isText ? "text" : "password"}
                name={`${props.translations.loginPage.form.password.name}`}
                minLength={`${props.translations.loginPage.form.password.minLength}`}
                maxLength={`${props.translations.loginPage.form.password.maxLength}`}
                aria-describedby={`${props.translations.loginPage.form.password.placeholder}`}
                placeholder={`${props.translations.loginPage.form.password.placeholder}`}
                value={password}
                onChange={(e) => {
                  verifyInput("password", e.target.value);
                }}
                required
              />
              <span
                className="inputs__svg cursor"
                onClick={() => {
                  setIsText(!isText);
                }}
              >
                {isText ? (
                  <img
                    src={hideIcon}
                    alt={`${props.translations.loginPage.form.password.alt.hide}`}
                  />
                ) : (
                  <img
                    src={viewIcon}
                    alt={`${props.translations.loginPage.form.password.alt.view}`}
                  />
                )}
              </span>
            </div>
          </div>
          <Button
            className="btn btn-svg login__btn"
            type="submit"
            disabled={!emailValid}
          >
            {props.translations.loginPage.button.submit}
            <img src={arrRight} alt="" />
          </Button>
          <p className="mt-md">
            <Link to="/auth/register" className="login__link">
              {props.translations.loginPage.title.subParaLink}
            </Link>
          </p>
          <p className="mt-sm">
            <Link
              onClick={() => {
                window
                  .open(
                    envConfig.api.baseURL + envConfig.api.termsURL,
                    "_blank"
                  )
                  .focus();
              }}
              className="register__link small_font"
            >
              By using <b>Virgilproper</b>, you agree to our{" "}
              <u>Terms and Conditions</u>
            </Link>
          </p>
        </Form>
      </div>
    </>
  );
};

export default connect((state) => ({ translations: getTranslations(state) }), {
  setUser,
})(Login);
