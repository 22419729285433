import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Button, Form, Input } from "reactstrap";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import { cross, upload } from "../../config/assets";
import envConfig from "../../config/constants";
import { checkAdmin } from "../../config/utility";
import { getCountries, selfUser, updateSelfUser } from "../../redux/api";
import {
  getAuthKey,
  getCountriesData,
  getTranslations,
  getUserData,
} from "../../redux/selector";

const CompanyProfileEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uploadMessage, setUploadMessage] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [logoPreview, setLogoPreview] = useState(
    props.userData.organization.logo
  );
  const [organizationName, setOrganizationName] = useState(
    props.userData?.organization?.name
  );
  const [country, setCountry] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [physicalArea, setPhysicalArea] = useState(
    props.userData.organization.physical_area
  );
  const [physicalLocality, setPhysicalLocality] = useState(
    props.userData.organization.physical_locality
  );
  const [physicalState, setPhysicalState] = useState(
    props.userData.organization.physical_state
  );
  const [postalArea, setPostalArea] = useState(
    props.userData.organization.postal_area
  );
  const [postalLocality, setPostalLocality] = useState(
    props.userData.organization.postal_locality
  );
  const [postalState, setPostalState] = useState(
    props.userData.organization.postal_state
  );
  const [sameAddress, setSameAddress] = useState(true);

  useEffect(() => {
    if (!checkAdmin(props.userData.access_right)) navigate("/app/dashboard");
    else dispatch(getCountries({ authKey: props.authKey }));
  }, [dispatch, navigate, props.authKey, props.userData.access_right]);

  useEffect(() => {
    let selectedCountry = null,
      selectedCurrency = null;
    const list = props.countriesData.countries.map((country) => {
      if (country.name === props.userData.organization.physical_country)
        selectedCountry = {
          label: country.name,
          value: country.name,
          currency: country.currency,
        };
      return {
        label: country.name,
        value: country.name,
        currency: country.currency,
      };
    });
    const listC = props.countriesData.currencies.map((currency) => {
      if (currency === props.userData.organization.currency)
        selectedCurrency = { label: currency, value: currency };
      return { label: currency, value: currency };
    });
    setCountries(list);
    setCountry(selectedCountry);
    setCurrencies(listC);
    setCurrency(selectedCurrency);
  }, [
    props.countriesData.countries,
    props.countriesData.currencies,
    props.userData.organization.currency,
    props.userData.organization.physical_country,
  ]);

  const verifyInput = (type, value) => {
    switch (type) {
      case "organization":
        setOrganizationName(value);
        break;
      case "physical_area":
        setPhysicalArea(value);
        if (sameAddress) setPostalArea(value);
        break;
      case "physical_locality":
        setPhysicalLocality(value);
        if (sameAddress) setPostalLocality(value);
        break;
      case "physical_state":
        setPhysicalState(value);
        if (sameAddress) setPostalState(value);
        break;
      case "postal_area":
        setPostalArea(value);
        break;
      case "postal_locality":
        setPostalLocality(value);
        break;
      case "postal_state":
        setPostalState(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs crumbs={["Company Profile", "Edit Classes"]} />
        <div className="sSetup">
          <div className="sSetup__container">
            <Form
              className="sSetup__grid-2"
              onSubmit={async (e) => {
                e.preventDefault();
                const response = await dispatch(
                  updateSelfUser({
                    authKey: props.authKey,
                    body: JSON.stringify({
                      organization_name: organizationName,
                      physical_area: physicalArea,
                      physical_locality: physicalLocality,
                      physical_state: physicalState,
                      physical_country: country.value,
                      postal_area: postalArea,
                      postal_locality: postalLocality,
                      postal_state: postalState,
                      postal_country: country.value,
                      logo: companyLogo,
                      currency: currency.value,
                    }),
                  })
                );
                if (response.payload.status === 200) {
                  dispatch(selfUser({ authKey: props.authKey }));
                  navigate("/app/company-profile");
                }
              }}
            >
              <div>
                <h2 className="heading-1 sSetup__blue">
                  Edit Your Company Profile
                </h2>
                <p className="mt-sm sSetup__blue">
                  {props.translations.setupPage.title.para}
                </p>
              </div>
              <div className="sSetup__uploader">
                <div className="sSetup__input-financialYear">
                  <div className="sSetup__upload">
                    <input
                      type="file"
                      className="sSetup__file cursor"
                      accept="image/*"
                      onChange={(e) => {
                        let file = e.target.files[0];
                        if (file.size > envConfig.logoMaxSize)
                          return uploadMessage(
                            `${props.translations.setupPage.validation.fileSizeInvalid}`
                          );
                        setUploadMessage(
                          `${props.translations.setupPage.validation.fileUpload}`
                        );
                        setLogoPreview(URL.createObjectURL(e.target.files[0]));

                        let reader = new FileReader();
                        reader.onload = () => {
                          let base64String = reader.result
                            .replace("data:", "")
                            .replace(/^.+,/, "");
                          setCompanyLogo(base64String);
                        };
                        reader.readAsDataURL(file);
                      }}
                    />
                    {logoPreview === "" && companyLogo === "" ? (
                      <img
                        className="sSetup__previewImg"
                        src={upload}
                        alt={`${props.translations.setupPage.alt.userProfile}`}
                      />
                    ) : (
                      <>
                        <img
                          alt=""
                          className="sSetup__crossImg"
                          src={cross}
                          onClick={() => {
                            setCompanyLogo("");
                            setLogoPreview("");
                            setUploadMessage("");
                          }}
                        />
                        <img
                          className="sSetup__previewImg"
                          src={logoPreview}
                          alt={`${props.translations.setupPage.alt.userProfile}`}
                        />
                      </>
                    )}
                  </div>
                  <span
                    className="form__label--error font-12 lower"
                    role="alert"
                    aria-live="assertive"
                  >
                    {uploadMessage}
                  </span>
                </div>
              </div>
              <div className="sSetup__grid--logo9">
                <div className="sSetup__zindex">
                  <label
                    htmlFor="name"
                    className="form__label  mb-s-sm"
                    aria-hidden="true"
                  >
                    {props.translations.setupPage.form.organisation.label}
                  </label>
                  <Input
                    className="form__input"
                    type={`${props.translations.setupPage.form.organisation.type}`}
                    name={`${props.translations.setupPage.form.organisation.name}`}
                    autoFocus
                    minLength={`${props.translations.setupPage.form.organisation.minLength}`}
                    maxLength={`${props.translations.setupPage.form.organisation.maxLength}`}
                    aria-describedby={`${props.translations.setupPage.form.organisation.placeholder}`}
                    placeholder={`${props.translations.setupPage.form.organisation.placeholder}`}
                    value={organizationName}
                    onChange={(e) =>
                      verifyInput("organization", e.target.value)
                    }
                    required
                  />
                </div>
              </div>
              <div className="sSetup__grid-2">
                {/* country */}
                <div className="">
                  <label
                    htmlFor="fy_start"
                    className="form__label  mb-s-sm cap"
                    aria-hidden="true"
                  >
                    {
                      props.translations.setupPage.form.physicalAddr.country
                        .placeholder
                    }
                  </label>
                  <Select
                    className="cap"
                    name="physical_country"
                    options={countries}
                    placeholder={"Country"}
                    value={country}
                    onChange={(value) => {
                      setCountry(value);
                      setCurrency({
                        value: value.currency,
                        label: value.currency,
                      });
                    }}
                    required={true}
                  />
                </div>
                {/* currency */}
                <div className="">
                  <label
                    htmlFor="fy_start"
                    className="form__label  mb-s-sm cap "
                    aria-hidden="true"
                  >
                    {
                      props.translations.setupPage.form.physicalAddr.currency
                        .name
                    }
                  </label>
                  <Select
                    className="cap "
                    name="currency"
                    options={currencies}
                    placeholder={"Currency"}
                    value={currency}
                    onChange={(value) => {
                      setCurrency(value);
                    }}
                    required={true}
                  />
                </div>
              </div>

              <div className="sSetup__details">
                <div className="">
                  <div className="sSetup__physicals">
                    <div className=" sSetup__fullWidth">
                      <label
                        htmlFor="physical_area"
                        className="form__label mb-s-sm"
                        aria-hidden="true"
                      >
                        {
                          props.translations.setupPage.form.physicalAddr
                            .organisation.label
                        }
                      </label>
                      <Input
                        className="form__input "
                        type={`${props.translations.setupPage.form.physicalAddr.building.type}`}
                        name={`${props.translations.setupPage.form.physicalAddr.building.name}`}
                        minLength={`${props.translations.setupPage.form.physicalAddr.building.minLength}`}
                        maxLength={`${props.translations.setupPage.form.physicalAddr.building.maxLength}`}
                        aria-describedby={`${props.translations.setupPage.form.physicalAddr.building.placeholder}`}
                        placeholder={`${props.translations.setupPage.form.physicalAddr.building.placeholder}`}
                        value={physicalArea}
                        onChange={(e) =>
                          verifyInput("physical_area", e.target.value)
                        }
                        required
                      />
                    </div>
                    <div className=" sSetup__fullWidth">
                      <Input
                        className="form__input "
                        type={`${props.translations.setupPage.form.physicalAddr.locality.type}`}
                        name={`${props.translations.setupPage.form.physicalAddr.locality.name}`}
                        minLength={`${props.translations.setupPage.form.physicalAddr.locality.minLength}`}
                        maxLength={`${props.translations.setupPage.form.physicalAddr.locality.maxLength}`}
                        aria-describedby={`${props.translations.setupPage.form.physicalAddr.locality.placeholder}`}
                        placeholder={`${props.translations.setupPage.form.physicalAddr.locality.placeholder}`}
                        value={physicalLocality}
                        onChange={(e) =>
                          verifyInput("physical_locality", e.target.value)
                        }
                        required
                      />
                    </div>
                    <div className="">
                      <Input
                        className="form__input"
                        type={`${props.translations.setupPage.form.physicalAddr.state.type}`}
                        name={`${props.translations.setupPage.form.physicalAddr.state.name}`}
                        minLength={`${props.translations.setupPage.form.physicalAddr.state.minLength}`}
                        maxLength={`${props.translations.setupPage.form.physicalAddr.state.maxLength}`}
                        aria-describedby={`${props.translations.setupPage.form.physicalAddr.state.placeholder}`}
                        placeholder={`${props.translations.setupPage.form.physicalAddr.state.placeholder}`}
                        value={physicalState}
                        onChange={(e) =>
                          verifyInput("physical_state", e.target.value)
                        }
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="sSetup__vertical"></div>
                <div className="">
                  <div className="sSetup__postals">
                    <div className=" sSetup__fullWidth">
                      <label
                        htmlFor="postal_area"
                        className="form__label  mb-s-sm"
                        aria-hidden="true"
                      >
                        {
                          props.translations.setupPage.form.postalAddr
                            .organisation.label
                        }
                      </label>
                      <Input
                        className="form__input "
                        type={`${props.translations.setupPage.form.postalAddr.building.type}`}
                        name={`${props.translations.setupPage.form.postalAddr.building.name}`}
                        minLength={`${props.translations.setupPage.form.postalAddr.building.minLength}`}
                        maxLength={`${props.translations.setupPage.form.postalAddr.building.maxLength}`}
                        aria-describedby={`${props.translations.setupPage.form.postalAddr.building.placeholder}`}
                        placeholder={`${props.translations.setupPage.form.postalAddr.building.placeholder}`}
                        value={postalArea}
                        onChange={(e) =>
                          verifyInput("postal_area", e.target.value)
                        }
                        required
                      />
                      <span
                        className="form__label--error c__yellow lower"
                        role="alert"
                        aria-live="assertive"
                      ></span>
                    </div>

                    <div className=" sSetup__fullWidth">
                      <Input
                        className="form__input "
                        type={`${props.translations.setupPage.form.postalAddr.locality.type}`}
                        name={`${props.translations.setupPage.form.postalAddr.locality.name}`}
                        minLength={`${props.translations.setupPage.form.postalAddr.locality.minLength}`}
                        maxLength={`${props.translations.setupPage.form.postalAddr.locality.maxLength}`}
                        aria-describedby={`${props.translations.setupPage.form.postalAddr.locality.placeholder}`}
                        placeholder={`${props.translations.setupPage.form.postalAddr.locality.placeholder}`}
                        value={postalLocality}
                        onChange={(e) =>
                          verifyInput("postal_locality", e.target.value)
                        }
                        required
                      />
                      <span
                        className="form__label--error c__yellow lower"
                        role="alert"
                        aria-live="assertive"
                      ></span>
                    </div>

                    <div className="sSetup__postals">
                      <div className="">
                        <Input
                          className="form__input"
                          type={`${props.translations.setupPage.form.postalAddr.state.type}`}
                          name={`${props.translations.setupPage.form.postalAddr.state.name}`}
                          minLength={`${props.translations.setupPage.form.postalAddr.state.minLength}`}
                          maxLength={`${props.translations.setupPage.form.postalAddr.state.maxLength}`}
                          aria-describedby={`${props.translations.setupPage.form.postalAddr.state.placeholder}`}
                          placeholder={`${props.translations.setupPage.form.postalAddr.state.placeholder}`}
                          value={postalState}
                          onChange={(e) =>
                            verifyInput("postal_state", e.target.value)
                          }
                          required
                        />
                        <span
                          className="form__label--error c__yellow lower"
                          role="alert"
                          aria-live="assertive"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sSetup__sameAddressBox">
                  <Input
                    className="sSetup__sameAddr"
                    type={`${props.translations.setupPage.form.checkBtn.type}`}
                    name={`${props.translations.setupPage.form.checkBtn.name}`}
                    id={`${props.translations.setupPage.form.checkBtn.id}`}
                    checked={sameAddress}
                    onChange={(e) => {
                      setSameAddress(e.target.checked);
                      if (e.target.checked) {
                        setPostalArea(physicalArea);
                        setPostalLocality(physicalLocality);
                        setPostalState(physicalState);
                      }
                    }}
                  />
                  <label
                    htmlFor="sameAddr"
                    className="font-14 ml-s-sm c__9b"
                    aria-hidden="true"
                  >
                    {props.translations.setupPage.form.checkBtn.label}
                  </label>
                </div>
                <div className="sSetup__btns">
                  <Button className="btn btn-svgs sSetup__btn" type="submit">
                    Update
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  authKey: getAuthKey(state),
  userData: getUserData(state),
  countriesData: getCountriesData(state),
  translations: getTranslations(state),
}))(CompanyProfileEdit);
