import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "reactstrap";
import BreadCrumbs from "../../components/breadCrumbs";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import {
  arrRight,
  Biological,
  Buildings,
  Computers,
  Heritage,
  Infrastructure,
  Land,
  Motor,
  Office,
  Other,
  Plant,
  tick,
  tickBlack,
} from "../../config/assets";
import envConfig from "../../config/constants";
import { checkAdmin } from "../../config/utility";
import {
  createColumns,
  getDefaultClass,
  selfUser,
  updateSelfUser,
} from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import {
  getAuthKey,
  getDefaultClassData,
  getTranslations,
  getUserData,
} from "../../redux/selector";

const EditClasses = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userClasses = props.userData.classes.reduce(
    (acc, { default_class_id }) => {
      acc[default_class_id] = default_class_id;
      return acc;
    },
    {}
  );
  const [selectedClass, setSelectedClass] = useState({});

  useEffect(() => {
    if (!checkAdmin(props.userData.access_right)) navigate("/app/dashboard");
    else dispatch(getDefaultClass({ authKey: props.authKey }));
  }, [dispatch, navigate, props.authKey, props.userData.access_right]);

  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs crumbs={["Company Profile", "Edit Classes"]} />

        <div className="classes">
          <div className="classes__container">
            <h2 className="heading-1 classes__blue">
              {props.translations.setupClass.title.h2}
            </h2>
            <p className="mt-sm classes__blue">
              {props.translations.setupClass.title.para}
            </p>
            <Form
              className="classes__cards"
              onSubmit={async (e) => {
                e.preventDefault();
                if (Object.keys(selectedClass).length === 0)
                  props.setMessageUser({
                    message: props.translations.setupClass.validation.class,
                  });
                else {
                  const response = await dispatch(
                    updateSelfUser({
                      authKey: props.authKey,
                      body: JSON.stringify({
                        classes: Object.keys(selectedClass),
                      }),
                    })
                  );
                  if (response.payload.status === 200) {
                    const response = await dispatch(
                      selfUser({ authKey: props.authKey })
                    );
                    if (response.payload.status === 200) {
                      for (const classDetails of response.payload.data.classes.reduce(
                        (array, { id, default_class_id }) => {
                          if (!userClasses[default_class_id]) {
                            array.push({ id });
                          }
                          return array;
                        },
                        []
                      )) {
                        const body = JSON.stringify({
                          columns: Object.values(
                            envConfig.defaultColumn
                          ).reduce((array, data) => {
                            array.push({
                              default_column_id: data,
                              name: null,
                              type: null,
                              position: array.length + 1,
                            });

                            return array;
                          }, []),
                        });
                        await dispatch(
                          createColumns({
                            authKey: props.authKey,
                            classId: classDetails.id,
                            body,
                          })
                        );
                      }
                    }
                    navigate("/app/company-profile");
                  }
                }
              }}
            >
              {props.classes.map((singleClass, index) => {
                return (
                  <label
                    className="checked__label"
                    key={index}
                    onClick={() => {
                      if (!userClasses[singleClass.id]) {
                        let data = Object.assign({}, selectedClass);
                        if (data[singleClass.id]) delete data[singleClass.id];
                        else data[singleClass.id] = singleClass.id;
                        setSelectedClass(data);
                      }
                    }}
                  >
                    <div
                      className={`Depreciation__card jobcreate__links__${
                        userClasses[singleClass.id]
                          ? "alreadySelected"
                          : selectedClass[singleClass.id]
                          ? "selected"
                          : ""
                      } mt-sm`}
                    >
                      {((classId) => {
                        switch (classId) {
                          case 1:
                            return (
                              <Land
                                className={`Depreciation__svg ${
                                  userClasses[classId]
                                    ? "Depreciation__selected--svg--black"
                                    : selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                          case 2:
                            return (
                              <Buildings
                                className={`Depreciation__svg ${
                                  selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                          case 3:
                            return (
                              <Computers
                                className={`Depreciation__svg ${
                                  selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                          case 4:
                            return (
                              <Office
                                className={`Depreciation__svg ${
                                  selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                          case 5:
                            return (
                              <Infrastructure
                                className={`Depreciation__svg ${
                                  selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                          case 6:
                            return (
                              <Motor
                                className={`Depreciation__svg ${
                                  selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                          case 7:
                            return (
                              <Plant
                                className={`Depreciation__svg ${
                                  selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                          case 8:
                            return (
                              <Heritage
                                className={`Depreciation__svg ${
                                  selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                          case 9:
                            return (
                              <Biological
                                className={`Depreciation__svg ${
                                  selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                          default:
                            return (
                              <Other
                                className={`Depreciation__svg ${
                                  selectedClass[classId]
                                    ? "Depreciation__selected--svg"
                                    : ""
                                }`}
                              />
                            );
                        }
                      })(singleClass.id)}
                      <img
                        className={`jobcreate__tic jobcreate__tic--${
                          userClasses[singleClass.id] ||
                          selectedClass[singleClass.id]
                            ? "display"
                            : ""
                        }`}
                        src={userClasses[singleClass.id] ? tickBlack : tick}
                        alt={`${props.translations.setupClass.alt.selectedCard}`}
                      />
                      <p
                        className={`Depreciation__para ${
                          selectedClass[singleClass.id]
                            ? "Depreciation__blue"
                            : ""
                        }`}
                      >
                        {singleClass.name}
                      </p>
                    </div>
                  </label>
                );
              })}
              <div className="sSetup__btns mt-md">
                <Button className="btn btn-svgs classes__btn" type="submit">
                  {props.translations.setupClass.button.submit}
                  <img src={arrRight} alt="" />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslations(state),
    classes: getDefaultClassData(state),
    userData: getUserData(state),
  }),
  { setMessageUser }
)(EditClasses);
