import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Form } from "reactstrap";
import DesktopSetupTopBar from "../../components/desktopSetupTopBar";
import MobileSetupTopBar from "../../components/mobileSetupTopBar";
import {
  arrRight,
  Biological,
  Buildings,
  Computers,
  Heritage,
  Infrastructure,
  Land,
  Motor,
  Office,
  Other,
  Plant,
  tick,
} from "../../config/assets";
import { getDefaultClass, selfUser, updateSelfUser } from "../../redux/api";
import { setMessageUser } from "../../redux/features/user";
import {
  getAuthKey,
  getDefaultClassData,
  getTranslations,
} from "../../redux/selector";

const SetupClass = (props) => {
  const dispatch = useDispatch();
  const [selectedClass, setSelectedClass] = useState({});

  useEffect(() => {
    dispatch(getDefaultClass({ authKey: props.authKey }));
  }, [dispatch, props.authKey]);

  return (
    <div className="classes">
      {/* Mobile Navbar */}
      <MobileSetupTopBar />
      {/* Desktop Navbar */}
      <DesktopSetupTopBar />

      <div className="classes__container">
        <h2 className="heading-1 classes__blue">
          {props.translations.setupClass.title.h2}
        </h2>
        <p className="mt-sm classes__blue">
          {props.translations.setupClass.title.para}
        </p>
        <Form
          className="classes__cards"
          onSubmit={async (e) => {
            e.preventDefault();
            if (Object.keys(selectedClass).length === 0)
              props.setMessageUser({
                message: props.translations.setupClass.validation.class,
              });
            else {
              const response = await dispatch(
                updateSelfUser({
                  authKey: props.authKey,
                  body: JSON.stringify({ classes: Object.keys(selectedClass) }),
                })
              );
              if (response.payload.status === 200)
                dispatch(selfUser({ authKey: props.authKey }));
            }
          }}
        >
          {props.classes.map((singleClass, index) => {
            return (
              <label
                className="checked__label"
                key={index}
                onClick={() => {
                  let data = Object.assign({}, selectedClass);
                  if (data[singleClass.id]) delete data[singleClass.id];
                  else data[singleClass.id] = singleClass.id;
                  setSelectedClass(data);
                }}
              >
                <div
                  className={`Depreciation__card jobcreate__links--${
                    selectedClass[singleClass.id] ? "selected" : ""
                  } mt-sm`}
                >
                  {((classId) => {
                    switch (classId) {
                      case 1:
                        return (
                          <Land
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                      case 2:
                        return (
                          <Buildings
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                      case 3:
                        return (
                          <Computers
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                      case 4:
                        return (
                          <Office
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                      case 5:
                        return (
                          <Infrastructure
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                      case 6:
                        return (
                          <Motor
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                      case 7:
                        return (
                          <Plant
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                      case 8:
                        return (
                          <Heritage
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                      case 9:
                        return (
                          <Biological
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                      default:
                        return (
                          <Other
                            className={`Depreciation__svg ${
                              selectedClass[classId]
                                ? "Depreciation__selected--svg"
                                : ""
                            }`}
                          />
                        );
                    }
                  })(singleClass.id)}
                  <img
                    className={`jobcreate__tic jobcreate__tic--${
                      selectedClass[singleClass.id] ? "display" : ""
                    }`}
                    src={tick}
                    alt={`${props.translations.setupClass.alt.selectedCard}`}
                  />
                  <p
                    className={`Depreciation__para ${
                      selectedClass[singleClass.id] ? "Depreciation__blue" : ""
                    }`}
                  >
                    {singleClass.name}
                  </p>
                </div>
              </label>
            );
          })}
          <div className="sSetup__btns mt-md">
            <Button className="btn btn-svgs classes__btn" type="submit">
              {props.translations.setupClass.button.submit}
              <img src={arrRight} alt="" />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslations(state),
    classes: getDefaultClassData(state),
  }),
  { setMessageUser }
)(SetupClass);
