import { createAsyncThunk } from "@reduxjs/toolkit";
import envConfig from "../config/constants";

const processResponse = async (url, options) => {
  try {
    return await (await fetch(url, options)).json();
  } catch (e) {
    if (envConfig.isDev) console.log(e);
  }
  return { status: 401, message: "Error connecting server" };
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (body, { dispatch }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.login,
      {
        headers: { "content-type": "application/json" },
        method: "POST",
        body,
      }
    );
    if (response.status === 200) {
      localStorage.setItem(
        envConfig.storageKeys.authKey,
        btoa(JSON.stringify(response.data))
      );
      dispatch(selfUser({ authKey: response.data.auth_key }));
    }
    return response;
  }
);

export const loginAccessUser = createAsyncThunk(
  "user/login/access-key",
  async ({ body, isAdmin }, { dispatch }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.loginAccess,
      {
        headers: { "content-type": "application/json" },
        method: "POST",
        body,
      }
    );
    if (response.status === 200) {
      if (!isAdmin) {
        localStorage.setItem(
          envConfig.storageKeys.subsidiaryAuthKey,
          localStorage.getItem(envConfig.storageKeys.authKey)
        );
      }
      localStorage.setItem(
        envConfig.storageKeys.authKey,
        btoa(JSON.stringify(response.data))
      );
      dispatch(selfUser({ authKey: response.data.auth_key }));
    }
    return response;
  }
);

export const selfUser = createAsyncThunk("user/self", async (body) => {
  const response = await processResponse(
    envConfig.api.baseURL + envConfig.api.self,
    {
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + body.authKey,
      },
    }
  );
  if (response.status !== 200) localStorage.clear();
  return response;
});

export const registerUser = createAsyncThunk(
  "user/register/initialize",
  async (body) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.registerVerify,
      {
        headers: { "content-type": "application/json" },
        method: "POST",
        body,
      }
    );
    return response;
  }
);

export const registerVerifyUser = createAsyncThunk(
  "user/register/verify",
  async (body) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.register,
      {
        headers: { "content-type": "application/json" },
        method: "POST",
        body,
      }
    );
    return response;
  }
);

export const forgotUser = createAsyncThunk("user/forgot", async (body) => {
  const response = await processResponse(
    envConfig.api.baseURL + envConfig.api.forgot,
    {
      headers: { "content-type": "application/json" },
      method: "POST",
      body,
    }
  );
  return response;
});

export const forgotVerifyUser = createAsyncThunk(
  "user/forgot/verify",
  async (body) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.forgotVerify,
      {
        headers: { "content-type": "application/json" },
        method: "POST",
        body,
      }
    );
    return response;
  }
);

export const getCountries = createAsyncThunk(
  "global/countries",
  async (body) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.countries,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + body.authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const updateSelfUser = createAsyncThunk(
  "user/self/update",
  async ({ authKey, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.updateSelf,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const getDefaultClass = createAsyncThunk(
  "class/default",
  async ({ authKey }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.defaultClass,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const getDefaultColumn = createAsyncThunk(
  "column/default",
  async ({ authKey }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.defaultColumn,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const createColumns = createAsyncThunk(
  "column/create",
  async ({ authKey, classId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.createColumns.base +
        classId +
        envConfig.api.createColumns.multiple,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "POST",
        body,
      }
    );
    return response;
  }
);

export const getColumns = createAsyncThunk(
  "column/get",
  async ({ authKey, classId }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.createColumns.base + classId,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const getAssets = createAsyncThunk(
  "asset/get/all",
  async ({ authKey, classId }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.getAssets.base +
        classId +
        envConfig.api.getAssets.all,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const createAssets = createAsyncThunk(
  "asset/create",
  async ({ authKey, classId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.createAssets + classId,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "POST",
        body,
      }
    );
    return response;
  }
);

export const getAssetDetails = createAsyncThunk(
  "asset/get/single",
  async ({ authKey, classId, assetId }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.getAssetDetails +
        classId +
        "/" +
        assetId,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const updateAssets = createAsyncThunk(
  "asset/update",
  async ({ authKey, classId, assetId, body, usefulLifeWay = "" }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.updateAssets +
        classId +
        "/" +
        assetId +
        "?useful_life_way=" +
        encodeURIComponent(usefulLifeWay),
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const disposeAssets = createAsyncThunk(
  "asset/dispose",
  async ({ authKey, classId, assetId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.disposeAssets.base +
        classId +
        "/" +
        assetId +
        envConfig.api.disposeAssets.dispose,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const disposeLandAssets = createAsyncThunk(
  "asset/dispose/land",
  async ({ authKey, classId, assetId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.disposeAssets.base +
        classId +
        "/" +
        assetId +
        envConfig.api.disposeAssets.disposeLand,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const updateStatusAssets = createAsyncThunk(
  "asset/update/status",
  async ({ authKey, classId, assetId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.statusUpdateAssets.base +
        classId +
        "/" +
        assetId +
        envConfig.api.statusUpdateAssets.status,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const impairAssets = createAsyncThunk(
  "asset/impair",
  async ({ authKey, classId, assetId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.impairAssets.base +
        classId +
        "/" +
        assetId +
        envConfig.api.impairAssets.impairment,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const transferAssets = createAsyncThunk(
  "asset/transfer",
  async ({ authKey, classId, assetId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.tranferAssets.base +
        classId +
        "/" +
        assetId +
        envConfig.api.tranferAssets.transfer,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const moveAssets = createAsyncThunk(
  "asset/movement",
  async ({ authKey, classId, assetId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.moveAssets.base +
        classId +
        "/" +
        assetId +
        envConfig.api.moveAssets.movement,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const fetchDashboard = createAsyncThunk(
  "dashboard/get",
  async ({ authKey }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.dashboard,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const getAssetList = createAsyncThunk(
  "asset/get/list",
  async ({ authKey, classId }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.getAssetList.base +
        classId +
        envConfig.api.getAssetList.detail,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const getArchiveAssets = createAsyncThunk(
  "asset/get/archive/all",
  async ({ authKey, classId }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.getAssets.base +
        classId +
        envConfig.api.getAssets.all +
        "?type=dispose",
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const calculateDeferredTax = createAsyncThunk(
  "asset/deferred-tax",
  async ({ authKey, classId, assetId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.calculateDeferredTax.base +
        classId +
        "/" +
        assetId +
        envConfig.api.calculateDeferredTax.deferTax,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const calculateInvestment = createAsyncThunk(
  "asset/investment-model",
  async ({ authKey, classId, assetId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.calculateInvestment.base +
        classId +
        "/" +
        assetId +
        envConfig.api.calculateInvestment.investment,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const createAccountant = createAsyncThunk(
  "accountant/create",
  async ({ authKey, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.createAccountant,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "POST",
        body,
      }
    );
    return response;
  }
);

export const getAccountants = createAsyncThunk(
  "accountant/get",
  async ({ authKey }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.getAccountants,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const deleteAccountant = createAsyncThunk(
  "accountant/delete",
  async ({ authKey, accountantId }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.deleteAccountant + accountantId,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "DELETE",
      }
    );
    return response;
  }
);

export const updateAccountant = createAsyncThunk(
  "accountant/update",
  async ({ authKey, accountantId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.getAccountants + accountantId,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const createSubsidiary = createAsyncThunk(
  "subsidiary/create",
  async ({ authKey, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.createSubsidiary,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "POST",
        body,
      }
    );
    return response;
  }
);

export const getSubsidiaries = createAsyncThunk(
  "subsidiary/get",
  async ({ authKey }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.getSubsidiaries,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const updateSubsidiary = createAsyncThunk(
  "subsidiary/update",
  async ({ authKey, subsidiaryId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL + envConfig.api.getSubsidiaries + subsidiaryId,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const accessSubsidiary = createAsyncThunk(
  "subsidiary/access",
  async ({ authKey, subsidiaryId }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.accessSubsidiaries.base +
        subsidiaryId +
        envConfig.api.accessSubsidiaries.accessKey,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const downloadReport = async ({ authKey, reportParam }) => {
  try {
    return {
      status: 200,
      blob: await (
        await fetch(
          envConfig.api.baseURL + envConfig.api.reports.base + reportParam,
          {
            headers: {
              "content-type": "application/json",
              authorization: "Bearer " + authKey,
            },
            method: "GET",
          }
        )
      ).blob(),
    };
  } catch (e) {
    if (envConfig.isDev) console.log(e);
  }
  return { status: 401, message: "Error connecting server" };
};

export const fairValueAssets = createAsyncThunk(
  "asset/fair-value",
  async ({ authKey, classId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.fairValueAssets.base +
        classId +
        envConfig.api.fairValueAssets.fairValue,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "PUT",
        body,
      }
    );
    return response;
  }
);

export const fastForwardOneYear = createAsyncThunk(
  "asset/forward",
  async ({ authKey, classId, assetId }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.forwardOneYear.base +
        classId +
        "/" +
        assetId +
        envConfig.api.forwardOneYear.forward,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "GET",
      }
    );
    return response;
  }
);

export const checkDuplicateAsset = createAsyncThunk(
  "asset/check-duplicate",
  async ({ authKey, classId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.checkDuplicate.base +
        classId +
        envConfig.api.checkDuplicate.check,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "POST",
        body,
      }
    );
    return response;
  }
);

export const deleteAssets = createAsyncThunk(
  "asset/delete",
  async ({ authKey, classId, assetId, body }) => {
    const response = await processResponse(
      envConfig.api.baseURL +
        envConfig.api.deleteAssets +
        classId +
        "/" +
        assetId,
      {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + authKey,
        },
        method: "DELETE",
        body,
      }
    );
    return response;
  }
);
