import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import {
  getAccountantsData,
  getAuthKey,
  getClassAssetInfo,
  getClassAssets,
  getUserData,
} from "../../redux/selector";
import DataTable from "react-data-table-component";
import { deleteAsset, edit } from "../../config/assets";
import envConfig from "../../config/constants";
import { setMessageUser } from "../../redux/features/user";
import BreadCrumbs from "../../components/breadCrumbs";
import { clearClassColumn } from "../../redux/features/asset";
import { deleteAccountant, getAccountants } from "../../redux/api";
import swal from "sweetalert";
import { checkAdmin } from "../../config/utility";

const Accountants = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "600",
        fontSize: "15px",
        textTransform: "capitalize",
      },
    },
    rows: {
      style: {
        minHeight: "3rem", // override the row height
      },
    },
  };

  let headers = [
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Phone",
        selector: (row) => row.phone,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Created On",
        selector: (row) => row.created_on,
        sortable: true,
      },
      {
        name: "Access Right",
        selector: (row) => row.access_right,
        sortable: true,
        cell: (row) => (
          <span
            className={`accMgmt__access--${
              row.access_right === 1 ? "fullAccess" : "readOnly"
            }`}
          >
            {row.access_right === 1 ? "Full Access" : "Read Only"}
          </span>
        ),
      },
      {
        name: "Actions",
        center: true,
        cell: (row) => (
          <>
            <Link
              className="accMgmt__link"
              state={row}
              to={"/app/accountant-edit"}
            >
              <img src={edit} alt="deletes" />
            </Link>
            <div
              className="accMgmt__link cursor-pointer"
              onClick={() => {
                swal({
                  title: "Are you sure?",
                  text: "Do you want to delete this accountant",
                  icon: "warning",
                  buttons: true,
                }).then(async (result) => {
                  if (result) {
                    const response = await dispatch(
                      deleteAccountant({
                        authKey: props.authKey,
                        accountantId: row.id,
                      })
                    );
                    if (response.payload.status === 200) {
                      dispatch(getAccountants({ authKey: props.authKey }));
                    }
                    props.setMessageUser({
                      message: response.payload.message,
                      type:
                        response.payload.status === 200
                          ? envConfig.notificationTypes.success
                          : envConfig.notificationTypes.danger,
                    });
                  }
                });
              }}
            >
              <img src={deleteAsset} alt="deletes" />
            </div>
          </>
        ),
      },
    ],
    data = props.accountants;

  if (searchText !== "") {
    data = data.filter((row) => {
      return (
        row.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        row.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        row.phone.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
    });
  }

  useEffect(() => {
    if (!checkAdmin(props.userData.access_right)) {
      navigate("/app/dashboard");
    } else {
      dispatch(getAccountants({ authKey: props.authKey }));
    }
  }, [dispatch, navigate, props.authKey, props.userData.access_right]);

  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs crumbs={["Accountants"]} />
        <div className="listing pb0">
          <div className="listing__header listing__arvhiveHeader">
            <h3 className="heading-3 cap">Accountant Management</h3>
            <div className="listing__header--box">
              {/* Mobile search */}
              <div className="form__groupd">
                <Input
                  className="form__search"
                  type="search"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>

              {Number(props.userData.access_right) ===
                envConfig.accessRights.admin && (
                <Link
                  to={{
                    pathname: "/app/accountant-add",
                  }}
                  className="btn btn__blue btn__brd"
                >
                  + Add Accountant
                </Link>
              )}
            </div>
          </div>
          <DataTable
            columns={headers}
            data={data}
            highlightOnHover={true}
            pagination={true}
            persistTableHead={true}
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight="300px"
          />
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    userData: getUserData(state),
    classAssets: getClassAssets(state),
    classAssetInfo: getClassAssetInfo(state),
    accountants: getAccountantsData(state),
  }),
  { setMessageUser, clearClassColumn }
)(Accountants);
