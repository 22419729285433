import { connect, useDispatch } from "react-redux";
import { Button, Form, Input } from "reactstrap";
import SideNav from "../../components/sideNav";
import TopNav from "../../components/topNav";
import {
  getAuthKey,
  getClassAssetInfo,
  getClassAssets,
  getUserData,
} from "../../redux/selector";
import { setMessageUser } from "../../redux/features/user";
import BreadCrumbs from "../../components/breadCrumbs";
import { clearClassColumn } from "../../redux/features/asset";
import envConfig from "../../config/constants";
import { useEffect, useState } from "react";
import { hideIcon, viewIcon } from "../../config/assets";
import validators from "../../config/validators";
import getTranslation from "../../translations";
import { updateSubsidiary } from "../../redux/api";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAdmin } from "../../config/utility";

const SubsidiaryEdit = (props) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState(state?.name);
  const [nameValid, setNameValid] = useState(false);
  const [nameMessage, setNameMessage] = useState("");
  const [email, setEmail] = useState(state?.email);
  const [emailValid, setEmailValid] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [phone, setPhone] = useState(state?.phone);
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneMessage, setPhoneMessage] = useState("");
  const [password, setPassword] = useState("");
  const [subsidiaryName, setSubsidiaryName] = useState(state?.subsidiary_name);
  const [subsidiaryNameValid, setSubsidiaryNameValid] = useState(false);
  const [subsidiaryNameMessage, setSubsidiaryNameMessage] = useState("");
  const [isText, setIsText] = useState(false);

  const verifyInput = (type, value) => {
    switch (type) {
      case "name":
        setName(value);
        setNameValid(value.match(validators.name));
        setNameMessage(
          value === ""
            ? ""
            : value.match(validators.name)
            ? props.translations.registerPage.validation.nameVerify
            : props.translations.registerPage.validation.nameInvalid
        );
        break;
      case "email":
        setEmail(value);
        setEmailValid(value.match(validators.email));
        setEmailMessage(
          value === ""
            ? ""
            : value.match(validators.email)
            ? props.translations.registerPage.validation.emailVerify
            : props.translations.registerPage.validation.emailInvalid
        );
        break;
      case "phone":
        setPhone(value);
        setPhoneValid(value.match(validators.phone));
        setPhoneMessage(
          value === ""
            ? ""
            : value.match(validators.phone)
            ? props.translations.registerPage.validation.phoneVerify
            : props.translations.registerPage.validation.phoneInvalid
        );
        break;
      case "password":
        setPassword(value);
        break;
      case "subsidiary_name":
        setSubsidiaryName(value);
        setSubsidiaryNameValid(value.match(validators.name));
        setSubsidiaryNameMessage(
          value === ""
            ? ""
            : value.match(validators.name)
            ? props.translations.registerPage.validation.nameVerify
            : props.translations.registerPage.validation.nameInvalid
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      props.userData.organization.is_subsidiary ||
      !(state && state.id) ||
      !checkAdmin(props.userData.access_right)
    ) {
      navigate("/app/dashboard");
    }
  }, [
    navigate,
    props.userData.access_right,
    props.userData.organization.is_subsidiary,
    state,
  ]);

  return (
    <>
      <TopNav />
      <SideNav />

      <div className="listings">
        <BreadCrumbs crumbs={["Subsidiary", "Edit"]} />
        <div className="listing">
          <div className="companyProfile__header">
            <div>
              <h3 className="heading-3">Subsidiary User Setup</h3>
              <p className="mt-s-sm">Fill in the informations of your user </p>
            </div>
          </div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              const response = await dispatch(
                updateSubsidiary({
                  authKey: props.authKey,
                  subsidiaryId: state.id,
                  body: JSON.stringify({
                    subsidiary_name: subsidiaryName,
                    name,
                    email,
                    phone,
                    password: password === "" ? undefined : password,
                  }),
                })
              );
              if (response.payload.status === 200) {
                navigate("/app/subsidiaries");
                props.setMessageUser({
                  message: response.payload.message,
                  type: envConfig.notificationTypes.success,
                });
              } else {
                props.setMessageUser({
                  message: response.payload.message,
                  type: envConfig.notificationTypes.danger,
                });
              }
            }}
          >
            <div className="companyProfile__labels">
              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="name"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    User Name
                  </label>
                  <Input
                    className="form__input "
                    type="text"
                    placeholder="Enter Name"
                    minLength="3"
                    maxLength="32"
                    value={name}
                    onChange={(e) => {
                      verifyInput("name", e.target.value);
                    }}
                    required
                  />
                  <span
                    className={`login__err form__label--${
                      nameValid ? "dark" : "error"
                    }`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {nameMessage}
                  </span>
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="email"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    User Email
                  </label>
                  <Input
                    className="form__input "
                    type="email"
                    placeholder="Enter Email"
                    minLength="5"
                    maxLength="64"
                    value={email}
                    onChange={(e) => {
                      verifyInput("email", e.target.value);
                    }}
                    required
                  />
                  <span
                    className={`login__err form__label--${
                      emailValid ? "dark" : "error"
                    }`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {emailMessage}
                  </span>
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="password"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Password
                  </label>
                  <Input
                    className="form__input "
                    type={isText ? "text" : "password"}
                    placeholder="Enter Password"
                    minLength="6"
                    maxLength="16"
                    value={password}
                    onChange={(e) => {
                      verifyInput("password", e.target.value);
                    }}
                    required
                  />
                  <span
                    className="companyProfile__label__icons cursor"
                    onClick={() => {
                      setIsText(!isText);
                    }}
                  >
                    {isText ? (
                      <img src={hideIcon} alt="" />
                    ) : (
                      <img src={viewIcon} alt="" />
                    )}
                  </span>
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="phone"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    User Phone Number
                  </label>
                  <Input
                    className="form__input "
                    type="tel"
                    placeholder="Enter Phone"
                    minLength="7"
                    maxLength="16"
                    value={phone}
                    onChange={(e) => {
                      verifyInput("phone", e.target.value);
                    }}
                    required
                  />
                  <span
                    className={`login__err form__label--${
                      phoneValid ? "dark" : "error"
                    }`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {phoneMessage}
                  </span>
                </div>
              </div>

              <div className="companyProfile__label ">
                <div className="companyProfile__label-data ">
                  <label
                    htmlFor="subsidiary_name"
                    className="form__label c__blue mb-s-sm"
                    aria-hidden="true"
                  >
                    Name of Subsidiary
                  </label>
                  <Input
                    className="form__input "
                    type="text"
                    minLength="3"
                    maxLength="60"
                    placeholder="Enter Subsidiary Name"
                    value={subsidiaryName}
                    onChange={(e) => {
                      verifyInput("subsidiary_name", e.target.value);
                    }}
                    required
                  />
                  <span
                    className={`login__err form__label--${
                      subsidiaryNameValid ? "dark" : "error"
                    }`}
                    role="alert"
                    aria-live="assertive"
                  >
                    {subsidiaryNameMessage}
                  </span>
                </div>
              </div>
            </div>

            <div className="popup__btns editProfile__btns mt-md">
              <Button className="btn popup__btn btn__blue cap" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    authKey: getAuthKey(state),
    translations: getTranslation(state),
    userData: getUserData(state),
    classAssets: getClassAssets(state),
    classAssetInfo: getClassAssetInfo(state),
  }),
  { setMessageUser, clearClassColumn }
)(SubsidiaryEdit);
